import { Box, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ActionableIntelligence from './ActionableIntelligenceView';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import CustomRadio from '../common/CustomRadio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';
import ReportingView from './ReportView';
import Typography from '@material-ui/core/Typography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { getFormattedDate } from '../../helper/commonHelper';

const breakpoints = createBreakpoints({});

const CustomizedTooltip = withStyles(() => ({
    tooltip: {
        maxWidth: 220,
        fontSize: '14px',
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    ratingFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    fontSetPortImg: {
        width: '80%',
    },

    porSnap: {
        border: '1px solid #D8D8D8',
        borderRadius: '2px',
        padding: '12px 10px',
        position: 'relative',
        [breakpoints.down('xs')]: {
            padding: '12px 8px',
        },
    },

    radioSecion: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    radioSecionRight: {
        flexDirection: 'row',
        width: '100%',
    },

    mainFontSetPortImgRecnt: {
        display: 'inline-block',
        width: '10%',
        verticalAlign: 'middle',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '7%',
        },
        [breakpoints.between('1000', '1350')]: {
            width: '7%',
        },
    },

    rightSideScroll: {
        height: '95vh',
        overflow: 'hidden',
        overflowY: 'scroll',
        minHeight: '98vh',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },

    fontSetPort: {
        color: '#07212B',
    },

    radioSecionFont: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('1000', '1350')]: {
            fontSize: '15px',
        },
    },
    radioSecionFontLeftSec: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        marginRight: '55px',
    },
    halfWidth: {
        width: '50%',
        [breakpoints.between('1000', '1350')]: {
            width: '100%',
        },
        [breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

const RecentChangesInRisk = (props) => {
    const classes = useStyles();

    const [isReportingView, setReportingView] = useState(false);

    const { raasAccountSelected, quarter, year } = props;

    const handleChangeRadio = (event) => {
        const reportingTab = event.target.value === 'reportingView';
        setReportingView(reportingTab);
    };

    let title = `as of ${getFormattedDate(new Date(), 'dd/MMM/yy')}`;
    if (quarter && year) {
        title = `for ${quarter}, Year - ${year}`;
    } else if (year) {
        title = `for Year - ${year}`;
    } else if (quarter) {
        title = `for QTR - ${quarter.split('Q')[1]}`;
    }

    return (
        <Box className={classes.porSnap}>
            <Box className={classes.ratingFilter}>
                <Box className={classes.halfWidth}>
                    <Typography className={classes.fontSetPort} variant="h5">
                        <span className={classes.mainFontSetPortImgRecnt}>
                            <BrightnessMediumIcon style={{ color: '#FF7841', fontSize: '32px' }} />
                            {' '}
                        </span>
                        {' '}
                        Risk Ratings
                        {' '}
                        {title}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.rightSideScroll}>
                <Box>
                    <RadioGroup
                        classes={{ root: classes.radioSecionRight }}
                        aria-label="gender"
                        name="gender1"
                        onChange={handleChangeRadio}
                        valueselected={isReportingView ? 'reportingView' : 'actionableIntelligenceView'}
                        defaultValue="actionableIntelligenceView"
                    >
                        <FormControlLabel
                            classes={{ label: classes.radioSecionFont }}
                            value="actionableIntelligenceView"
                            control={<CustomRadio />}
                            label={(
                                <CustomizedTooltip
                                    title="Risk distribution across domains"
                                >
                                    <Box>Domain View</Box>
                                </CustomizedTooltip>
                            )}
                        />
                        <FormControlLabel
                            classes={{ label: classes.radioSecionFontLeftSec }}
                            value="reportingView"
                            control={<CustomRadio />}
                            label={(
                                <CustomizedTooltip
                                    title="Risk distribution across risk categories per domain based on moderate, high and critical risk rating"
                                >
                                    <Box>Category View</Box>
                                </CustomizedTooltip>
                            )}
                        />
                    </RadioGroup>
                </Box>
                {isReportingView ? (
                    <ReportingView
                        targetId={props.targetId}
                        reportId={props.reportId}
                        quarter={props.quarter}
                        year={props.year}
                        label={props.label}
                        categories={props.categories}
                        raasAccountSelected={raasAccountSelected}
                    />
                )
                    : (
                        <ActionableIntelligence
                            targetId={props.targetId}
                            reportId={props.reportId}
                            quarter={props.quarter}
                            year={props.year}
                            label={props.label}
                            raasAccountSelected={raasAccountSelected}
                        />
                    )}
            </Box>
        </Box>
    );
};

export default RecentChangesInRisk;

import { Box, Collapse } from '@material-ui/core';
import { LOCATION_PARTY_MAP_DISPLAY, RISK_CATEGORY_COLORS } from '../common/constants';
import { Link } from 'react-router-dom';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Loader from '../common/LoaderView';
import MapViewCities from './MapViewCities';
import MapViewCountry from './MapViewCountry';
import MapViewNP from './MapViewNP';
import NoDataElement from '../common/NoDataElement';
import React, { useEffect, useState } from 'react';
import TargetLists from '../common/listView/TargetsListView';
import Typography from '@material-ui/core/Typography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import sendRequest from '../../httpRequest';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
    mainSecPart: {
        border: '1px solid #7070704B',
        padding: '10px',
        marginTop: '25px',
        width: '100%',
        [breakpoints.down('xs')]: {
            padding: '0px',
            marginTop: '15px',
        },
    },

    mainHighLowBottom: {
        marginTop: '25px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '85%',
        marginLeft: 'auto',
    },

    yellowSec: {
        textDecoration: 'none',
        height: '250px',
        backgroundColor: '#FEFD13',
        border: '6px solid rgba(254,253,19,0.16)',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            height: '150px',
            border: 'none',
            borderRadius: '1px',
        },
    },
    yellowSecImm: {
        textDecoration: 'none',
        height: '250px',
        backgroundColor: '#C01510',
        border: '6px solid #F3D0CF',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            height: '150px',
            border: 'none',
            borderRadius: '1px',
        },
    },
    yellowSecLow: {
        textDecoration: 'none',
        height: '250px',
        backgroundColor: '#70AD47',
        border: '6px solid #E7F1E2',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            height: '150px',
            border: 'none',
            borderRadius: '1px',
        },
    },
    yellowSecHigh: {
        textDecoration: 'none',
        height: '250px',
        border: '6px solid #FAE4D5',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            height: '150px',
            border: 'none',
            borderRadius: '1px',
        },
    },

    mainChartHighLow: {
        display: 'flex',
    },

    fontSetPorttCh: {
        color: '#fff',
        lineHeight: '22px',
    },
    fontSetPorttCheight: {
        color: '#204565',
        lineHeight: '22px',
        textAlign: 'center',
    },

    fontSetPorttCheightOccu: {
        color: '#204565',
        lineHeight: '22px',
        textAlign: 'center',
        transform: 'rotate(-90deg)',
    },

    fontSetPortt: {
        color: '#204565',
    },

    mainHighLow: {
        marginTop: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '8%',
        [breakpoints.down('xs')]: {
            marginTop: '0px',
            width: '10%',
        },
    },
    mainYellowSec: {
        [breakpoints.down('xs')]: {
            width: '50%',
            padding: '2px !important',
        },
    },
    mapContainer: {
        border: '1px solid #7070704B',
        width: '100%',
        height: '370px',
        marginTop: '10px',
    },
    fontSetPort: {
        color: '#07212B',
    },
}));

const ActionableIntelligence = (props) => {
    const classes = useStyles();
    const [drillDownlevel, setLevel] = useState({
        index: 1,
        dataIndex: '',
    });
    const [actionableItems, setActionableItems] = useState({});
    const [loading, setLoadingStatus] = useState(false);
    const [mapLoading, setMapLoadingStatus] = useState(false);
    const [targetListing, setTargetListing] = useState([]);
    const { raasAccountSelected, targetId, reportId } = props;
    const [targetIdList, setTargetIdList] = useState([]);
    const [modifyNPData, setModifyNPData] = useState([]);

    /**
     * Callback function for Risk Domain API success
     * @param {Object} res
     */
    const targetRequestSuccess = (res) => {
        setMapLoadingStatus(false);
        let targets = [];
        if (res.data) {
            targets = res.data.targets.map((target) => {
                target.risk_categories.sort(
                    (firstCategory, secondCategory) => parseInt(secondCategory.score) - parseInt(firstCategory.score)
                );
                return target;
            });
        }
        setTargetListing(targets);
    };

    useEffect(() => {
        if (props.targetId && (props.targetId !== 3) && props.reportId) {
            setMapLoadingStatus(true);
            const data = {
                target_type: props.targetId,
                report_type: props.reportId,
                sortBy: 'high-to-low',
                limit: 200,
                client_org: raasAccountSelected.id,
            };
            setTargetListing([]);
            sendRequest(
                'get',
                'api/v2/risk/targets/riskiest/findings/?',
                data,
                getRequestedHeader(),
                targetRequestSuccess,
                requestFailure
            );
        }
    }, [props.targetId, props.reportId, raasAccountSelected]);

    useEffect(() => {
        if (props && props.targetId && props.reportId) {
            setLoadingStatus(true);
            const requestData = {
                target_type: props.targetId,
                report_type: props.reportId,
                quarter: props.quarter,
                year: props.year,
                label: props.label,
                client_org: raasAccountSelected.id,
            };

            sendRequest(
                'get',
                'api/v2/risk/recent-changes/actionable/',
                requestData,
                getRequestedHeader(),
                requestSuccess,
                requestFailure
            );
        }
    }, [props.targetId, props.reportId, props.quarter, props.year, props.label, raasAccountSelected]);

    /**
     * Callback function for Risk Domain API success
     * @param {Object} res
     */
    const getReportSuccess = (res) => {
        setLoadingStatus(false);
        const responseData = res.data;
        const nthPartyData = [];
        responseData && responseData.length && responseData.forEach((item) => {
            const temp = LOCATION_PARTY_MAP_DISPLAY.find((filtered) => filtered === item.id);
            if (temp) nthPartyData.push(item);
        });

        const tempIds = [];
        const tempNPData = [];
        nthPartyData && nthPartyData.length && nthPartyData.forEach((item) => {
            const keys = Object.keys(item);
            keys && keys.length && keys.forEach((criticality) => {
                if (item[criticality] && item[criticality].count > 0) {
                    const subCat = item[criticality].sub_categories;
                    subCat && subCat.length && subCat.forEach((category) => {
                        if(category.target_type !== 2){
                            tempIds.push(category.id);
                        }
                        tempNPData.push({
                            id: category.id,
                            name: category.name,
                            rating: criticality,
                            targets: category.targets,
                            targetType: category.target_type,
                        });
                    });
                }
            });
        });

        setModifyNPData(tempNPData); // used to display marker
        setTargetIdList(tempIds); // used to get the lat long details
    };

    useEffect(() => {
        if (targetId && reportId && raasAccountSelected.id) {
            const data = {
                target_type: targetId,
                report_type: reportId,
                sortBy: 'high-to-low',
                limit: 200,
                client_org: raasAccountSelected.id,
            };
            setLoadingStatus(true);
            sendRequest(
                'get',
                'api/v2/risk/recent-changes/report/',
                data,
                getRequestedHeader(),
                getReportSuccess,
                requestFailure,
            );
        }
    }, [raasAccountSelected, targetId, reportId]);

    /**
   * Callback function for recent-changes-actionable API success
   * @param {Object} res
   */
    const requestSuccess = (res) => {
        setLoadingStatus(false);
        setActionableItems(res.data);
    };

    /**
   * Function to handle API call failure
   * @param {Object} error
   */
    const requestFailure = (error) => {
        setLoadingStatus(false);
        setMapLoadingStatus(false);
        console.log(error);
    };

    /**
   * Function to handle drill down
   * @param {Object} severity
   */
    const handleDrillDown = (severity) => {
        setLevel((prevstate) => ({
            index: prevstate.index === 1 ? 2 : 1,
            dataIndex: severity,
        }));
    };

    const getHeading = () => {
        let title;
        if (targetId === 1 || targetId === 2) title = 'Worldwide Risk Concentration';
        if (targetId === 3 && targetIdList.length) title = 'Location Risk Concentration';
        return (
            <Typography className={classes.fontSetPort} variant="h5">
                {title}
            </Typography>
        );
    };

    const getMap = () => {
        if (targetId === 1 || targetId === 2) {
            if (targetId === 1 && targetListing.length) {
                return (<Box className={classes.mapContainer}><MapViewCities targetLists={targetListing} /></Box>);
            }
            if (targetId === 2 && targetListing.length) {
                return (<Box className={classes.mapContainer}><MapViewCountry targetLists={targetListing} /></Box>);
            }
            return (<Box className={classes.mapContainer}><NoDataElement content="Map Data Not Found" /></Box>);
        }
        if (targetId === 3) {
            return (<Box className={classes.mapContainer}><MapViewNP modifyNPData={modifyNPData} targetIdList={targetIdList} /></Box>);
        }
        return null;
    };

    if (drillDownlevel.index === 1) {
        return (loading || mapLoading) ? <Loader loading={loading} /> : (
            <Box className={classes.rightSideScroll}>
                <Box className={classes.mainChartHighLow}>
                    <Box className={classes.mainSecPart}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item md={6} className={classes.mainYellowSec} sm={12} lg={6}>
                                <span
                                    className={classes.yellowSec}
                                    onClick={handleDrillDown.bind(this, 'Moderate')}
                                    name="Moderate"
                                    style={{ backgroundColor: RISK_CATEGORY_COLORS.MODERATE_RISK }}
                                >
                                    <Typography
                                        className={classes.fontSetPorttCh}
                                        variant="h6"
                                    >
                                        {actionableItems ? actionableItems.moderate : ''}
                                        {' '}
                                        <br />
                                        {' '}
                                        Moderate
                                    </Typography>
                                </span>
                            </Grid>
                            <Grid item md={6} className={classes.mainYellowSec} sm={12} lg={6}>
                                <span
                                    className={classes.yellowSecImm}
                                    onClick={handleDrillDown.bind(this, 'Critical')}
                                    name="Critical"
                                    style={{ backgroundColor: RISK_CATEGORY_COLORS.CRITICAL_RISK }}
                                >
                                    <Typography
                                        className={classes.fontSetPorttCh}
                                        variant="h6"
                                    >
                                        {actionableItems ? actionableItems.critical : ''}
                                        {' '}
                                        <br />
                                        {' '}
                                        Critical
                                    </Typography>
                                </span>
                            </Grid>
                            <Grid item md={6} className={classes.mainYellowSec} sm={12} lg={6}>
                                <span
                                    className={classes.yellowSecLow}
                                    onClick={handleDrillDown.bind(this, 'Low')}
                                    name="Low"
                                    style={{ backgroundColor: RISK_CATEGORY_COLORS.LOW_RISK }}
                                >
                                    <Typography
                                        className={classes.fontSetPorttCh}
                                        variant="h6"
                                    >
                                        {actionableItems ? actionableItems.low : ''}
                                        {' '}
                                        <br />
                                        Low
                                    </Typography>
                                </span>
                            </Grid>
                            <Grid item md={6} className={classes.mainYellowSec} sm={12} lg={6}>
                                <span
                                    className={classes.yellowSecHigh}
                                    onClick={handleDrillDown.bind(this, 'High')}
                                    name="High"
                                    style={{ backgroundColor: RISK_CATEGORY_COLORS.HIGH_RISK }}
                                >
                                    <Typography
                                        className={classes.fontSetPorttCh}
                                        variant="h6"
                                    >
                                        {actionableItems ? actionableItems.high : ''}
                                        {' '}
                                        <br />
                                        {' '}
                                        High
                                    </Typography>
                                </span>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box className={classes.mainHighLowBottom}>
                    <Typography
                        className={classes.fontSetPorttCheight}
                        variant="subtitle1"
                    />
                    <Typography
                        className={classes.fontSetPorttCheight}
                        variant="subtitle1"
                    />
                    <Typography
                        className={classes.fontSetPorttCheight}
                        variant="subtitle1"
                    />
                </Box>
                <Box>
                    <Box>
                        {getHeading()}
                    </Box>
                    {getMap()}
                </Box>
            </Box>
        );
    }

    let risk_category;
    if (drillDownlevel.dataIndex) {
        risk_category = drillDownlevel.dataIndex.toLowerCase();
    }

    const targets = actionableItems[`${risk_category}_targets`] ? actionableItems[`${risk_category}_targets`] : [];
    return (loading ? <Loader loading={loading} />
        : (
            <TargetLists
                actionableItems={targets}
                heading={`${drillDownlevel.dataIndex} Domains`}
                headingCount={targets.length}
                parentRisk={risk_category}
                handledrillDown={handleDrillDown}
                reportType={props.reportId}
                targetType={props.targetId}
            />
        )
    );
};

export default ActionableIntelligence;

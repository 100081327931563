import React, { useEffect, useState, useRef } from 'react';
import {
    Accordion, AccordionSummary, Box, Divider, Grid, Typography,
} from '@material-ui/core';
import { getLabelTargets } from '../../redux/actions/ParameterAction';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import CustomRadio from './CustomRadio';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fade from '@material-ui/core/Fade';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Loader from './LoaderView';
import Modal from '@material-ui/core/Modal';
import NoDataElement from './NoDataElement';
import RadioGroup from '@material-ui/core/RadioGroup';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import edit from '../../Images/edit.svg';
import remove from '../../Images/remove.svg';
import sendRequest from '../../httpRequest';
import CustomSelect from '../common/CustomSelect';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
    selectBoxX: {
        width: 'auto',
        minHeight: '46px',
        backgroundColor: '#D5DEE112',
        padding: '4px 12px 4px 32px',
        borderRadius: '2px',
        color: '#07212B',
        fontSize: '17px',
        marginLeft: '0px',
        fontFamily: 'Gilroy-Semibold',
        opacity: '.5',
        position: 'relative',
        [breakpoints.down('xs')]: {
            minHeight: '40px',
            fontSize: '16px',
        },
        [breakpoints.between('sm', 'md')]: {
            width: '90%',
        },
    },
    fontSetEdit: {
        cursor: 'pointer',
        // width: 'calc(100% + 105px)',
        fontSize: '14px',
        [breakpoints.down('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: 'calc(100% + 10px)',
        },
        [breakpoints.between('1000', '1350')]: {
            width: 'calc(100% + 20px)',
        },
    },
    filterImg: {
        position: 'absolute',
        width: '7%',
        left: '4%',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '60%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        [breakpoints.down('xs')]: {
            width: '100%',
            padding: '10px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            margin: '0px',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    fontSetPortPoup: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    searchPoupInput: {
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        '& input': {
            fontSize: '18px',
            fontFamily: 'Gilroy-Semibold',
            padding: '4px 29px',
        },
    },
    modalBtnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    searchPoupInputIcon: {
        position: 'absolute',
        left: '5px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#7D99A3',
    },
    poupInput: {
        backgroundColor: '#D5DEE112',
        color: '#00364B',
        border: '0.800000011920929px solid #D5DEE1',
        width: '450px',
        height: '40px',
        paddingLeft: '38px',
    },
    poupButton: {
        color: '#428BCA',
        width: '162px',
        height: '40px',
        border: '1px solid #428BCA',
        backgroundColor: '#fff',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    mainTableSec: {
        maxHeight: '55vh',
        overflowY: 'scroll',
        marginTop: '8px',
        [breakpoints.down('xs')]: {
            height: '50vh',
        },
    },
    poupTable: {
        width: '100%',
        border: '1px solid #D5DBE16C',
        borderRadius: '2px',
        backgroundColor: '#FDFDFD',
        marginBottom: '5px',

        '& td': {
            paddingLeft: '15px',
            fontSize: '18px',
            fontFamily: 'Gilroy-Medium',
            height: '45px',
            '&:nth-child(1)': {
                [breakpoints.down('xs')]: {
                    fontSize: '14px',
                    width: '40% !important',
                },
            },
            '& img': {
                [breakpoints.down('xs')]: {
                    width: '70%',
                },
            },
        },
    },
    targetsWrapper: {
        display: 'flex',
        overflow: 'scroll',
    },
    poupInputLab: {
        backgroundColor: '#D5DEE112',
        color: '#00364B',
        border: '0.800000011920929px solid #D5DEE1',
        width: '100%',
        height: '48px',
        paddingLeft: '38px',
    },
    poupTableTdTwo: {
        color: '#000',
        opacity: '.6',
        width: '23%',
        fontSize: '16px !important',
        [breakpoints.down('xs')]: {
            fontSize: '14px !important',
        },
    },
    poupTableTd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        '& img': {},
    },
    searchAndTarget: {
        color: 'rgba(0, 54, 75, .57)',
        fontSize: '14px',
        fontFamily: 'Gilroy-Regular',
    },
    editIcon: {
        cursor: 'pointer',
    },
    poupHr: {
        margin: '10px -32px',
    },
    mainBottonButton: {
        textAlign: 'right',
    },
    bottomPoupButtonCancel: {
        color: '#5C5C5C',
        height: '48px',
        width: '125px',
        fontSize: '18px',
        borderRadius: '2px',
        border: 'none',
        backgroundColor: '#E0E0E0',
        cursor: 'pointer',
        marginRight: '20px',
    },
    targetName: {
        color: '#00364B',
        padding: '4px 8px 4px 0px',
        fontSize: '14px',
    },
    bottomPoupButton: {
        color: '#fff',
        height: '48px',
        width: '125px',
        fontSize: '18px',
        borderRadius: '2px',
        border: 'none',
        backgroundColor: '#428BCA',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            fontSize: '15px',
            height: '40px',
            width: '100px',
        },
    },
    mainSelectBoxSec: {
        position: 'relative',
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '7px',
    },
    autoCompleteSec: {
        width: '100%',
        position: 'relative',
        '& input': {
            padding: '0px 38px !important',
            fontSize: '16px',
        },
    },
    addRemove: {
        fontSize: 16,
        fontFamily: 'Gilroy-Semibold',
        color: '#000000',
        marginTop: '24px',
    },
    targetIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E0E0E0',
        borderRadius: '21px',
        padding: '0px 19px',
        margin: '0px 5px',
        '& svg': {
            color: '#FF9393',
            fontSize: '14px',
        },
    },
    inAutocomSec: {
        borderRadius: '0px',
        backgroundColor: '#D5DEE112',
        height: '48px',
    },
    searchPoupInputIconAuto: {
        position: 'absolute',
        left: '5px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#7D99A3',
    },
    modalBtnContainerCancel: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    targetFilterCont: {
        position: 'relative',
    },
    radioSecionRight: {
        flexDirection: 'row',
        width: '100%',
    },
    radioSecionFontLeftSec: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        marginRight: '55px',
    },
    radioSecionFont: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('1000', '1350')]: {
            fontSize: '15px',
        },
    },
    accordianSection: {
        marginBottom: '14px',
        boxShadow: 'none',
        '&::before': {
            content: 'none',
        },
        overflow: 'hidden',
    },
    inAccordianSection: {
        minHeight: '48px !important',
        border: '0.800000011920929px solid #D5DEE1',
        borderRadius: '2px',
        backgroundColor: '#D5DEE112',
        '&:hover': {
            backgroundColor: '#428BCA',
            border: '0.800000011920929px solid #428BCA',
            color: '#fff',
            '& svg': {
                color: '#fff',
            },
            '& $heading': {
                color: '#fff',
            },
            '& $headingActive': {
                color: '#fff',
            },
            '& $headingInactive': {
                color: '#fff',
            },
        },
    },
    inAccordianSectionMargin: {
        margin: '0px 0px !important',
        color: '#428BCA',
        '& $heading': {
            color: '#428BCA',
        },
    },
    heading: {
        display: 'inline',
    },
    errorType: {
        fontSize: '14px',
    },
}));

const CustomizedTooltip = withStyles(() => ({
    tooltip: {
        maxWidth: 220,
        fontSize: '14px',
        border: '1px solid #dadde9',
    },
}))(Tooltip);

// Style for Select
const customSelectStyles = {
    option: (provided, state) => ({
        padding: '10px',
    }),
    control: (base, state) => ({
        ...base,
        width: '230px',
        height: '46px',
        backgroundColor: '#D5DEE112',
        padding: '4px 12px',
        borderRadius: '2px',
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('sm', 'md')]: {
            marginTop: '7px',
            fontSize: '15px',
        },
        [breakpoints.between('1000', '1350')]: {
            marginTop: '0px',
            width: '160px',
            fontSize: '13px',
        },
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        width: '260px',
    }),
    menuList: (base) => ({
        ...base,
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
    }),
    placeholder: (base) => ({
        ...base,
        marginLeft: '8px',
        color: '#828F94',
    }),
    singleValue: (provided) => ({
        ...provided,
        marginLeft: '15px',
        color: '#828F94',
    }),
};

// Style for target select
const customSelectStylesTarget = {
    option: (provided, state) => ({
        padding: '10px',
    }),
    control: (base, state) => ({
        ...base,
        backgroundColor: '#D5DEE112',
        padding: '4px 20px',
        borderRadius: '2px',
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('sm', 'md')]: {
            marginTop: '7px',
            fontSize: '15px',
        },
        [breakpoints.between('1000', '1350')]: {
            marginTop: '0px',
        },
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
    }),
    menuList: (base) => ({
        ...base,
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
    }),
    placeholder: (base) => ({
        ...base,
        marginLeft: '15px',
        color: '#828F94',
    }),
    singleValue: (provided) => ({
        ...provided,
        marginLeft: '15px',
        color: '#828F94',
    }),
};

const LabelComponent = (props) => {
    const selectInputRef = useRef();
    const onClear = () => {
        selectInputRef.current.select.clearValue();
    };
    const classes = useStyles();
    const [labels, setLabels] = useState([]);
    const [filteredLabels, setFilteredLabels] = useState([]);
    const dispatch = useDispatch();
    const [filteredPersonalLabels, setFilteredPersonalLabels] = useState([]);
    const [filteredOrganizationalLabels, setFilteredOrganizationalLabels] = useState([]);
    // Maintaining states for label add/edit modalsz
    const [open, setOpen] = useState(false);
    const [openAddnewLabel, setOpenAddNewLabel] = useState(false);
    const [openEditLabel, setOpenEditLabelModal] = useState(false);
    const [labelError, setLabelError] = useState('');
    const [loading, setLoading] = useState(false);
    const [subscribedTargets, setSubscribedTargets] = useState({
        all: [],
        filtered: [],
    });
    const [labelType, setLabelType] = useState('personal');
    const [orgModLabels, setOrgModLabels] = useState([]);
    const [orgModFilteredLabels, setOrgModFilteredLabels] = useState([]);
    const { loginDetails } = props;
    const [editLabelDetails, setEditLabelDetails] = useState({
        labelId: '',
        labelName: '',
        type: '',
    });
    const [expanded, setExpanded] = useState(false);
    const [, setAccordianPanel] = useState('');

    const [targetsForEachLabel, setTargetsForEachLabel] = useState([]);
    const [errorFields, setErrorFields] = useState({});
    const [newLabel, setNewLabel] = useState('');
    const targetListItems = useSelector((state) => state.ParametertReducer.labelTargets);
    const [searchTarget, setSearchTarget] = useState();
    const targetData = useSelector((state) => state.TargetReducer.targetData)
    const targetOption = targetData.map((target) => ({
        value: target.id,
        label: target.name,
    }));

    useEffect(() => {
        if (targetListItems && targetListItems.length) {
            setSubscribedTargets({
                all: targetListItems,
                filtered: targetListItems,
            });
        }
    }, [targetListItems]);

    /**
   * Function to update label type
   * @param {Object} event
   */
    const handleTypeChange = (event) => {
        setLabelType(event.target.value);
    };

    /**
   * @description update accordian panel value and expand / close accordian
   * @param {string} panel
   */
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if (isExpanded) {
            setAccordianPanel(panel);
        }
    };

    /**
    * Function to handle request failure
    * @param {Object} error
    */
    const requestFailure = (error) => {
        toast.error(error.message);
        if (error.response) {
            setLabelError(error.response.data ? error.response.data.non_field_errors : '');
        }
        setLoading(false);
    };
    const getPerLabelsSuccess = (res) => {
        setFilteredPersonalLabels(res.data.results);

        if (props.defaultLabel) {
            const defaultLabel = res.data.results.find((lab) => lab.id === props.defaultLabel);
            if (defaultLabel) {
                props.handleLabelChange({
                    label: defaultLabel.name,
                    value: defaultLabel.value,
                });
            }
        }
    };

    const getOrgLabelsSuccess = (res) => {
        setFilteredOrganizationalLabels(res.data.results);
        if (props.defaultLabel) {
            const defaultLabel = res.data.results.find((lab) => lab.id === props.defaultLabel);
            if (defaultLabel) {
                props.handleLabelChange({
                    label: defaultLabel.name,
                    value: defaultLabel.value,
                });
            }
        }
    };

    useEffect(() => {
        if (props && props.organizationId) {
            sendRequest(
                'get',
                `api/v2/accounts/labels/${props.organizationId}/`,
                {
                    label_type: 'personal',
                },
                getRequestedHeader(),
                getPerLabelsSuccess,
                requestFailure
            );
            sendRequest(
                'get',
                `api/v2/accounts/labels/${props.organizationId}/`,
                {
                    label_type: 'organization',
                },
                getRequestedHeader(),
                getOrgLabelsSuccess,
                requestFailure
            );
        }
    }, [orgModFilteredLabels]);

    /**
   * Callback handler for getting organizational labels
   * @param {Object} res
   */
    const getOrgModLabelsSuccess = (res) => {
        setOrgModLabels(res.data.results);
        setOrgModFilteredLabels(res.data.results);
    };

    /**
     * Update labels list each time the edit labels modal is opened or closed
     */
    useEffect(() => {
        if (props && props.organizationId) {
            sendRequest(
                'get',
                `api/v2/accounts/labels/${props.organizationId}/`,
                { label_type: 'personal' },
                getRequestedHeader(),
                getLabelsSuccess,
                requestFailure
            );
            sendRequest(
                'get',
                `api/v2/accounts/labels/${props.organizationId}/`,
                {
                    label_type: 'organization',
                },
                getRequestedHeader(),
                getOrgModLabelsSuccess,
                requestFailure
            );
        }

    }, [open, props.organizationId]);

    /**
     * Callback handler for getting labels
     * @param {Object} res
     */
    const getLabelsSuccess = (res) => {
        dispatch(props.actionProps.setLabels(res.data.results));
        setLabels(res.data.results);
        setFilteredLabels(res.data.results);
        if (props.defaultLabel) {
            const defaultLabel = res.data.results.find((lab) => lab.id === props.defaultLabel);
            if (defaultLabel) {
                props.handleLabelChange({
                    label: defaultLabel.name,
                    value: defaultLabel.value,
                });
            }
        }
    };

    /**
     * Function to close modal
     * @param {Object} event
     */
    const handleCloseModal = (event) => {
        setOpen(false);
    };

    /**
     * Function to filter labels on search bar input
     * @param {Object} event
     */
    const filterLabelhandler = (event) => {
        const searchItem = event.target.value.toLowerCase();
        const newLabels = labels.filter((label, index) => {
            const labelName = label.name.toLowerCase();
            return labelName.includes(searchItem);
        });
        const newOrgLabels = orgModLabels.filter((label, index) => {
            const labelName = label.name.toLowerCase();
            return labelName.includes(searchItem);
        });
        setOrgModFilteredLabels(newOrgLabels);
        setFilteredLabels(newLabels);
    };

    /**
     * Function to open add new label modal
     */
    const handleOpenAddNewLabelModal = () => {
        setOpen(false);
        setOpenAddNewLabel(true);
        getLabelTargets(dispatch);
    };

    /**
     * Function to open edit label modal
     * @param {Number} labelId
     * @param {String} labelName
     */
    const handleEditIconClick = (labelId, labelName, type) => {
        setOpen(false);
        // Update label clicked details
        setEditLabelDetails({
            labelId,
            labelName,
            type,
        });
        setOpenEditLabelModal(true);
        getLabelTargets(dispatch);

        // Update targets for label clicked
        const requestData = {
            label: labelId,
        };
        sendRequest(
            'get',
            'api/risk/get_target_by_label/',
            requestData,
            getRequestedHeader(),
            updateTargetsForLabel,
            requestFailure
        );
    };

    /**
     * Funtion to delete personal label
     * @param {Number} labelId
     * @param {String} labelName
     */
    const handleLabelDelete = (labelId) => {
        sendRequest(
            'delete',
            `/api/account/label/${labelId}/`,
            {},
            getRequestedHeader(),
            deleteSuccess.bind(null, labelId),
            requestFailure
        );
    };

    /**
   * Funtion to reload modal on successful organization label deletion
   * @param {Number} labelId
   */
    const orgDeleteSuccess = (labelId) => {
        // update orgFilteredLabels and orgLabels
        const newFilteredLabels = orgModFilteredLabels.filter(
            (label) => label.id !== labelId
        );
        const newLabels = orgModLabels.filter((label) => label.id !== labelId);
        setOrgModLabels(newLabels);
        setOrgModFilteredLabels(newFilteredLabels);
    };

    /**
   * Funtion to delete organization label
   * @param {Number} labelId
   */
    const handleOrganizationLabelDelete = (labelId) => {
        sendRequest(
            'delete',
            `/api/account/label/${labelId}/`,
            {},
            getRequestedHeader(),
            orgDeleteSuccess.bind(null, labelId),
            requestFailure
        );
    };

    /**
     * Funtion to reload modal on successful deletion
     * @param {Object} res
     */
    const deleteSuccess = (labelId) => {
        // update filteredLabels and labels
        const newFilteredLabels = filteredLabels.filter(
            (label, index) => label.id !== labelId
        );
        const newLabels = labels.filter((label, index) => label.id !== labelId);
        setLabels(newLabels);
        setFilteredLabels(newFilteredLabels);
    };

    /**
     * Function to update targets associated with each label
     * @param {Object} res
     */
    const updateTargetsForLabel = (res) => {
        const labelDetails = res.data.map((target) => ({ label: target.name, value: target.id }));
        setTargetsForEachLabel(labelDetails);
    };

    /**
     * Function to close add new label modal
     */
    const handleCloseAddNewLabelModal = () => {
        setOpenAddNewLabel(false);
    };

    /**
     * Function to update new label
     * @param {Object} event
     */
    const handleLabelAddition = (event) => {
        setNewLabel(event.target.value);
        setErrorFields({ ...errorFields, addName: '' });
    };

    const targetList = [];
    subscribedTargets.filtered.map((tar) => {
        targetList.push({
            label: tar.target.name,
            value: tar.target.id,
        });
    });

    /**
     * Function to update targets for each label
     * @param {Object} event
     */

    useEffect(() => {
        if (searchTarget) {
            const timer = setTimeout(() => {
                getLabelTargets(searchTarget);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, []);
    /**
    * Function to handle cancel click for add new label modal
    * @param {Object} event
    */
    const handleAddModalClose = (event) => {
        setLabelType('personal');
        setOpenAddNewLabel(false);
        setOpen(true);
        setTargetsForEachLabel([]);
        setNewLabel('');
        setLabelError('');
        setErrorFields({ ...errorFields, addName: '' });
    };

    const [searchedTargetList, setSearchedTargetList] = useState({
        searchedTarget: [],
        isError: false,
    });

    const handleMultiFilter = (event) => {
        setTargetsForEachLabel(event)
    };
    /**
     * Function to validate edit fields
     */
    const validateEditFields = () => {
        let isValidField = true;
        const tempErrorFields = {};
        if (editLabelDetails.labelName) {
            if (editLabelDetails.type === 'personal') {
                const filterLabelId = filteredLabels && filteredLabels.length
                    && filteredLabels.filter((label) => label.id !== editLabelDetails.labelId);

                const perLabelNameCheck = filterLabelId && filterLabelId.length
                    && filterLabelId.filter((label) => label.name.toLowerCase() === editLabelDetails.labelName.toLowerCase());

                if (perLabelNameCheck && perLabelNameCheck.length > 0) {
                    tempErrorFields.editName = 'This name already exists. Please create with a different name';
                    isValidField = false;
                }
            }

            if (editLabelDetails.type === 'organization') {
                const filterLabelId = orgModFilteredLabels && orgModFilteredLabels.length
                    && orgModFilteredLabels.filter((label) => label.id !== editLabelDetails.labelId);

                const orgLabelNameCheck = filterLabelId && filterLabelId.length
                    && filterLabelId.filter((label) => label.name.toLowerCase() === editLabelDetails.labelName.toLowerCase());

                if (orgLabelNameCheck && orgLabelNameCheck.length > 0) {
                    tempErrorFields.editName = 'This name already exists. Please create with a different name';
                    isValidField = false;
                }
            }
        } else {
            tempErrorFields.editName = 'This field is mandatory';
            isValidField = false;
        }
        setErrorFields({ ...errorFields, ...tempErrorFields });
        return isValidField;
    };

    /**
     * Function to validate fields
     */
    const validateFields = () => {
        let isValidField = true;
        const tempErrorFields = {};
        if (newLabel) {
            if (labelType === 'personal') {
                const perFilteredEl = filteredLabels && filteredLabels.length
                    && filteredLabels.filter((label) => label.name.toLowerCase() === newLabel.toLocaleLowerCase());
                if (perFilteredEl && perFilteredEl.length > 0) {
                    tempErrorFields.addName = 'This name already exists. Please create with a different name';
                    isValidField = false;
                }
            }
            if (labelType === 'organization') {
                const orgFilteredEl = orgModFilteredLabels && orgModFilteredLabels.length
                    && orgModFilteredLabels.filter((label) => label.name.toLowerCase() === newLabel.toLocaleLowerCase());
                if (orgFilteredEl && orgFilteredEl.length > 0) {
                    tempErrorFields.addName = 'This name already exists. Please create with a different name';
                    isValidField = false;
                }
            }
        } else {
            tempErrorFields.addName = 'This field is mandatory';
            isValidField = false;
        }
        setErrorFields({ ...errorFields, ...tempErrorFields });
        return isValidField;
    };

    /**
    * Function to update label name
    * @param {Object} event
    */
    const handleAddNewlabelClick = (event) => {
        if (validateFields()) {
            setLoading(true);
            let requestData;
            requestData = {
                name: newLabel,
                organization: props.organizationId,
                label_type: labelType,
            };

            if (labelType === 'personal') {
                requestData = {
                    name: newLabel,
                    organization: props.organizationId,
                    label_type: labelType,
                    user: loginDetails.loginUser.id,
                };
            }

            sendRequest(
                'post',
                `api/v2/accounts/labels/${props.organizationId}/`,
                requestData,
                getRequestedHeader(),
                addNewLabelSuccess,
                requestFailure
            );
            setLabelError('');
        }
    };

    /**
     * Fucntion to handle add new label API success
     * @param {Object} res
     */
    const addNewLabelSuccess = (res) => {
        const requestData = {
            label: res.data.id,
            client_org: props.organizationId
        };

        const targetsAdded = [];
        targetsForEachLabel.map((target, index) => {
            targetsAdded.push(target.value);
        });

        const payload = {
            targets: targetsAdded,
        };
        if (targetsAdded.length > 0) {
            sendRequest(
                'put',
                '/api/risk/update_targets_for_label/',
                requestData,
                getRequestedHeader(),
                targetsUpdateSuccess,
                requestFailure,
                payload
            );
        } else {
            setLoading(false);
            setOpenAddNewLabel(false);
            setOpen(true);
            setTargetsForEachLabel([]);
            setNewLabel('');
            setLabelError('');
        }
    };

    /**
     * Function to update targets for each label on Done click
     * @param {Object} event
     */
    const targetsUpdateSuccess = (res) => {
        setOpenAddNewLabel(false);
        setOpenEditLabelModal(false);
        setOpen(true);
        setTargetsForEachLabel([]);
        setNewLabel('');
        setLoading(false);
    };

    /**
     * Function to handle label name edit
     * @param {Object} event
     */
    const handleLabelNameEdit = (event) => {
        setEditLabelDetails({
            ...editLabelDetails,
            labelName: event.target.value,
        });
        setErrorFields({ ...errorFields, editName: '' });
    };

    /**
     * Function to close Edit modal
     * @param {Object} event
     */
    const handleEditModalClose = (event) => {
        setOpenEditLabelModal(false);
        setOpen(true);
        setTargetsForEachLabel([]);
        setErrorFields({ ...errorFields, editName: '' });
    };

    /**
     * Function to close edit labels modal
     */
    const handleClosem = () => {
        if (validateEditFields()) {
            const targetsAdded = [];
            targetsForEachLabel.map((target, index) => {
                targetsAdded.push(target.value);
            });
            if (targetsAdded.length >= 1) {
                const payload = {
                    name: editLabelDetails.labelName,
                    organization: props.organizationId,
                    label_type: editLabelDetails.type,
                };

                // call API to update label name
                sendRequest(
                    'put',
                    `api/v2/accounts/label/${editLabelDetails.labelId}/`,
                    {},
                    getRequestedHeader(),
                    labelnameUpdateSuccess,
                    requestFailure,
                    payload
                );
            } else {
                toast.error('Atleast one target is required in the label');
            }
        }
    };

    /**
     * Update targets on label name update
     * @param {Object} res
     */
    const labelnameUpdateSuccess = (res) => {
        const requestData = {
            label: editLabelDetails.labelId,
            client_org: props.organizationId
        };

        if (targetsForEachLabel && targetsForEachLabel.length) {
            const targetsAdded = [];
            targetsForEachLabel.map((target, index) => {
                targetsAdded.push(target.value);
            });

            const payload = {
                targets: targetsAdded,
            };

            // call API to update targets
            sendRequest(
                'put',
                '/api/risk/update_targets_for_label/',
                requestData,
                getRequestedHeader(),
                targetsUpdateSuccess,
                requestFailure,
                payload
            );
        } else {
            setOpenEditLabelModal(false);
            setOpen(true);
            setLoading(false);
        }
    };

    /**
     * Function to open labels modal
     */
    const handleOpenm = () => {
        setOpen(true);
    };


    /**
     * Function to handle label selction
     * @param {Object} newValue
     */
    const handleLabelSelection = (newValue) => {
        if (newValue) {
            props.handleLabelChange(newValue);

        } else {
            props.handleLabelChange({});
        }
    };

    useEffect(() => {
        onClear()
    }, [props.organizationId])

    const labOpnGroup = [
        {
            label: 'personal',
            options: filteredPersonalLabels && filteredPersonalLabels.map((item) => ({ label: item.name, value: item.id })),
        },
        {
            label: 'organizational',
            options: filteredOrganizationalLabels && filteredOrganizationalLabels.map((item) => ({ label: item.name, value: item.id })),
        },
    ];

    const formatGroupLabel = (data) => (
        <div>
            <span>{data.label}</span>
        </div>
    );

    const labelOptions = [];
    labels.map((label) => {
        labelOptions.push({
            value: label.id,
            label: label.name,
        });
    });

    const isAdmin = loginDetails && loginDetails.loginUser && loginDetails.loginUser.profile && loginDetails.loginUser.profile.is_admin;
    return (
        <Box className={`${classes.labelContainer} tour-dashboard-label`}>
            <CustomizedTooltip
                placement="right"
                // eslint-disable-next-line max-len
                title="Labels will enable you to filter the targets based on any criteria such as third parties or locations that you prefer to view. Labels can be used to group your subscription portfolio that are available at single click."
            >
                <Box className={classes.mainSelectBoxSec}>
                    <Select
                        ref={selectInputRef}
                        options={labOpnGroup}
                        styles={customSelectStyles}
                        onChange={handleLabelSelection}
                        components={{ IndicatorSeparator: () => null }}
                        placeholder={'Select Label'}
                        isClearable
                        formatGroupLabel={formatGroupLabel}
                    />
                    <FilterListIcon className={classes.filterImg} />
                </Box>
            </CustomizedTooltip>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography className={classes.fontSetPortPoup} variant="h6">
                            View and Manage Labels
                            <span>
                                {' '}
                                <CloseIcon onClick={handleCloseModal} />
                            </span>
                        </Typography>
                        <Box py={3} className={classes.searchPoupInput}>
                            <SearchIcon className={classes.searchPoupInputIcon} />
                            <input
                                type="text"
                                className={classes.poupInput}
                                placeholder="Search Label by Name"
                                onChange={filterLabelhandler}
                            />
                            <button
                                className={classes.poupButton}
                                onClick={handleOpenAddNewLabelModal}
                                type="button"
                            >
                                Add New Label
                            </button>
                        </Box>
                        <Accordion
                            classes={{ rounded: classes.accordianSection }}
                            expanded={expanded === 'panel1'}
                            onChange={handleChange('panel1')}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                classes={{
                                    root: classes.inAccordianSection,
                                    expanded: classes.inAccordianSectionMargin,
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={10}>
                                        <Typography className={classes.heading}>
                                            Personal
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <Box className={classes.mainTableSec}>
                                {(filteredLabels && filteredLabels.length) ? filteredLabels.map((label) => (
                                    <table className={classes.poupTable} key={label.name}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '60%' }}>{label.name}</td>
                                                <td className={classes.poupTableTdTwo}>
                                                    {label.subscription_count}
                                                    {' '}
                                                    Targets
                                                    {' '}
                                                </td>
                                                <td className={classes.poupTableTd}>
                                                    <span
                                                        aria-hidden="true"
                                                        className={classes.editIcon}
                                                        onClick={handleEditIconClick.bind(
                                                            null,
                                                            label.id,
                                                            label.name,
                                                            'personal',
                                                        )}
                                                    >
                                                        <img src={edit} alt="edit" />
                                                        {' '}
                                                    </span>
                                                    {' '}
                                                    <span
                                                        onClick={handleLabelDelete.bind(null, label.id)}
                                                        className={classes.editIcon}
                                                        aria-hidden="true"
                                                    >
                                                        <img src={remove} alt="remove" />
                                                        {' '}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )) : <NoDataElement content="Labels Not Found" />}
                            </Box>
                        </Accordion>
                        <Accordion
                            classes={{ rounded: classes.accordianSection }}
                            expanded={expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                classes={{
                                    root: classes.inAccordianSection,
                                    expanded: classes.inAccordianSectionMargin,
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={10}>
                                        <Typography className={classes.heading}>
                                            Organizational
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <Box className={classes.mainTableSec}>
                                {(orgModFilteredLabels && orgModFilteredLabels.length) ? orgModFilteredLabels.map((label) => (
                                    <table className={classes.poupTable} key={label.name}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '60%' }}>{label.name}</td>
                                                <td className={classes.poupTableTdTwo}>
                                                    {label.subscription_count}
                                                    {' '}
                                                    Targets
                                                    {' '}
                                                </td>
                                                <td className={classes.poupTableTd}>
                                                    {isAdmin && (
                                                        <span
                                                            aria-hidden="true"
                                                            className={classes.editIcon}
                                                            onClick={handleEditIconClick.bind(
                                                                null,
                                                                label.id,
                                                                label.name,
                                                                'organization',
                                                            )}
                                                        >
                                                            <img src={edit} alt="edit" />
                                                            {' '}
                                                        </span>
                                                    )}
                                                    {' '}
                                                    {isAdmin && (
                                                        <span
                                                            onClick={handleOrganizationLabelDelete.bind(null, label.id)}
                                                            className={classes.editIcon}
                                                            aria-hidden="true"
                                                        >
                                                            <img src={remove} alt="remove" />
                                                            {' '}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )) : <NoDataElement content="Labels Not Found" />}
                            </Box>
                        </Accordion>
                        <Divider className={classes.poupHr} />
                        <Box className={classes.mainBottonButton}>
                            <button
                                className={classes.bottomPoupButton}
                                onClick={handleCloseModal}
                                type="button"
                            >
                                Done
                            </button>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAddnewLabel}
                onClose={handleCloseAddNewLabelModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAddnewLabel}>
                    <div className={classes.paper}>
                        <Typography className={classes.fontSetPortPoup} variant="h6">
                            Add New Label
                        </Typography>
                        <Box py={3}>
                            <Box className={classes.searchPoupInput}>
                                <input
                                    type="text"
                                    className={classes.poupInputLab}
                                    placeholder="Label Name"
                                    onChange={handleLabelAddition}
                                    value={newLabel}
                                />
                            </Box>
                            {errorFields.addName ? (
                                <Typography
                                    className={classes.errorType}
                                    color="error"
                                >
                                    {errorFields.addName}
                                </Typography>
                            ) : null}
                        </Box>
                        <InputLabel className={classes.addRemove}>
                            Add/Remove Targets
                        </InputLabel>
                        <Box py={1} className={classes.targetFilterCont}>
                            <SearchIcon className={classes.searchPoupInputIconAuto} />
                            <CustomSelect
                                isMulti
                                isClearable
                                placeholder="Search and add targets to this label"
                                options={targetOption}
                                onChange={handleMultiFilter}
                                name="textsearch"
                                styles={customSelectStylesTarget}
                                value={targetsForEachLabel}
                            />
                        </Box>
                        <InputLabel className={classes.addRemove}>
                            Label Type
                        </InputLabel>
                        <Box>
                            <RadioGroup
                                classes={{ root: classes.radioSecionRight }}
                                aria-label="gender"
                                name="gender1"
                                defaultValue="personal"
                                onChange={handleTypeChange}
                            >
                                <FormControlLabel
                                    classes={{ label: classes.radioSecionFontLeftSec }}
                                    value="personal"
                                    control={<CustomRadio />}
                                    label="Personal"
                                />
                                {isAdmin && (
                                    <FormControlLabel
                                        classes={{ label: classes.radioSecionFont }}
                                        value="organization"
                                        control={<CustomRadio />}
                                        label="Organizational"
                                    />
                                )}
                            </RadioGroup>
                        </Box>
                        {
                            labelError ? <NoDataElement content={labelError} /> : null
                        }
                        {
                            loading ? <Loader isInline /> : null
                        }
                        <Divider className={classes.poupHr} />
                        <Box className={classes.modalBtnContainerCancel}>
                            <Box className={classes.mainBottonButton}>
                                <button
                                    className={classes.bottomPoupButtonCancel}
                                    onClick={handleAddModalClose}
                                    type="button"
                                >
                                    Cancel
                                </button>
                            </Box>
                            <Box className={classes.mainBottonButton}>
                                <button
                                    className={classes.bottomPoupButton}
                                    onClick={handleAddNewlabelClick}
                                    type="button"
                                >
                                    Done
                                </button>
                            </Box>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openEditLabel}
                onClose={handleCloseAddNewLabelModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openEditLabel}>
                    <div className={classes.paper}>
                        <Typography className={classes.fontSetPortPoup} variant="h6">
                            Edit Label
                        </Typography>
                        <Box py={3}>
                            <Box className={classes.searchPoupInput}>
                                <input
                                    type="text"
                                    className={classes.poupInputLab}
                                    placeholder="Label Name"
                                    onChange={handleLabelNameEdit}
                                    value={editLabelDetails.labelName}
                                />
                            </Box>
                            {errorFields.editName ? (
                                <Typography
                                    className={classes.errorType}
                                    color="error"
                                >
                                    {errorFields.editName}
                                </Typography>
                            ) : null}
                        </Box>
                        <Typography className={classes.fontSetPortPoup} variant="h6">
                            Add/Remove Targets
                        </Typography>
                        <Box py={1} className={classes.targetFilterCont}>
                            <SearchIcon className={classes.searchPoupInputIconAuto} />
                            <CustomSelect
                                isMulti
                                isClearable
                                placeholder="Search and add targets to this label"
                                options={targetOption}
                                onChange={handleMultiFilter}
                                name="textsearch"
                                styles={customSelectStylesTarget}
                                value={targetsForEachLabel}
                            />
                        </Box>
                        <Divider className={classes.poupHr} />
                        <Box className={classes.modalBtnContainer}>
                            <Box className={classes.mainBottonButton}>
                                <button
                                    className={classes.bottomPoupButton}
                                    onClick={handleEditModalClose}
                                    type="button"
                                >
                                    Cancel
                                </button>
                            </Box>
                            <Box className={classes.mainBottonButton}>
                                <button
                                    className={classes.bottomPoupButton}
                                    onClick={handleClosem}
                                    type="button"
                                >
                                    Done
                                </button>
                            </Box>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            {
                !props.removeEditLabels ? (
                    <Box pl={1}>
                        <Typography
                            color="primary"
                            className={classes.fontSetEdit}
                            variant="subtitle1"
                            onClick={handleOpenm}
                        >
                            Edit Labels
                        </Typography>
                    </Box>
                ) : null
            }
        </Box>
    );
};

export default LabelComponent;
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/align.min.js';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import FroalaEditor from 'react-froala-wysiwyg';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Grid from '@material-ui/core/Grid';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LoaderView from './LoaderView';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import sendRequest from '../../httpRequest/index';

const breakpoints = createBreakpoints({});
const useStyles = makeStyles((theme) => ({
    heading: {
        backgroundColor: '#f0f5fb',
        padding: '6px 12px 0px 12px',
        borderRadius: '4px',
        marginBottom: '7px',
        '& span': {
            marginRight: '15px',
            verticalAlign: '-webkit-baseline-middle',
            '& svg': {
                color: '#204565',
                fontSize: '20px',
            },
        },
    },
    analystContainer: {
        width: '50vw',
        padding: '7px 7px 20px 7px',
        backgroundColor: 'White',
        boxShadow: '0px 2px 12px #00000046',
        border: '1px solid #70707010',
        borderRadius: '5px',
        position: 'fixed',
        right: 61,
        bottom: 92,
        transition: '.6s',
        zIndex: 999,
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            right: 0,
        },
    },

    analystContainerExt: {
        width: '32vw',
        padding: '7px 7px 20px 7px',
        backgroundColor: 'White',
        boxShadow: '0px 2px 12px #00000046',
        border: '1px solid #70707010',
        borderRadius: '5px',
        position: 'fixed',
        right: 61,
        bottom: 92,
        transition: '.6s',
        zIndex: 999,
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            right: 0,
        },
    },
    selectContainerClass: {
        zIndex: 10000,
    },
    expandIcon: {
        float: 'right',
        cursor: 'pointer',
    },
    buttonSect: {
        width: '60px',
        height: '60px',
        borderRadius: '14px',
        boxShadow: '0px 2px 12px #0000002E',
        position: 'fixed',
        right: 61,
        bottom: 25,
        zIndex: 10,
        '& span': {
            margin: '0px',
        },
        '& svg': {
            fontSize: '28px !important',
        },
        [breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    buttonSectCros: {
        width: '60px',
        height: '60px',
        borderRadius: '14px',
        boxShadow: '0px 2px 12px #0000002E',
        position: 'fixed',
        backgroundColor: '#fff',
        right: 61,
        bottom: 25,
        zIndex: 10,
        '& span': {
            margin: '0px',
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '& svg': {
            fontSize: '28px !important',
            color: '#FF4848',
        },
    },

    ediTorSec: {
        margin: '10px 0px',
    },
    buttonSectPart: {
        textAlign: 'right',
    },
    mandatoryError: {
        fontSize: '14px',
        color: '#f44336',
    },
}));

const CustomizedTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ececec',
        color: '#000',
        boxShadow: theme.shadows[3],
        fontSize: 11,
        padding: '10px',
        fontFamily: 'Gilroy-Semibold',
    },
}))(Tooltip);

const AskAnAnalyst = (props) => {
    const classes = useStyles();
    const { actionProps, stateProps } = props;
    const { analystTargets: targetOptions } = stateProps;
    const [isOpen, toggleView] = useState(false);
    const [isExpanded, changeSize] = useState(false);
    // const [targetOptions, setTargetOptions] = useState([]);
    const [isTargetOptionsLoading, setTargetOptionsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [subject, setSubject] = useState('general');
    const [selectedTarget, setSelectedTarget] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const froala = useRef();
    const dispatch = useDispatch();

    const subjectOptions = [
        { value: 'general', label: 'General' },
        { value: 'alertRelated', label: 'Alert Related' },
        { value: 'reportRelated', label: 'Report Related' },
        { value: 'addUser', label: 'Add User' },
        { value: 'addTarget', label: 'Add Target' },
    ];

    /**
     * After load, calling for the list of Targets.
     */
    useEffect(() => {
        if (targetOptions.length === 0) {
            sendRequest(
                'get',
                '/api/v2/accounts/ask-analyst-targets/',
                {},
                getRequestedHeader(),
                ({ data }) => {
                    setTargetsDataOption(data);
                    setTargetOptionsLoading(false);
                },
                (error) => {
                    setTargetOptionsLoading(false);
                    setErrorMessage(error.message);
                }
            );
        }
    }, []);

    /**
     * Sets the data for the TargetOptions
     */
    const setTargetsDataOption = (data) => {
        const result = [];
        for (let index = 0; index < data.length; index++) {
            const targetOptions = [];
            const targetCategory = data[index];

            for (let childIndex = 0; childIndex < targetCategory.targets.length; childIndex++) {
                const target = targetCategory.targets[childIndex];
                targetOptions.push({ label: target.name, value: target.id });
            }

            result.push({ label: targetCategory.targetTypeName, options: targetOptions });
        }
        dispatch(actionProps.setAnalystTargets(result));
    };

    /**
     * Show and Hide, Ask An Analyst View.
     */
    const toggleAskAnAnalyst = () => {
        toggleView(!isOpen);
        setErrorMessage('');
    };

    const validateFields = () => {
        if (subject && froala.current.editor.html.get()) {
            return true;
        }
        return false;
    };

    /**
     * Function to send the API request to the Analyst.
     */
    const sendAskAnAnalystRequest = () => {
        if (validateFields()) {
            setIsLoading(true);
            sendRequest(
                'post',
                '/api/v2/accounts/ask-query/',
                {
                    query_type: 'ask-an-analyst',
                    subject,
                    message: froala.current.editor.html.get(),
                    target: selectedTarget ? selectedTarget.value : null,
                    additional_info: { targetName: selectedTarget.label || '', page_url: window.location.href },
                },
                getRequestedHeader(),
                ({ data }) => {
                    setIsLoading(false);
                    if (data === 'Thank you') {
                        toast.success('Your query is submitted, we will revert back to you soon.');
                        setSubject('general');
                        froala.current.editor.html.set('');
                        toggleView(false);
                    }
                },
                (error) => {
                    setIsLoading(false);
                    setErrorMessage(error.message);
                }
            );
        } else {
            setErrorMessage('Enter Required Fields (Subject & Message).');
        }
    };

    /**
     * Function to change the text of the Subject field.
     * @param {Object} Select Box response selected item.
     */
    const changeSubject = (item) => {
        setSubject(item.value);
        setErrorMessage('');
    };

    /**
     * Function to change the text of the Target field.
     * @param {Object} Select Box response selected item.
     */
    const changeTarget = (item) => {
        setSelectedTarget(item);
    };

    /**
     * Function to change the size of the AskAnAnalyst window.
     */
    const toggleTheArea = () => {
        changeSize(!isExpanded);
    };

    /**
     * Function to change get the Analyst view.
     */
    const getAnalystView = () => {
        if (isOpen) {
            return (
                <div
                    className={isExpanded ? classes.analystContainer : classes.analystContainerExt}
                >
                    <div className={classes.heading}>
                        <span>
                            <LiveHelpIcon />
                        </span>
                        Have a Question, Ask our Analyst.
                        {isExpanded ? (
                            <span>
                                {' '}
                                <FullscreenExitIcon
                                    classes={{
                                        root: classes.expandIcon,
                                    }}
                                    onClick={toggleTheArea}
                                />
                            </span>
                        ) : (
                            <span>
                                <OpenWithIcon
                                    classes={{
                                        root: classes.expandIcon,
                                    }}
                                    onClick={toggleTheArea}
                                />
                            </span>
                        )}
                    </div>
                    <Grid container spacing={2} className={classes.selectContainerClass}>
                        <Grid item xs={6}>
                            <Select
                                styles={{
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                placeholder="Subject"
                                onChange={changeSubject}
                                options={subjectOptions}
                                value={
                                    subjectOptions.filter((option) => option.value === subject)[0]
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                styles={{
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                placeholder="Target"
                                onChange={changeTarget}
                                options={targetOptions}
                                isLoading={isTargetOptionsLoading}
                                value={
                                    targetOptions.filter((option) => option.value === subject)[0]
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid className={classes.ediTorSec} container>
                        <Grid item xs={12}>
                            <FroalaEditor
                                ref={froala}
                                tag="textarea"
                                config={{
                                    attribution: false,
                                    toolbarButtons: {
                                        moreText: {
                                            // eslint-disable-next-line max-len
                                            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                                        },
                                        moreParagraph: {
                                            // eslint-disable-next-line max-len
                                            buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                                        },
                                    },
                                    height: 150,
                                    events: {
                                        focus: () => {
                                            setErrorMessage('');
                                        },
                                    },
                                    key: 'BWC6D-16B3C2F3F1C2A9vC-22D-17zD6G-10eabB-31mbE-13dG-10vwI2C-21rscD3F3H3D2G3A4D4B6D2D1==',
                                }}
                                styles={{ height: '400px' }}
                            />
                        </Grid>
                    </Grid>
                    <span className={classes.mandatoryError}>{errorMessage}</span>
                    <Grid container>
                        <Grid item className={classes.buttonSectPart} xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={sendAskAnAnalystRequest}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        return null;
    };

    /**
     * Function to show the right button on the basis of user selection.
     */
    const getButtonView = () => {
        if (isOpen) {
            return (
                <Button
                    variant="contained"
                    size="large"
                    startIcon={<CloseIcon />}
                    onClick={toggleAskAnAnalyst}
                    className={classes.buttonSectCros}
                />
            );
        }
        return (
            <CustomizedTooltip title="Ask an Analyst" placement="left">
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<LiveHelpIcon />}
                    onClick={toggleAskAnAnalyst}
                    className={classes.buttonSect}
                />
            </CustomizedTooltip>
        );
    };

    return (
        <div className={classes.absoluteView}>
            {isLoading ? <LoaderView /> : null}
            {getAnalystView()}
            {getButtonView()}
        </div>
    );
};

export default AskAnAnalyst;

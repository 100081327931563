import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import React from 'react';

const NoDataElement = (props) => {
    const { content, style } = props;
    return (
        <Alert severity="info">{content}</Alert>
    );
};

export default NoDataElement;

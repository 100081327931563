import { Box, Tooltip } from '@material-ui/core';
import { RISK_CATEGORY_COLORS } from '../constants';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import equalico from '../../../Images/equalico.svg';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(() => ({
    heading_weight: {
        fontWeight: '700',
    },
    rightTableSection: {
        '& table': {
            borderSpacing: '0px 0px',
            width: '100%',
            '& td ': {
                fontSize: '18px',
                padding: '8px 0px 8px 5px',
                verticalAlign: 'baseline',
                position: 'relative',
                [breakpoints.down('sm')]: {
                    fontSize: '14px',
                    padding: '8px 0px 8px 5px',
                },
            },
            '& th': {
                fontSize: '18px',
                textAlign: 'center',
                verticalAlign: 'baseline',
                padding: '5px',
                color: '#07212B',
                backgroundColor: '#D9E7F4',
                fontFamily: 'Gilroy-Semibold',
                [breakpoints.down('sm')]: {
                    fontSize: '14px',
                    padding: '8px 0px 8px 5px',
                },
                '&:nth-child(1)': {
                    width: '25%',
                },
                '&:nth-child(3)': {
                    width: '150px',
                },
            },
        },
    },
    borderBottom: {
        '& td ': {
            'border-bottom': '1px solid hsl(0, 0%, 80%)',
        },
    },

    arrowUpIcon: {
        width: '25px',
        display: 'inline-block',
        textAlign: 'center',
        [breakpoints.down('sm')]: {
            width: '20px',
        },
    },
    arrowUpIconDig: {
        width: '40px',
        display: 'inline-block',
        textAlign: 'left',
        [breakpoints.down('sm')]: {
            width: '28px',
        },
    },
    arrowUpIconn: {
        fontSize: '30px',
        verticalAlign: 'bottom',
        width: '25px',
        display: 'inline-block',
        textAlign: 'center',
    },

    floatNumver: {
        fontSize: '14px',
        fontFamily: 'Gilroy-Medium',
    },

    ownerShipSec: {
        color: '#fff',
        borderRadius: '2px',
        padding: '4px 15px',
        width: '100%',
    },

    heading: {
        color: '#fff',
        backgroundColor: '#C62C27',
        borderRadius: '2px',
        padding: '4px 15px',
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },

    mainOwnerShipSec: {
        border: '1px solid #7070704B',
        padding: '5px',
        marginTop: '35px',
    },

    blackColorSet: {
        cursor: 'pointer',
        color: '#428BCA',
        width: '250px',
    },

    inMainOwnerShipSec: {
        border: '5px solid #E7F1E2',
        padding: '7px',
    },

    arrowBackicon: {
        marginLeft: '7px',
    },

    tdCenter: {
        '& td': {
            textAlign: 'center',
        },
    },
}));

const CustomizedTooltip = withStyles(() => ({
    tooltip: {
        maxWidth: 220,
        fontSize: '14px',
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const TargetLists = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        parentRisk, actionableItems, categoryId, subCategoryId,
    } = props;
    let listViewLeft; let listViewRight; let listView; let
        listViewHeader;

    // Set the heading color according to the risk clicked
    let colorKey = '';

    if (parentRisk) {
        if (parentRisk.toLowerCase() === 'medium') {
            colorKey = 'MODERATE_RISK';
        } else {
            colorKey = `${parentRisk.toUpperCase()}_RISK`;
        }
    }
    const backgroundColor = RISK_CATEGORY_COLORS[colorKey];
    const { listData } = props;
    const targets = [];
    const targetDetails = [];
    const isReportingView = listData;

    if (isReportingView) {
        listData.targets.forEach((target) => {
            const scoreChange = Number(target.score) - Number(target.old_score);
            let scoreChangeIcon = null;
            if (scoreChange === 0) {
                scoreChangeIcon = (
                    <img
                        src={equalico}
                        alt="score view"
                    />
                );
            } else if (scoreChange < 0) {
                scoreChangeIcon = <ArrowDropDownIcon className={classes.arrowUpIconn} style={{ color: 'green' }} />;
            } else {
                scoreChangeIcon = <ArrowDropUpIcon className={classes.arrowUpIconn} style={{ color: 'red' }} />;
            }
            targets.push({
                id: target.id,
                name: target.name,
                count: target.score,
                scoreChangeIcon,
                scoreChange,
                catId: categoryId,
                subCatId: subCategoryId,
            });
        });
    } else {
        props.actionableItems.forEach((target) => {
            target.categories.forEach((category, index) => {
                const scoreChange = category.score - category.old_score;
                let scoreChangeIcon = null;
                const targetId = target.id;
                const targetName = index === 0 ? target.name : '';
                if (scoreChange === 0) {
                    scoreChangeIcon = (
                        <img
                            src={equalico}
                            alt="score view"
                        />
                    );
                } else if (scoreChange < 0) {
                    scoreChangeIcon = <ArrowDropDownIcon className={classes.arrowUpIconn} style={{ color: 'green' }} />;
                } else {
                    scoreChangeIcon = <ArrowDropUpIcon className={classes.arrowUpIconn} style={{ color: 'red' }} />;
                }
                let last;
                if (index === target.categories.length - 1) {
                    last = true;
                }

                targetDetails.push({
                    id: targetId,
                    name: targetName,
                    catId: category.id,
                    category: category.name,
                    score: category.score,
                    scoreChange,
                    scoreChangeIcon,
                    last,
                });
            });
        });
    }

    // List creation for recent changes - reporting view / alert potfolio view
    if (isReportingView) {
        listViewLeft = targets.filter((target, index) => index % 2 === 0)
            .map((target) => (
                <tr className={classes.tdCenter}>
                    <td
                        aria-hidden="true"
                        className={`${classes.blackColorSet} ${classes.heading_weight}`}
                        onClick={
                            () => {
                                history.push({
                                    pathname: '/targetProfile',
                                    search: `?id=${target.id}&categoryId=${target.catId}&subCategoryId=${target.subCatId}`,
                                });
                            }
                        }
                    >
                        {' '}
                        {target.name}
                    </td>
                    <td >
                        <span className={classes.arrowUpIconDig}>
                            {target.count}
                        </span>
                    </td>
                    <td>
                        <span>
                            <span className={classes.arrowUpIcon}>
                                {' '}
                                {target.scoreChangeIcon}
                                { ' '}
                            </span>
                            {' '}
                            <span className={classes.floatNumver}>
                                {target.scoreChange > 0 ? `+${target.scoreChange.toFixed(2)}` : target.scoreChange.toFixed(2)}
                            </span>
                        </span>
                    </td>
                </tr>
            ));

        listViewRight = targets.filter((target, index) => index % 2 === 1)
            .map((target) => (
                <tr className={classes.tdCenter}>
                    <td
                        aria-hidden="true"
                        className={`${classes.blackColorSet} ${classes.heading_weight}`}
                        onClick={() => {
                            history.push({
                                pathname: '/targetProfile',
                                search: `?id=${target.id}&categoryId=${target.catId}&subCategoryId=${target.subCatId}`,
                            });
                        }}
                    >
                        {' '}
                        {target.name}
                    </td>
                    <td>
                        <span className={classes.arrowUpIconDig}>
                            {target.count}
                        </span>
                    </td>
                    <td>
                        <span>
                            <span className={classes.arrowUpIcon}>
                                {' '}
                                {target.scoreChangeIcon}
                                { ' '}
                            </span>
                            {' '}
                            <span className={classes.floatNumver}>
                                {target.scoreChange > 0 ? `+${target.scoreChange.toFixed(2)}` : target.scoreChange.toFixed(2)}
                            </span>
                            {' '}
                        </span>
                    </td>
                </tr>
            ));

        listViewHeader = (
            <Typography
                variant="h6"
                className={classes.ownerShipSec}
                style={{ backgroundColor }}
            >
                {props.heading}
            </Typography>
        );
    } else {
        listView = targetDetails.map((target) => {
            let borderBottom = '';
            if (target.last) {
                borderBottom = '1px solid hsl(0, 0%, 80%)';
            }
            return (
                <tr key={target.id + target.score + target.scoreChange} className={classes.tdCenter}>
                    <td
                        aria-hidden="true"
                        className={`${classes.blackColorSet} ${classes.heading_weight}`}
                        onClick={() => {
                            history.push({
                                pathname: '/targetProfile',
                                search: `?id=${target.id}`,
                            });
                        }}
                        style={{ borderBottom }}
                    >
                        {' '}
                        {target.name}
                    </td>
                    <td
                        aria-hidden="true"
                        className={classes.blackColorSet}
                        onClick={() => {
                            history.push({
                                pathname: '/targetProfile',
                                search: `?id=${target.id}&categoryId=${target.catId}`,
                            });
                        }}
                        style={{ borderBottom }}
                    >
                        {' '}
                        {target.category}
                    </td>
                    <td style={{ borderBottom }}>
                        <span className={classes.arrowUpIconDig}>
                            {target.score}
                        </span>
                    </td>
                    <td style={{ borderBottom }}>
                        <span className={classes.arrowUpIcon}>
                            {' '}
                            {target.scoreChangeIcon}
                            {' '}
                        </span>
                        {' '}
                        <span className={classes.floatNumver}>
                            {target.scoreChange.toFixed(2)}
                        </span>
                    </td>
                </tr>
            );
        });
        listViewHeader = (
            <Typography
                variant="h6"
                className={classes.heading}
                onClick={props.handledrillDown}
                style={{ backgroundColor }}
            >
                <ArrowBackIcon className={classes.arrowBackicon} />
                <span className={classes.arrowBackicon}>{props.headingCount}</span>
                <span className={classes.arrowBackicon}>{props.heading}</span>
            </Typography>
        );
    }

    return (
        <Box className={classes.mainOwnerShipSec}>
            <Box className={classes.inMainOwnerShipSec}>
                {actionableItems ? (
                    <Grid container direction="row" spacing={1}>
                        { listViewHeader }
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                            <Box className={classes.rightTableSection}>
                                <table>
                                    <colgroup>
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Target</th>
                                            <th>Domain</th>
                                            <th>Current Rating</th>
                                            <th>
                                                <CustomizedTooltip title="Change from previous quarter">
                                                    <span>
                                                        Change
                                                    </span>
                                                </CustomizedTooltip>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { listView }
                                    </tbody>
                                </table>
                            </Box>
                        </Grid>
                    </Grid>
                )
                    : (
                        <Grid container direction="row" spacing={1}>
                            { listViewHeader }
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                                <Box className={`${classes.rightTableSection} ${classes.borderBottom}`}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Target</th>
                                                <th>Current Score</th>
                                                <th>
                                                    <CustomizedTooltip title="Change from previous quarter">
                                                        <span>
                                                            Change
                                                        </span>
                                                    </CustomizedTooltip>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listViewLeft }
                                        </tbody>
                                    </table>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                                <Box className={`${classes.rightTableSection} ${classes.borderBottom}`}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Target</th>
                                                <th>Current Score</th>
                                                <th>
                                                    <CustomizedTooltip title="Change from previous quarter">
                                                        <span>
                                                            Change
                                                        </span>
                                                    </CustomizedTooltip>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listViewRight }
                                        </tbody>
                                    </table>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
            </Box>
        </Box>
    );
};

export default TargetLists;

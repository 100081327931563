import { checkEmptyObject, getLoginDetail, getToken } from '../helper/commonHelper';
import { loginUserDetail } from '../redux/actions/loginAction';
import { setTargetData } from '../redux/actions/targetAction';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../components/dashboard';
import React from 'react';
import setCategories from '../redux/actions/categoryAction';
import setTargetTypes from '../redux/actions/targetTypeAction';

/**
 * This component acts as a container for the dashboard component
 * @param {Object} props
 */
const DashboardContainer = (props) => {
    const dispatch = useDispatch();
    let accessToken = useSelector((state) => state.LoginReducer.accessToken);
    const loginUserData = useSelector((state) => state.LoginReducer.loginDetail);
    const reportId = useSelector((state) => state.NavbarReducer.reportIdSelected);
    const raasAccount = useSelector((state) => state.NavbarReducer.raasAccountSelected);
    const categoryList = useSelector((state) => state.CategoriesReducer.categories);
    const raasAccountSelected = useSelector((state) => state.NavbarReducer.raasAccountSelected);

    if (!accessToken) {
        accessToken = getToken || accessToken;
    }

    if (checkEmptyObject(loginUserData)) {
        const data = getLoginDetail();
        dispatch(loginUserDetail(data));
    }
    const loginDetails = { accessToken, loginUser: checkEmptyObject(loginUserData) ? getLoginDetail() : loginUserData.loginDetail };
    const actionProps = { setTargetData, setTargetTypes, setCategories };
    const stateProps = {
        targetData: useSelector((state) => state.TargetReducer.targetData),
    };
    const reportDetails = { id: reportId };
    return (
        <Dashboard
            {...props}
            LoginDetails={loginDetails}
            actionProps={actionProps}
            reportDetails={reportDetails}
            raasAccount={raasAccount}
            categoryList={categoryList}
            raasAccountSelected={raasAccountSelected}
            stateProps={stateProps}
        />
    );
};

export default DashboardContainer;

import 'leaflet/dist/leaflet.css';
import {
    Marker, Tooltip,
} from 'react-leaflet';
import { RISK_CATEGORY_RANGE } from '../common/constants';
import { getRequestedHeader } from '../../helper/commonHelper';
import { useHistory } from 'react-router-dom';
import L from 'leaflet';
import Loader from '../common/LoaderView';
import React, { Fragment, useEffect, useState } from 'react';
import WorldmapView from '../common/charts/WorldmapView';
import locationOrangeIcon from '../../Images/locationOrange.svg';
import locationRedIcon from '../../Images/locationRed.svg';
import locationYellowIcon from '../../Images/locationYellow.svg';
import parse from 'html-react-parser';
import sendRequest from '../../httpRequest';

const MapViewCities = (props) => {
    const [cityData, setCityData] = useState([]);
    const [loading, setLoadingStatus] = useState(false);
    const [latLongDetails, setLatLongDetails] = useState([]);
    const { targetLists } = props;
    const history = useHistory();
    const center = [50, 20];
    const ZOOM_LEVEL = 1;

    const countryStyle = {
        fillColor: '#fff',
        fillOpacity: 1,
        color: '#000',
        weight: 0.4,
    };

    /**
   * Function to select marker based on rating
   * @param {Object}
   */
    const selectMarkerIcon = (item) => {
        const rating = item.risk_categories && item.risk_categories.length && item.risk_categories[0].score;
        let icon;
        if (rating > RISK_CATEGORY_RANGE.LOW_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT) {
            icon = new L.Icon({
                iconUrl: locationYellowIcon,
                iconSize: [20, 20],
            });
        } else if (
            rating > RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT
        ) {
            icon = new L.Icon({
                iconUrl: locationOrangeIcon,
                iconSize: [20, 20],
            });
        } else if (
            rating > RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT
        ) {
            icon = new L.Icon({
                iconUrl: locationRedIcon,
                iconSize: [20, 20],
            });
        }
        return icon;
    };

    /**
   * Function to handle API call success
   * @param {Array} success
   */
    const latLangSuccess = (res) => {
        setLoadingStatus(false);
        if (res) {
            setLatLongDetails(res.data);
        }
    };

    /**
   * Function to handle API call failure
   * @param {Object} error
   */
    const requestFailure = (error) => {
        setLoadingStatus(false);
        console.log(error);
    };

    useEffect(() => {
        if (targetLists && targetLists.length) {
            const targetIds = [];
            targetLists.map((target) => targetIds.push(target.id));
            const uniqueIds = targetIds && targetIds.filter((c, index) => targetIds.indexOf(c) === index);
            const data = { target_ids: uniqueIds };
            if (uniqueIds.length) {
                setLoadingStatus(true);
                sendRequest(
                    'post',
                    'api/v2/get-lattitude-longitude/',
                    data,
                    getRequestedHeader(),
                    latLangSuccess,
                    requestFailure,
                );
            }
        }
    }, [targetLists]);

    useEffect(() => {
        if (targetLists.length && latLongDetails.length) {
            const modData = [];
            for (let i = 0; i < latLongDetails.length; i++) {
                const target = latLongDetails[i];
                const selectedData = targetLists.find((city) => city.id === target.id);
                if (selectedData) {
                    let table = `<div style="font-size:12px; font-weight:600">${selectedData.name || ''}</div>
                    <table style="font-size:12px;"><tr style="margin-bottom:2px;"><th align="left">Risk Domain</th><th>Rating</th></tr>`;
                    // eslint-disable-next-line no-unused-expressions
                    selectedData.risk_categories.length && selectedData.risk_categories.forEach((data) => {
                        table += `<tr><td >${data.name || ''}</td><th>${data.score || ''}</th></tr>`;
                    });
                    table += '</table>';
                    modData.push({
                        name: selectedData.name,
                        id: selectedData.id,
                        risk_categories: selectedData.risk_categories,
                        lattitude: target.lattitude,
                        longitude: target.longitude,
                        tooltip: table,
                    });
                }
            }
            setCityData(modData);
        }
    }, [targetLists, latLongDetails]);

    /**
   * Function to be executed on marker click
   * @param {id}
   */
    const onMarkerClick = (targetId) => {
        if (targetId) {
            history.push(
                {
                    pathname: '/targetProfile',
                    search: `?id=${targetId}`,
                }
            );
        }
    };

    return (
        <Fragment>
            { loading ? <Loader loading={loading} /> : (
                <WorldmapView
                    ZOOM_LEVEL={ZOOM_LEVEL}
                    center={center}
                    data={window.geoData && window.geoData.features}
                    countryStyle={countryStyle}
                    title="Worldwide Risk Concentration"
                >
                    {
                        (cityData.length ? (cityData.map((item) => {
                            if (item.risk_categories && item.risk_categories.length
                                && item.risk_categories[0].score && item.risk_categories[0].score >= 5) {
                                return (
                                    <Marker
                                        key={item.id}
                                        position={[item.lattitude, item.longitude]}
                                        icon={selectMarkerIcon(item)}
                                        onClick={() => onMarkerClick(item.id)}
                                    >
                                        <Tooltip>{parse(item.tooltip)}</Tooltip>
                                    </Marker>
                                );
                            }
                            return null;
                        })) : '')
                    }
                </WorldmapView>
            )}
        </Fragment>
    );
};

export default MapViewCities;

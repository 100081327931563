import { Box } from '@material-ui/core';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Loader from '../common/LoaderView';
import React, { useEffect, useState } from 'react';
import TargetAlertList from '../common/listView/TargetAlertListView';
import Typography from '@material-ui/core/Typography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import sendRequest from '../../httpRequest';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(() => ({
    topMainSecAccordian: {
        marginBottom: '10px !important',
    },
    accordianIcon: {
        fontSize: '35px',
        color: '#204565',
    },
    mainSecAccordian: {
        backgroundColor: '#F5F8FC',
        minHeight: '50px !important',
        alignItems: 'center',
        paddingLeft: '45px',
        position: 'relative',
        border: '1px solid #7070704B',
        borderRadius: '4px',
    },
    accodianHeading: {
        justifyContent: 'space-between',
        margin: '0px !important',
        alignItems: 'center',
        '& img': {
            width: '45%',
        },
    },
    expendIcon: {
        position: 'absolute',
        left: '0px',
        padding: '0px',
        transform: 'rotate(-90deg)',
    },
    expendIconSect: {
        transform: 'rotate(0deg) !important',
    },
    accordianImgSec: {
        textAlign: 'right',
        width: '100px',
        paddingTop: '7px',
    },
    accoDetailSec: {
        padding: '0px',
        '& table': {
            width: '100%',
            '& td ': {
                fontSize: '15px',
                textAlign: 'center',
                verticalAlign: 'baseline',
                padding: '10px 0px 10px 15px',
                position: 'relative',
                [breakpoints.between('1000', '1350')]: {
                    padding: '4px 0px 4px 5px',
                },
            },
            '& th': {
                fontSize: '16px',
                textAlign: 'left',
                verticalAlign: 'baseline',
                padding: '10px 0px 10px 15px',
                color: '#204565',
                [breakpoints.between('1000', '1350')]: {
                    padding: '13px 0px 13px 5px',
                },
            },
        },
    },
    heading: {
        cursor: 'pointer',
    },

}));

const RiskiestByTargets = (props) => {
    const classes = useStyles();
    const [targetLists, setTargetLists] = useState([]);
    const [loading, setLoadingStatus] = useState(false);
    const [expandeda, setExpandeda] = React.useState(0);

    const { raasAccountSelected } = props;
    const history = useHistory();

    /**
     * Callback function for API success
     * @param {Object} res
     */
    const requestSuccess = (res) => {
        setLoadingStatus(false);
        let targets = [];
        if (res.data) {
            targets = res.data.targets.map((target, index) => {
                target.risk_categories.sort((firstCategory, secondCategory) => parseInt(secondCategory.score) - parseInt(firstCategory.score));
                return target;
            });
        }
        setTargetLists(targets);
        if (targets[0]) {
            setExpandeda(targets[0].id);
        }
    };

    /**
     * Callback function for API failure
     * @param {Object} error
     */
    const requestFailure = (error) => {
        setLoadingStatus(false);
        console.log(error);
    };

    useEffect(() => {
        if (props && props.targetId && props.reportId) {
            setLoadingStatus(true);
            const requestData = {
                target_type: props.targetId,
                report_type: props.reportId,
                quarter: props.quarter,
                year: props.year,
                label: props.label,
                client_org: raasAccountSelected.id,
            };

            if (props.targetFilter === 'byFindings') {
                requestData.limit = 10;
                sendRequest('get', 'api/v2/risk/riskiest/findings/', requestData, getRequestedHeader(), requestSuccess, requestFailure);
            } else if (props.targetFilter === 'byCompositeScore') {
                sendRequest('get', 'api/v2/risk/riskiest/composite/', requestData, getRequestedHeader(), requestSuccess, requestFailure);
            }
        }
    }, [props.targetId, props.reportId, props.quarter, props.label, props.year, props.targetFilter, raasAccountSelected]);

    const handleChange = (targetId) => (event, isExpanded) => {
        if (isExpanded) {
            setExpandeda(targetId);
        }
    };

    return (loading ? <Loader loading={loading} /> : (
        <Box pt={2}>
            {
                targetLists.map((target) => (
                    <Accordion
                        className={classes.topMainSecAccordian}
                        expanded={expandeda === target.id}
                        onChange={handleChange(target.id)}
                        key={target.id}
                        name={target.id}
                    >
                        <AccordionSummary
                            expandIcon={(
                                <ArrowDropDownIcon
                                    className={classes.accordianIcon}
                                />
                            )}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.mainSecAccordian}
                            classes={{
                                content: classes.accodianHeading,
                                expandIcon: classes.expendIcon,
                                expanded: classes.expendIconSect,
                            }}
                        >
                            <Typography
                                className={classes.heading}
                                variant="subtitle2"
                                onClick={() => {
                                    if (expandeda === target.id) {
                                        history.push(
                                            {
                                                pathname: '/targetProfile',
                                                search: `?id=${target.id}`,
                                            }
                                        );
                                    }
                                }}
                            >
                                {target.name}
                            </Typography>
                            <Box className={classes.accordianImgSec}>
                                <img src={target.logo} alt="target" />
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accoDetailSec}>
                            <TargetAlertList
                                targetId={target.id}
                                targetName={target.name}
                                risk_categories={target.risk_categories}
                                filteredCategories={props.filteredCategories}
                                relatedAlerts={target.related_alerts}
                                targetType={props.targetId}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </Box>
    )
    );
};

export default RiskiestByTargets;

import { AUTH_URL, DECRYPTION_MASTER_KEY } from '../configurations';
import { LATEST_VERSION } from '../components/common/constants';

const isEncryptionEnabled = true;
const ls = window.localStorage;
const ss = window.sessionStorage;

const authCentralState = () => !!(sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken'));

/**
 * Function to decode data
 * @param {Object} data
 */
const dataDecode = (data) => {
    if (isEncryptionEnabled) return atob(data);
    return data;
};

/**
 * Function to decode data
 * @param [Array] data
 */
const decryptData = (data) => {
    if (isEncryptionEnabled) return JSON.parse(decodeURIComponent(escape(atob(data))));
    return data;
};

/**
 * Function to get from local storage
 * @param {String} key
 */
const localStorageGetItem = (key) => {
    try {
        let value = localStorage.getItem(key);
        if (value) value = JSON.parse(decodeURIComponent(escape(atob(value))));
        return value;
    } catch (err) {
        console.log(err.message);
    }
};

/**
 * Function to convert date to MM-DD-YYYY format
 * @param {Object} date
 */
// const getFormattedDate = (date) => `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
const getFormattedDate = (d, format) => {
    let today = new Date(d);
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    if (format === 'dd/MMM/yy') {
        today = new Date(d).toLocaleString('en-GB', {
            day: 'numeric', month: 'short', year: '2-digit',
        }).replace(/ /g, '-')
    }
    else if (format === 'mm/dd/yyyy') {
        today = `${mm}-${dd}-${yyyy}`;
    } else {
        today = `${yyyy}-${mm}-${dd}`;
    }
    return today;
};

/**
 * @description get login user token from session or local storage
 * @return {string} [login user detail]
 */
const getToken = () => (localStorageGetItem('accessToken') || sessionStorageGetItem('accessToken'));

/**
 * @description get login user detail from session or local storage
 * @return {object} [login user detail]
 */
const getLoginDetail = () => {
    const loginDetailData = localStorageGetItem('loginDetail') || sessionStorageGetItem('loginDetail');
    return loginDetailData || {};
};

/**
 * @description get count and pagesize and calculate total page for list
 * @param {number} count
 * @param {number} pageSize
 * @return {number} [total page for list]
 */
const getTotalPage = (count, pageSize) => {
    const totalPage = Math.ceil(count / pageSize);
    return totalPage;
};

const getRequestedHeader = () => {
    const requestHeader = {
        Authorization: `Token ${getToken()}`,
    };
    return requestHeader;
};

const checkEmptyObject = (obj) => {
    if (Object.keys(obj).length === 0) {
        return true;
    }
    return false;
};

const getFormatedDate = (d, format) => {
    let today = new Date(d);
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    if (format === 'mm/dd/yyyy') {
        today = `${mm}-${dd}-${yyyy}`;
    }
    else if(format === 'dd/MMM/yy'){
        today = new Date(d).toLocaleString('en-GB', {
            day: 'numeric', month: 'short', year: '2-digit'
        }).replace(/ /g, '-')
    } else {
        today = `${yyyy}-${mm}-${dd}`;
    }
    return today;
};

/**
 * Return random string
 */
function getRandomString(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * Function to extract params from url
 * @param {String} name
 */
const getParameterByName = (...name) => {
    const paramObj = {};
    for (const key of name) {
        const match = RegExp(`[?&]${key}=([^&]*)`).exec(window.location.search);
        paramObj[key] = match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    return paramObj;
};

/**
 * Function to encode data
 * @param [Array] data
 */
const encryptData = (data) => {
    if (isEncryptionEnabled) return btoa(unescape(encodeURIComponent(JSON.stringify(data))));
    return data;
};

/**
 * Function to encode data
 * @param {Object} data
 */
const dataEncode = (data) => {
    if (isEncryptionEnabled) return btoa(data);
    return data;
};

/**
 * Function to store to local storage
 * @param {String} key
 * @param {Object} value
 */
const localStorageSetItem = (key, value) => {
    try {
        const keyValue = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
        localStorage.setItem(key, keyValue);
    } catch (err) {
        console.log(err.message);
    }
};

const compareAppVersion = (version) => {
    const latest = LATEST_VERSION && LATEST_VERSION.split('.').slice(0, 3).join('.');
    const prev = version && version.split('.').slice(0, 3).join('.');
    if (latest === prev) return true;
    return false;
};

/**
 * Function to store to session storage
 * @param {String} key
 * @param {Object} value
 */
const sessionStorageSetItem = (key, value) => {
    value = dataEncode(JSON.stringify(value));
    ss.setItem(key, value);
    return true;
};

/**
 * Function to get from session storage
 * @param {String} key
 */
const sessionStorageGetItem = (key) => {
    const value = ss.getItem(key);
    try {
        return JSON.parse(dataDecode(value));
    } catch (e) {
        return null;
    }
};

/**
 * Function to create params for API
 * @param {String} paramName
 * @param {Array} paramArray
 */
const createFinalApiWithQueryParams = (paramName, paramArray) => {
    const targetParams = [];
    paramArray.forEach((item) => {
        targetParams.push(`${paramName}=${item}`);
    });
    return targetParams.join('&');
};

const getDefaultTargetType = () => {
    const loginDetails = getLoginDetail();
    if (localStorageGetItem('selectedTarget')) {
        return (localStorageGetItem('selectedTarget').id - 1);
    } if (loginDetails && loginDetails.profile && loginDetails.profile.default_target_type) {
        return parseInt(loginDetails.profile.default_target_type, 10) - 1;
    }
    return 0;
};

/**
 * Function to get display view of app
 */
const getDisplayView = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
};
const groupBy = (objectArray, property) => objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
        acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
}, {});

const currentYear = Number(new Date().getFullYear());
const quarterArray = ['Q1', 'Q2', 'Q3', 'Q4'];

const getYearOption = (filterQuarter) => {
    const yearArray = [];
    for (let startYear = 2012; startYear <= currentYear; startYear += 1) {
        if (startYear !== currentYear || quarterArray.indexOf(filterQuarter) <= getQuarter()) {
            yearArray.push({
                label: startYear,
                value: startYear,
            });
        }
    }
    return yearArray;
};

const getQuarterOption = (filterYear) => {
    const quarterOptions = [];
    const quarterArr = currentYear == filterYear
        ? quarterArray.filter((v, i) => getQuarter() >= i)
        : quarterArray;
    quarterArr.forEach((quar) => {
        quarterOptions.push({
            label: quar,
            value: quar,
        });
    });
    return quarterOptions;
};

/**
   * Function to return quarter as 0,1,2,3
   * @param {Object} date
   */
function getQuarter(d) {
    d = d || new Date();
    const m = Math.floor(d.getMonth() / 3);
    return m > 4 ? m - 4 : m;
}

/**
 * Function to handle login through SSO
 */
function handleSSOLogin() {
    // Attempt login by navigating to authUrl
    window.location.assign(AUTH_URL);
}

/**
 * Function to check previous quarter
 */
const check_previous_quarter = (quarter) => {
    if (quarter == 'Q1') {
        return 'Q4';
    }
    return (`Q${Number(quarter.slice(-1)) - 1}`);
};

/**
 * Function to decode the token
 * @param {String} token
 */
function decryptToken(token) {
    // Decode the base64 data so we can separate iv and crypt text.
    const rawData = atob(token);
    // Split by 16 because my IV size
    let iv = rawData.substring(0, 16);
    let crypttext = rawData.substring(16);

    // Parsers
    crypttext = window.CryptoJS.enc.Latin1.parse(crypttext);
    iv = window.CryptoJS.enc.Latin1.parse(iv);
    const key = window.CryptoJS.enc.Utf8.parse(DECRYPTION_MASTER_KEY);

    // Decrypt
    const plaintextArray = window.CryptoJS.AES.decrypt(
        { ciphertext: crypttext },
        key,
        { iv, mode: window.CryptoJS.mode.CBC, padding: window.CryptoJS.pad.Pkcs7 }
    );

    // Can be Utf8 too
    const outputPlainText = window.CryptoJS.enc.Latin1.stringify(plaintextArray);
    return outputPlainText;
}

/**
 * Function to extract and decode login token
 * @param {String} URL
 */
function extractAndDecodeLoginToken() {
    const match = RegExp('[?&]token=([^&]*)').exec(window.location.search);
    if (match) {
        const token = match[1];
        const decodedURL = decodeURI(token);
        const targetToken = decodedURL.substr(decodedURL.indexOf("'") + 1, decodedURL.lastIndexOf("'") - 2);
        return decryptToken(targetToken);
    }

    return null;
}

/**
 * Function to remove latin string
 * @param {String}
 */
function removeLatin(text) {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'.split('');
    const to = 'aaaaaeeeeeiiiiooooouuuunc------'.split('');
    if (text.length != 0) {
        let newtext = '';
        text.split('').map((item) => {
            if (from.includes(item)) {
                newtext += to[from.indexOf(item)];
            } else {
                newtext += item;
            }
        });
        return newtext;
    }

    return text;
}

/**
 * PERIOD CONFIGURATION
*/
const PERIOD_CONF = {
    hour: 24,
    minutes: 60,
    seconds: 60,
    milliseconds: 1000,
};

/**
 * SESSION TIMEOUT CONFIGURATION
*/
const TIMEOUT = process.env.REACT_APP_DEFAULT_SESSION_TIMEOUT || 30;

/**
 * Function to get default session timeout
 */
const getDefaultSessionTimeout = () => {
    const loginDetails = getLoginDetail();
    if (loginDetails && loginDetails.profile && loginDetails.profile.default_session_timeout) {
        return parseInt(loginDetails.profile.default_session_timeout);
    }
    return TIMEOUT;
};

/**
 * Function to show walkthrough
 */
const showWalkthrough = () => ((sessionStorage.getItem('showWalkthrough') || localStorage.getItem('showWalkthrough'))
    ? (sessionStorageGetItem('showWalkthrough') === 'yes' && localStorageGetItem('showWalkthrough') === 'yes')
    : true);

export {
    authCentralState, getLoginDetail, getDefaultTargetType,
    getTotalPage, getRequestedHeader, getToken, getRandomString,
    checkEmptyObject, getFormatedDate, getParameterByName,
    dataEncode, dataDecode, localStorageGetItem, localStorageSetItem, sessionStorageSetItem, sessionStorageGetItem,
    createFinalApiWithQueryParams,
    getDisplayView, groupBy, getQuarter, getYearOption, getQuarterOption, handleSSOLogin,
    extractAndDecodeLoginToken, check_previous_quarter, removeLatin, getFormattedDate, PERIOD_CONF, TIMEOUT,
    showWalkthrough, getDefaultSessionTimeout, decryptData, encryptData, compareAppVersion,
};

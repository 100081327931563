import 'leaflet/dist/leaflet.css';
import { Marker, Popup } from 'react-leaflet';
import { RISK_CATEGORY_COLORS, RISK_CATEGORY_RANGE, selectMarkerIcon } from '../common/constants';
import { Typography } from '@material-ui/core';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Loader from '../common/LoaderView';
import NoDataElement from '../common/NoDataElement';
import React, { Fragment, useEffect, useState } from 'react';
import WorldmapView from '../common/charts/WorldmapView';
import sendRequest from '../../httpRequest';

const useStyles = makeStyles(() => ({
    popupMap: {
        '& a': {
            backgroundColor: '#428BCA !important',
            color: '#fff !important',
            borderRadius: '50% !important',
            right: '-10px !important',
            top: '-9px !important',
        },
        '& .leaflet-popup-content': {
            margin: '5px 7px',
            width: '200px !important',
        },
        '& p': {
            margin: 'unset',
        },
        '& .leaflet-popup-tip-container': {
            display: 'none',
        },
    },
    popupTable: {
        marginTop: '5px',
    },
    popupText: {
        color: '#428BCA',
        cursor: 'pointer',
        padding: '2px 5px',
    },
}));

const MapViewNP = (props) => {
    const classes = useStyles();
    const [loading, setLoadingStatus] = useState(false);
    const [countries, setCountries] = useState([]);
    const [cityData, setCityData] = useState([]);
    const { modifyNPData, targetIdList } = props;
    const history = useHistory();

    const center = [50, 20];
    const ZOOM_LEVEL = 1;
    const countryStyle = {
        fillColor: '#fff',
        fillOpacity: 1,
        color: '#000',
        weight: 0.4,
    };

    /**
   * Function to handle API call failure
   * @param {Object} error
   */
    const requestFailure = (error) => {
        setLoadingStatus(false);
        console.log(error);
    };

    /**
   * Function to handle latLang API call success
   * @param {Array} success
   */
    const latLangSuccess = (res) => {
        setLoadingStatus(false);
        const modData = [];
        res.data && res.data.length && res.data.forEach((latLongDet) => {
            const selected = modifyNPData.find((np) => np.id === latLongDet.id);
            if (selected) {
                const temp = {
                    name: selected.name,
                    id: selected.id,
                    rating: selected.rating,
                    targets: selected.targets,
                    lattitude: latLongDet.lattitude,
                    longitude: latLongDet.longitude,
                };
                modData.push(temp);
            }
        });
        setCityData(modData);
    };

    useEffect(() => {
        if (targetIdList && targetIdList.length) {
            const uniqueIds = targetIdList.filter((c, index) => targetIdList.indexOf(c) === index);
            const data = { target_ids: uniqueIds };
            if (uniqueIds.length) {
                setLoadingStatus(true);
                sendRequest(
                    'post',
                    'api/v2/get-lattitude-longitude/',
                    data,
                    getRequestedHeader(),
                    latLangSuccess,
                    requestFailure,
                );
            }
        }
    }, [targetIdList]);

    /**
   * Function to be executed on marker click
   * @param {id}
   */
    const onMarkerClick = (id) => {
        if (id) {
            history.push(
                {
                    pathname: '/targetProfile',
                    search: `?id=${id}`,
                }
            );
        }
    };

    /**
   * Function to select color based on rating
   * @param {Object}
   */
    const selectColor = (country) => {
        const rating = country.targets && country.targets.length && country.targets[0].score;
        let riskColor;
        if (rating > 0 && rating <= RISK_CATEGORY_RANGE.LOW_RISK_LIMIT) {
            riskColor = RISK_CATEGORY_COLORS.LOW_RISK;
        } else if (
            rating > RISK_CATEGORY_RANGE.LOW_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT
        ) {
            riskColor = RISK_CATEGORY_COLORS.MODERATE_RISK;
        } else if (
            rating > RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT
        ) {
            riskColor = RISK_CATEGORY_COLORS.HIGH_RISK;
        } else if (
            rating > RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT
        ) {
            riskColor = RISK_CATEGORY_COLORS.CRITICAL_RISK;
        } else {
            riskColor = '#fff';
        }
        return riskColor;
    };

    const mapCountries = window.geoData && window.geoData.features;

    useEffect(() => {
        setCountries(null);
        if (mapCountries) {
            for (let i = 0; i < mapCountries.length; i++) {
                const mapCountry = mapCountries[i];
                const matchedCountry = modifyNPData.find((country) => country.name.toLowerCase() === mapCountry.properties.ADMIN.toLowerCase());
                mapCountry.properties.risk_categories = 0;
                mapCountry.properties.color = '#fff';
                mapCountry.properties.id = 0;
                if (matchedCountry != null) {
                    mapCountry.properties.risk_categories = matchedCountry.targets;
                    mapCountry.properties.id = Number(matchedCountry.id);
                    mapCountry.properties.color = selectColor(matchedCountry);
                }
            }
            setCountries(mapCountries);
        }
    }, [modifyNPData]);

    /**
   * Function to be executed on country click
   * @param {id}
   */
    const onContentClick = (targetId) => {
        if (targetId > 0) {
            history.push(
                {
                    pathname: '/targetProfile',
                    search: `?id=${targetId}`,
                }
            );
        }
    };

    /**
   * Function to be executed on country hover
   * @param {Array}
   */
    const onContentHover = (layer, countryRiskCat, countryName) => {
        if (countryRiskCat.length) {
            let table = `<div style="font-size:12px; font-weight:600">${countryName}</div>`;
            table += '<table style="font-size:12px;">';
            table += '<tr style="margin-bottom:2px;"><th align="left">Risk Domain</th><th>Rating</th></tr>';
            countryRiskCat.forEach((data) => {
                table += '<tr>';
                table += `<td >${data.name}</td>`;
                table += `<th>${data.score}</th>`;
                table += '</tr>';
            });
            table += '</table>';
            layer.bindTooltip(table).openTooltip();
        } else {
            layer.bindTooltip(countryName).openTooltip();
        }
    };

    /**
   * Function to be executed on creating the map
   * @param {Object} error
   */
    const onEachCountry = (country, layer) => {
        layer.options.fillColor = country.properties.color;
        const countryRiskCat = country.properties.risk_categories;
        const countryName = country.properties.ADMIN;

        layer.on({
            click: () => onContentClick(country.properties.id),
            mouseover: () => onContentHover(layer, countryRiskCat, countryName),
        });
    };
    const getCityMarker = () => {
        if (cityData.length) {
            return cityData.map((item) => (
                <Marker
                    key={item.id}
                    position={[item.lattitude, item.longitude]}
                    icon={selectMarkerIcon(item.rating)}
                >
                    <Popup className={classes.popupMap}>
                        <table className={classes.popupTable}>
                            <colgroup>
                                <col span="1" style={{ width: '83%' }} />
                                <col span="2" style={{ width: '15%' }} />
                            </colgroup>
                            <tr>
                                <th><Typography variant="h6">{item.name}</Typography></th>
                                <th><Typography variant="h6">{item.targets[0].score}</Typography></th>
                            </tr>
                            <tr>
                                <th>Targets</th>
                            </tr>
                        </table>
                        {item.targets && item.targets.length && item.targets.map((target) => (
                            <div
                                className={classes.popupText}
                                onClick={() => onMarkerClick(target.id)}
                            >
                                {target.name}
                            </div>
                        ))}
                    </Popup>
                </Marker>
            ));
        }
        return null;
    };
    return (
        <Fragment>
            { loading ? <Loader loading={loading} /> : null}
            {(modifyNPData && modifyNPData.length) ? (
                <WorldmapView
                    ZOOM_LEVEL={ZOOM_LEVEL}
                    center={center}
                    // data={window.geoData && window.geoData.features}
                    onEachCountry={onEachCountry}
                    data={countries}
                    countryStyle={countryStyle}
                    title="Location Risk Concentration"
                >
                    {getCityMarker()}
                </WorldmapView>
            ) : <NoDataElement content="Map Data Not Found" /> }
        </Fragment>
    );
};

export default MapViewNP;

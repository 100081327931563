// API request method configuration
const METHOD_CONF = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'DELETE',
};

const SUBSCRIPTION_DETAILS_CONF = [
    {
        title: 'Cities',
        targetType: 1,
        headers: ['S.No', 'Target ID', 'Target', 'Subscription Tier', 'Start Date', 'End Date', 'Status'],
        body: [{ key: 'target_type', type: 'target_type' }, { key: 'id', type: 'number' }, { key: 'name', type: 'text' },
            { key: 'subscription_tier', type: 'text' }, { key: 'start_date', type: 'date' }, { key: 'end_date', type: 'date' },
            { key: 'status', type: 'status' }],
        api: '/api/account/targets-of-organization/',
    },
    {
        title: 'Countries',
        targetType: 2,
        headers: ['S.No', 'Target ID', 'Target', 'Subscription Tier', 'Start Date', 'End Date', 'Status'],
        body: [{ key: 'target_type', type: 'target_type' }, { key: 'id', type: 'number' }, { key: 'name', type: 'text' },
            { key: 'subscription_tier', type: 'text' }, { key: 'start_date', type: 'date' }, { key: 'end_date', type: 'date' },
            { key: 'status', type: 'status' }],
        api: '/api/account/targets-of-organization/',
    },
    {
        title: 'Third Parties',
        targetType: 3,
        headers: ['S.No', 'Target ID', 'Target', 'Subscription Tier', 'Start Date', 'End Date', 'Status'],
        body: [{ key: 'target_type', type: 'target_type' }, { key: 'id', type: 'number' }, { key: 'name', type: 'text' },
            { key: 'subscription_tier', type: 'text' }, { key: 'start_date', type: 'date' }, { key: 'end_date', type: 'date' },
            { key: 'status', type: 'status' }],
        api: '/api/account/targets-of-organization/',
    },
];

// API url configuration
const URL_CONF = {
    GET_ALERTS: '/api/risk/alerts/',
    GET_SISENSE_URL: 'api/account/sisense_url/',
    EXPORT_ORG_MEMBERS: '/api/v2/account/export-users-csv/',
};

// Feature enable/disable config
const isExumaEnabled = true;
const isBYOMEnabled = process.env.REACT_APP_BYOM_ENABLED === 'true';
const isContextualHelpEnabled = false;
const isSessionTimeoutEnabled = process.env.REACT_APP_SESSION_TIMEOUT_ENABLED === 'true';
const isUniversalSearchEnabled = process.env.REACT_APP_UNIVERSAL_SEARCH_ENABLED === 'true';

const STATUS_CONF = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
];

export {
    METHOD_CONF,
    SUBSCRIPTION_DETAILS_CONF,
    URL_CONF,
    isExumaEnabled,
    isBYOMEnabled,
    isContextualHelpEnabled,
    isSessionTimeoutEnabled,
    STATUS_CONF,
    isUniversalSearchEnabled,
};

import {
    Box, Button, Checkbox, FormControlLabel, Tooltip, Typography,
} from '@material-ui/core';
import { FormatColorResetOutlined } from '@material-ui/icons';
import { LATEST_VERSION } from '../common/constants';
import { Link, useHistory } from 'react-router-dom';
import {
    compareAppVersion, getDefaultTargetType, getLoginDetail, getQuarterOption, getRequestedHeader,
    getYearOption, localStorageGetItem, localStorageSetItem, sessionStorageGetItem, sessionStorageSetItem,
} from '../../helper/commonHelper';
import { isExumaEnabled, isUniversalSearchEnabled } from '../../common/Constants';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AskAnalystContainerView from '../../containers/AskAnalystContainerView';
import Backdrop from '@material-ui/core/Backdrop';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CloseIcon from '@material-ui/icons/Close';
import CustomModal from '../common/CustomModal';
import CustomSelect from '../common/CustomSelect';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import Fade from '@material-ui/core/Fade';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import LabelContainer from '../../containers/LabelComponentContainer';
import Loader from '../common/LoaderView';
import Modal from '@material-ui/core/Modal';
import PortfolioSnapshot from './PortflioSnapshotView';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import RecentChangesInRisk from './RecentChangesInRiskView';
import Select from 'react-select';
import ShareComponent from '../common/ShareComponent';
import ShareIcon from '@material-ui/icons/Share';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import download from 'downloadjs';
import exumaLogo from '../../Images/Pop-up-Img.jpg';
import sendRequest from '../../httpRequest';

const breakpoints = createBreakpoints({});

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    mainSectionIn: {
        padding: '0px 60px',
        [breakpoints.down('xs')]: {
            padding: '0px 5px',
        },
    },

    topMainCont: {
        [breakpoints.down('xs')]: {
            width: '100%',
            marginTop: '62px',
        },
    },
    mainSectionInTab: {
        padding: '0px 0px',
        [breakpoints.down('xs')]: {
            padding: '0px',
            margin: '-24px',
            width: 'calc(100% + 48px)',
        },
    },

    mainSectionInTabMob: {
        padding: '0px 0px',
        [breakpoints.down('xs')]: {
            padding: '0px 8px',
            margin: '0px -24px',

            width: 'calc(100% + 48px)',
        },
    },
    inMainSectionInTab: {
        [breakpoints.down('xs')]: {
            width: '100%',
        },
    },

    mainSelectBoxSecRight: {
        display: 'flex',
        [theme.breakpoints.between('sm', 'md')]: {
            marginTop: '10px',
        },
        [breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
        [breakpoints.between('1000', '1350')]: {
            marginTop: '0px',
            marginLeft: '28px',
        },
    },

    socialSign: {
        border: '1px solid #C7D0D8',
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: '45px',
        height: '45px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#428BCA',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            height: '40px',
        },
    },

    socialSignR: {
        border: '1px solid #C7D0D8',
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: '45px',
        height: '45px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '15px',
        cursor: 'pointer',
        color: '#428BCA',
        [breakpoints.down('xs')]: {
            height: '40px',
        },
    },
    socialSignRDisbl: {
        border: '1px solid #C7D0D8',
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: '48px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '15px',
        color: '#DCDCDC',
    },
    selectBoxXr: {
        width: '140px',
        minHeight: '48px',
        backgroundColor: '#D5DEE112',
        padding: '4px 12px 4px 32px',
        borderRadius: '2px',
        color: '#07212B',
        fontSize: '18px',
        marginLeft: '28px',
        fontFamily: 'Gilroy-Semibold',
        opacity: '.5',
        position: 'relative',
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: '0px',
        },
        [breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: '0px',
            minHeight: '40px',
        },
    },
    selectBoxXrSec: {
        width: '140px',
        minHeight: '48px',
        backgroundColor: '#D5DEE112',
        padding: '4px 12px 4px 32px',
        borderRadius: '2px',
        color: '#07212B',
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
        opacity: '.5',
        position: 'relative',
        borderLeft: 'none',
        [breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: '0px',
            minHeight: '40px',
        },
    },

    tabStyle: {
        border: '1px solid #428BCA55',
        borderRadius: '2px',
        backgroundColor: '#0D1B2807',
        width: '56%',
        [breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '10px',
        },
    },

    tabStyleIn: {
        minWidth: '33.55%',
        zIndex: '99',
        fontSize: '16px',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.down('xs')]: {
            minWidth: '33%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.between('1000', '1350')]: {
            fontSize: '14px',
            padding: '0px',
        },
    },

    menuItemmDown: {
        fontSize: '12px',
        padding: '5px 10px',
    },

    mainSelectBoxSec: {
        position: 'relative',
        [breakpoints.down('xs')]: {
            width: '50%',
        },
    },

    filterImgR: {
        position: 'absolute',
        width: '12%',
        left: '4%',
        top: '50%',
        color: '#828F94',
        fontSize: '18px',
        transform: 'translateY(-50%)',
        [breakpoints.down('xs')]: {
            width: '10%',
            left: '2%',
        },
    },

    mainSocialSign: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        // width: '57%',
        // [breakpoints.down('xs')]: {
        //     width: '100%',
        //     justifyContent: 'flex-start',
        //     marginTop: '9px',
        // },
    },

    rightSignImgSocial: {
        width: '52%',
    },

    filterImgRSec: {
        position: 'absolute',
        width: '12%',
        left: '4%',
        top: '50%',
        color: '#828F94',
        fontSize: '17px',
        transform: 'translateY(-50%)',
        [breakpoints.down('xs')]: {
            width: '10%',
            left: '2%',
        },
    },

    mainFontSetPortImg: {
        display: 'inline-block',
        width: '5%',
        verticalAlign: 'middle',
        [breakpoints.between('1000', '1350')]: {
            width: '7%',
        },
        [breakpoints.down('xs')]: {
            width: '10%',
        },
    },

    mainFontSetPortImgRecnt: {
        display: 'inline-block',
        width: '10%',
        verticalAlign: 'middle',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '7%',
        },
        [breakpoints.between('1000', '1350')]: {
            width: '7%',
        },
    },

    chartImgSec: {
        textAlign: 'center',

        '& span': {
            [breakpoints.down('xs')]: {
                width: '100%',
            },
        },

    },

    hrSec: {
        margin: '0px -20px',
    },

    marginTop: {
        marginTop: '60px',
        marginLeft: '25px',
    },
    marginTopDown: {
        marginTop: '48px',
        marginLeft: '-42px',
    },

    rightSideScroll: {
        height: '95vh',
        overflow: 'hidden',
        overflowY: 'scroll',
    },

    backColor: {
        backgroundColor: '#fff',
        boxShadow: '0px 0px 6px #00000029',
        color: '#fff',
    },
    algnData: {
        display: 'flex',
        height: '48px',
        alignItems: 'center',
        [breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },

    mainAlgnData: {
        [breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    fontSetPort: {
        color: '#07212B',
        [theme.breakpoints.between('1000', '1350')]: {
            fontSize: '18px',
        },
    },
    mainHighLowBottom: {
        marginTop: '25px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '85%',
        marginLeft: 'auto',
    },

    accodianHeading: {
        justifyContent: 'space-between',
        margin: '0px !important',
        '& img': {
            width: '45%',
        },
    },

    yellowSec: {
        height: '250px',
        backgroundColor: '#FEFD13',
        border: '6px solid rgba(254,253,19,0.16)',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        [breakpoints.down('xs')]: {
            height: '150px',
        },

    },
    yellowSecImm: {
        height: '250px',
        backgroundColor: '#C01510',
        border: '6px solid #F3D0CF',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        [breakpoints.down('xs')]: {
            height: '150px',
        },
    },
    yellowSecLow: {
        height: '250px',
        backgroundColor: '#70AD47',
        border: '6px solid #E7F1E2',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        [breakpoints.down('xs')]: {
            height: '150px',
        },
    },
    yellowSecHigh: {
        height: '250px',
        backgroundColor: '#ED7D30',
        border: '6px solid #FAE4D5',
        borderRadius: '4px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        [breakpoints.down('xs')]: {
            height: '150px',
        },
    },

    tableMiddleSecImg: {
        verticalAlign: 'bottom',
    },

    mainChartHighLow: {
        display: 'flex',
    },

    headingRelatedMain: {
        padding: '15px',
    },
    fontSetPorttCh: {
        color: '#fff',
        lineHeight: '22px',
    },
    fontSetPorttCheight: {
        color: '#204565',
        lineHeight: '22px',
        textAlign: 'center',
    },

    expendIcon: {
        position: 'absolute',
        left: '0px',
        top: '-2px',
        padding: '0px',
        transform: 'rotate(-90deg)',
    },
    expendIconSect: {
        transform: 'rotate(0deg) !important',
    },

    mainSecAccordian: {
        backgroundColor: '#F5F8FC',
        minHeight: '50px !important',
        alignItems: 'center',
        paddingTop: '7px',
        paddingLeft: '45px',
        position: 'relative',
        border: '1px solid #7070704B',
        borderRadius: '4px',
    },

    fontSetEdit: {
        cursor: 'pointer',
    },

    fontSetPorttCheightOccu: {
        color: '#204565',
        lineHeight: '22px',
        textAlign: 'center',
        transform: 'rotate(-90deg)',
    },

    fontSetPortt: {
        color: '#204565',
    },
    filterButtonInner: {
        border: '0.800000011920929px solid #29456241',
        borderRadius: '2px',
        backgroundColor: '#2045650B',
        color: '#204565',
        width: '36px',
        height: '36px',
        padding: '7px',
        cursor: 'pointer',
    },

    accordianIcon: {
        fontSize: '35px',
        color: '#204565',
        marginTop: '5px',
    },
    mainHighLow: {
        marginTop: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },

    topMainSecAccordian: {
        marginBottom: '10px !important',
    },

    arrowRight: {
        border: 'solid #428BCA',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
        transform: 'rotate(-45deg)',
    },

    accoDetailSec: {
        padding: '0px',
        '& table': {
            width: '100%',
            '& td ': {
                fontSize: '15px',
                textAlign: 'center',
                verticalAlign: 'baseline',
                borderBottom: '1px solid #E7EBEE',
                borderRight: '1px solid #E7EBEE',
                padding: '4px 0px 4px 15px',
                position: 'relative',
            },
            '& th': {
                fontSize: '16px',
                textAlign: 'left',
                verticalAlign: 'baseline',
                padding: '10px 0px 10px 15px',
                color: '#204565',
            },
        },
    },

    rightTableSection: {
        '& table': {
            width: '100%',
            '& td ': {
                fontSize: '18px',
                padding: '8px 0px 8px 5px',
                verticalAlign: 'baseline',
                position: 'relative',
                [breakpoints.down('sm')]: {
                    fontSize: '14px',
                    padding: '8px 0px 8px 5px',
                },
            },
            '& th': {
                fontSize: '18px',
                textAlign: 'left',
                verticalAlign: 'baseline',
                padding: '5px 0px 5px 20px',
                color: '#07212B',
                backgroundColor: '#D9E7F4',
                fontFamily: 'Gilroy-Semibold',
                [breakpoints.down('sm')]: {
                    fontSize: '14px',
                    padding: '8px 0px 8px 5px',
                },
            },
        },
    },

    radioSecionFont: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('1000', '1350')]: {
            fontSize: '15px',
        },
    },
    radioSecionFontLeftSec: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        marginRight: '55px',
    },

    filterButtonInnerMenu: {
        marginTop: '0px',
        marginLeft: '-56px',
        '& li': {
            fontSize: '18px',
            color: '#204565',
        },
    },

    searchPoupInput: {
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
    },
    searchPoupInputIcon: {
        position: 'absolute',
        left: '5px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#7D99A3',
    },

    poupButton: {
        color: '#428BCA',
        width: '162px',
        height: '40px',
        border: '1px solid #428BCA',
        backgroundColor: '#fff',
        cursor: 'pointer',
    },

    poupTable: {
        width: '100%',
        border: '1px solid #D5DBE16C',
        borderRadius: '2px',
        backgroundColor: '#FDFDFD',
        marginBottom: '5px',

        '& td': {
            paddingLeft: '15px',
            fontSize: '18px',
            fontFamily: 'Gilroy-Medium',
            height: '45px',
        },
    },

    poupTableTd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        '& img': {},
    },

    accordianImgSec: {
        textAlign: 'right',
    },
    accordianImgSecEy: {
        textAlign: 'center',
    },
    arrowUpIcon: {},
    arrowUpIconn: {
        fontSize: '30px',
        verticalAlign: 'bottom',
    },
    tableMiddleSecThFirst: {
        backgroundColor: '#ECF3FB',
    },
    tableMiddleSecThSecond: {
        backgroundColor: '#F7F7F7',
    },
    mainBottonButton: {
        textAlign: 'right',
    },

    headingRelatedMiddle: {
        paddingTop: '15px',
    },

    poupHr: {
        margin: '10px -32px',
    },

    headingRelated: {
        backgroundColor: '#F7F7F7',
        color: '#204565',
        padding: '10px 20px',
    },

    arrowDown: {
        transform: 'rotate(45deg)',
        border: 'solid #FFFFFF',
        display: 'inline-block',
        padding: '4px',
        borderWidth: '0 2px 2px 0',
        marginLeft: '5px',
        marginTop: '2px',
        verticalAlign: 'text-top',
    },

    floatNumver: {
        fontSize: '14px',
        fontFamily: 'Gilroy-Medium',
    },

    ownerShipSec: {
        color: '#fff',
        backgroundColor: '#C62C27',
        borderRadius: '2px',
        padding: '4px 15px',
        width: '100%',
    },

    mainBackColr: {
        display: 'flex',
    },

    poupTableTdTwo: {
        color: '#000',
        opacity: '.6',
        fontSize: '16px !important',
    },

    mainBackColrFirst: {
        width: '7px',
        height: '46px',
        backgroundColor: '#ECF3FB',
        marginRight: '-2px',
    },
    mainBackColrSecond: {
        width: '7px',
        height: '81px',
        backgroundColor: '#C01510',
        marginRight: '-2px',
    },
    bottomPoupButton: {
        color: '#fff',
        height: '48px',
        width: '125px',
        fontSize: '18px',
        borderRadius: '2px',
        border: 'none',
        backgroundColor: '#428BCA',
        cursor: 'pointer',
    },

    mainTableSec: {
        height: '55vh',
        [breakpoints.down('xs')]: {
            height: 'auto',
        },
    },
    mainBackColrThird: {
        width: '7px',
        height: '81px',
        backgroundColor: '#ED7D30',
        marginRight: '-2px',
    },
    mainBackColrFour: {
        width: '7px',
        height: '44px',
        backgroundColor: '#FEFD13',
        marginRight: '-2px',
    },

    fontSetPortPoup: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    mainBackColrFive: {
        width: '7px',
        height: '40px',
        backgroundColor: '#70AD47',
        marginRight: '-2px',
    },

    mediumHighSec: {
        fontSize: '14px',
        backgroundColor: '#FEFD13',
        padding: '5px 7px',
    },
    mediumHighSecHigh: {
        fontSize: '14px',
        backgroundColor: '#ED7D31',
        padding: '4px 7px',
        color: '#fff',
    },

    mainMediumHighSec: {
        paddingTop: '5px',
    },

    poupInput: {
        backgroundColor: '#D5DEE112',
        color: '#00364B',
        border: '0.800000011920929px solid #D5DEE1',
        width: '450px',
        height: '40px',
        paddingLeft: '38px',
    },

    poupInputLab: {
        backgroundColor: '#D5DEE112',
        color: '#00364B',
        border: '0.800000011920929px solid #D5DEE1',
        width: '100%',
        height: '48px',
        paddingLeft: '38px',
    },

    fontSetSeeAll: {
        textAlign: 'right',
    },

    mediumHighSecDate: {
        fontSize: '14px',
        marginLeft: '100px',
        [breakpoints.down('xs')]: {
            marginLeft: '10px',
        },
    },

    mainOwnerShipSec: {
        border: '1px solid #7070704B',
        padding: '5px',
        marginTop: '35px',
    },

    blackColorSet: {
        color: '#428BCA',
    },

    inMainOwnerShipSec: {
        border: '5px solid #E7F1E2',
        padding: '7px',
    },

    FilterDoneSec: {
        marginTop: '10px',
        display: 'block',
        textAlign: 'right',
        color: '#428BCA !important',
    },
    iconTargetField: {
        display: 'inline-block',
        backgroundColor: '#428BCA',
        height: '46px',
        padding: '14px 5px',
        borderRadius: '4px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        color: '#fff',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            height: '40px',
            padding: '7px 5px',
        },
        '&:hover': {
            backgroundColor: '#428BCA',
        },
    },
    disabledBtn: {
        backgroundColor: '#DCDCDC',
        '&:hover': {
            backgroundColor: '#DCDCDC',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperComingSoon: {
        backgroundColor: theme.palette.background.paper,
        width: '30%',
        boxShadow: theme.shadows[5],
        [breakpoints.down('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            margin: '0px',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    exuStyle: {
        width: '100%',
        display: 'block',
    },
    overlay: {
        position: 'relative',
    },
    closeIconlay: {
        top: '0',
        right: '0',
        position: 'absolute',
        margin: '8px',
        color: '#ffffff',
    },
    divNext: {
        padding: theme.spacing(2, 4, 3),
        maxHeight: '45vh',
        overflowY: 'auto',
    },
    smFont: {
        color: '#000',
        fontSize: '16px',
        marginTop: '10px',
        textAlign: 'justify',
    },
    smFontText: {
        color: '#000',
        fontSize: '16px',
        textAlign: 'justify',
    },
    boldText: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 600,
    },
    readMoreLink: {
        color: '#fff',
        height: '42px',
        width: '152px',
        fontSize: '18px',
        borderRadius: '50px',
        border: 'none',
        backgroundColor: '#428BCA',
        cursor: 'pointer',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 14px 0px 14px',
        textDecoration: 'none',
        [breakpoints.down('xs')]: {
            fontSize: '15px',
            height: '40px',
            width: '100px',
        },
    },
    bottomUnderline: {
        cursor: 'pointer',
        fontSize: '14px',
        marginTop: '10px',
        color: '#707070',
        textDecoration: 'underline',
    },
    moreIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    searchTPoupInput: {
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        [breakpoints.down('xs')]: {
            marginLeft: '0px',
            marginTop: '10px',
            marginBottom: '10px',
            width: '50%',
        },
        '& span': {
            '&:nth-child(1)': {
                visibility: 'hidden',
            },
        },
    },
    mandatoryError: {
        fontSize: '14px',
        color: '#f44336',
    },
}));

// Style for Select
const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        padding: '10px',
    }),
    control: (base, state) => ({
        ...base,
        width: '180px',
        height: '46px',
        backgroundColor: '#D5DEE112',
        padding: '4px 12px',
        borderRadius: '2px',
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('sm', 'md')]: {
            marginTop: '7px',
        },
        [breakpoints.between('1000', '1350')]: {
            marginTop: '0px',
        },
    }),
    menu: (base, state) => ({
        ...base,
        borderRadius: 0,
        width: '260px',
        marginLeft: state.isSelected ? '15px' : '',
    }),
    menuList: (base, { isSelected }) => ({
        ...base,
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
        marginLeft: isSelected ? '15px' : '',
        color: isSelected ? '#fff' : '#000',
    }),
    placeholder: (base) => ({
        ...base,
        marginLeft: '15px',
        color: '#828F94',
    }),
    singleValue: (provided) => ({
        ...provided,
        marginLeft: '15px',
        color: '#828F94',
    }),
};

const customSelectSearchStyles = {
    option: (provided, state) => ({
        ...provided,
        color: '#000',
        zIndex: '9999',
    }),
    control: (base, state) => ({
        ...base,
        width: 'auto',
        height: '50px',
        backgroundColor: '#D5DEE112',
        padding: '4px 5px',
        borderRadius: '2px',
        fontSize: '16px',
        overflowY: 'scroll',
        // zIndex:'9999',
        [breakpoints.down('xs')]: {
            width: '100%',
            height: '40px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: '15px',
        },
        [breakpoints.between('1000', '1350')]: {
            fontSize: '14px',
        },
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        color: '#000',
        marginTop: 0,
        // zIndex:'9999',
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        color: '#000',
        fontSize: '18px',
        // zIndex:'9999',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#000',
        // zIndex:'9999',
    }),
    container: (base) => ({
        width: '100%',
        [breakpoints.down('xs')]: {
            width: '100%',
        },
    }),
};

const Dashboard = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [targetType, setTargetType] = useState([]);
    const [currentSelected, setCurrentSelection] = useState({
        // targetType: sessionStorageGetItem('selectedTarget') ? (sessionStorageGetItem('selectedTarget').id - 1) : getDefaultTargetType(),
        targetType: getDefaultTargetType(),
        reportType: '',
        label: {},
    });
    const [easyShareSuccess, setEasyShareSuccess] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [organizationId, setOrganizationId] = useState('');
    const [isRaasChampion, setIsRaasChampion] = useState(false);
    const [categories, setCategoryList] = useState([]);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [selectValue, setSelectValue] = useState({});
    const [openShare, setOpenShare] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const {
        reportDetails, actionProps, raasAccount, LoginDetails, raasAccountSelected, stateProps,
    } = props;

    const [searchedTargetList, setSearchedTargetList] = useState({
        searchedTarget: [],
        isError: false,
    });

    const targetOption = stateProps.targetData
        && stateProps.targetData.map((target) => ({
            value: target.id,
            label: target.name,
        }));

    const currentlySelectedTarget = targetType[currentSelected.targetType];
    /**
   * Function to handle multi target search
   * @param {Object} event
   */
    const handleMultiFilter = (event) => {
        if (event.length <= 10) {
            setSearchedTargetList({
                searchedTarget: event,
                isError: false,
            });
        } else if (event.length > 10) {
            setSearchedTargetList({
                ...searchedTargetList,
                isError: true,
            });
        }
    };

    /**
   * Function to handle target list go button
   *
   */
    const handleSearchedTarget = () => {
        if (searchedTargetList.searchedTarget.length) {
            const targets = searchedTargetList.searchedTarget.map((target) => target.value);
            history.push({
                pathname: '/targetList',
                search: `?targetType=${currentlySelectedTarget ? currentlySelectedTarget.id : ''}&targets=${targets}`,
            });
        }
    };

    const getCategorySuccess = (res) => {
        setCategoryList(res.data);
        dispatch(actionProps.setCategories(res.data));
    };

    const easyShareSuccessModalElements = (
        <Box p={5}>
            <Typography className={classes.topStyle} variant="subtitle1">
                Download of QPR Initiated and will be delivered to the mail ID selected.
                This may take a while. Meanwhile, please keep looking for a mail from
                {' '}
                <span style={{ color: 'blue' }}>no-reply@supplywisdom.com</span>
                {' '}
                .
            </Typography>
        </Box>
    );

    const closeEasySharePopup = () => {
        setEasyShareSuccess(false);
    };

    const getTargetSuccess = (res) => {
        dispatch(props.actionProps.setTargetData(res.data));
    };

    const getAllTarget = () => {
        const requestParameter = { target_type: currentlySelectedTarget.id, report_type: reportDetails.id, client_org: raasAccountSelected.id };
        sendRequest(
            'get',
            'api/risk/get-reporttype-targets/',
            requestParameter,
            getRequestedHeader(),
            getTargetSuccess,
            requestFailure
        );
    };

    useEffect(() => {
        const loginDetails = getLoginDetail();
        setIsPageLoading(true);
        // API call to get target types
        sendRequest(
            'get',
            'api/v2/risk/target-type/',
            {},
            getRequestedHeader(),
            getTargetTypeSuccess,
            requestTargetTypeFailure
        );
        if (!loginDetails || !loginDetails.profile) {
            sendRequest(
                'get',
                '/api/account/getuser/',
                {},
                getRequestedHeader(),
                getUserSuccess,
                requestFailure
            );
        } else {
            setOrganizationId(loginDetails.profile.organization);
            setIsRaasChampion(loginDetails.profile.is_raas_champion);
        }
    }, []);

    useEffect(() => {
        const target = targetType[currentSelected.targetType];
        if (target && reportDetails.id) {
            sendRequest(
                'get',
                'api/v2/risk/category/',
                {
                    report_type: reportDetails.id,
                    target_type: target.id,
                },
                getRequestedHeader(),
                getCategorySuccess,
                requestFailure
            );
        }
    }, [currentSelected.targetType, targetType, reportDetails.id]);

    useEffect(() => {
        const currentTarget = targetType[currentSelected.targetType];
        if (currentTarget && currentTarget.id && reportDetails.id) {
            getAllTarget();
        }
    }, [targetType, currentSelected.targetType, reportDetails.id, raasAccountSelected]);

    /**
     * Callback function for getuser API success
     * @param {Object} res
     */
    const getUserSuccess = (res) => {
        if (res.data) {
            localStorageSetItem('loginDetail', res.data);
            sendRequest(
                'get',
                `/api/account/user/${res.data.profile.id}/profile`,
                {},
                getRequestedHeader(),
                getProfileSuccess,
                requestFailure
            );
        }
    };

    /**
     * Callback function for get profile API success
     * @param {Object} res
     */
    const getProfileSuccess = (res) => {
        setOrganizationId(res.data.organization);
        setIsRaasChampion(res.data.is_raas_champion);
        setCurrentSelection({
            ...currentSelected,
            targetType: parseInt(res.data.default_target_type, 10) - 1,
        });
    };

    /**
     * Callback function for target type API success
     * @param {Object} res
     */
    const getTargetTypeSuccess = (res) => {
        setIsPageLoading(false);
        dispatch(props.actionProps.setTargetTypes(res.data.results));
        setTargetType(res.data.results);
    };

    /**
     * Function to handle request failure
     * @param {Object} error
     */
    const requestFailure = (error) => {
        console.log(error);
    };

    /**
     * Function to handle Target Type request failure
     * @param {Object} error
     */
    const requestTargetTypeFailure = (error) => {
        setIsPageLoading(false);
        console.log(error);
    };

    /**
     * Function to handle target type change
     * @param {Object} props
     */
    const handleTargetChange = (e, newvalue) => {
        setCurrentSelection({
            ...currentSelected,
            targetType: newvalue,
        });
        setSearchedTargetList({
            searchedTarget: [],
            isError: false,
        });
        const currentlySelectedTarget = targetType[newvalue];
        localStorageSetItem('selectedTarget', currentlySelectedTarget);
    };

    /**
     * Function to handle report, label, quarter, year change
     * @param {Object} event
     */
    const handleFieldChange = (event) => {
        const field = event.target.name;
        const { value } = event.target;
        setCurrentSelection({
            ...currentSelected,
            [field]: value,
        });
    };

    const [anchorElk, setAnchorElk] = React.useState(null);

    const handleClickl = (event) => {
        setAnchorElk(event.currentTarget);
    };

    const handleClosel = () => {
        setAnchorElk(null);
    };

    const handleLabelChange = (newValue) => {
        setCurrentSelection({
            ...currentSelected,
            label: newValue,
        });
    };

    /**
     * Function to handle year change
     * @param {Object} newValue
     */
    const handleYearChange = (newValue) => {
        setSelectValue({
            ...selectValue,
            year: newValue,
        });
    };

    /**
     * Function to handle quarter change
     * @param {Object} newValue
     */
    const handleQuarterChange = (newValue) => {
        setSelectValue({
            ...selectValue,
            quarter: newValue,
        });
    };

    /**
     * Function to handle go button click for QTR and Year
     */
    const handleQuarterYearChange = () => {
        if ((selectValue.quarter && selectValue.year) || (!selectValue.quarter && !selectValue.year)) {
            setCurrentSelection({
                ...currentSelected,
                quarter: selectValue.quarter,
                year: selectValue.year,
            });
        }
    };

    let year; let
        quarter;
    if (currentSelected.year) {
        year = currentSelected.year.value;
    }

    if (currentSelected.quarter) {
        quarter = currentSelected.quarter.value;
    }

    const dashboardChartView = (
        <Grid
            container
            maxwidth="xl"
            className={classes.mainSectionInTab}
            direction="row"
            spacing={2}
        >
            <Grid item md={6} sm={12} lg={6} className={classes.inMainSectionInTab}>
                <PortfolioSnapshot
                    targetId={currentlySelectedTarget ? currentlySelectedTarget.id : ''}
                    reportId={reportDetails.id}
                    quarter={quarter}
                    year={year}
                    label={currentSelected.label.value}
                    isRaasChampion={isRaasChampion}
                    raasAccount={raasAccount}
                    categories={categories}
                    raasAccountSelected={raasAccountSelected}
                />
            </Grid>
            <Grid item md={6} sm={12} lg={6} className={classes.inMainSectionInTab}>
                <RecentChangesInRisk
                    targetId={currentlySelectedTarget ? currentlySelectedTarget.id : null}
                    reportId={reportDetails.id}
                    quarter={quarter}
                    year={year}
                    label={currentSelected.label.value}
                    categories={categories}
                    raasAccountSelected={raasAccountSelected}
                />
            </Grid>
        </Grid>
    );

    const handleShareClick = () => {
        setOpenShare(true);
    };

    const closeShare = () => {
        setOpenShare(false);
    };

    /**
     * Callback function for download API success
     * @param {Object} res
     */
    const targetDownloadSuccess = (res) => {
        const content = res.headers['content-type'];
        const title = `QPR_${raasAccountSelected.name
            ? raasAccountSelected.name.replaceAll(' ', '_')
            : LoginDetails.loginUser.profile.org_name.replaceAll(' ', '_')}_${currentSelected.quarter.value}_${currentSelected.year.value}.pdf`;
        download(res.data, title, content);
        setDownloading(false);
    };

    /**
     * Function to handle download failure
     * @param {Object} error
     */
    const downloadFailure = (error) => {
        if (error.response) {
            console.log(error);
        }
        setDownloading(false);
    };

    const handleDownloadClick = () => {
        setDownloading(true);
        const requestParams = {
            quarter: currentSelected.quarter.value,
            year: currentSelected.year.value,
            client_org: raasAccountSelected.id,
        };
        sendRequest(
            'get',
            'api/v2/risk/pdfreportqpr/',
            requestParams,
            getRequestedHeader(),
            targetDownloadSuccess,
            downloadFailure,
            null,
            'blob'
        );
    };

    const yearOptions = getYearOption(selectValue.quarter && selectValue.quarter.value);
    const quarterOptions = getQuarterOption(selectValue.year && selectValue.year.value);
    const goBtnEnabled = (selectValue.quarter && selectValue.year) || (!selectValue.quarter && !selectValue.year);

    const shareAPI = 'api/v2/risk/pdfreportqpr/';

    const shareParams = {
        report_type: reportDetails.id,
    };

    if (raasAccountSelected.id) {
        shareParams.client_org = raasAccountSelected.id;
    }

    if (raasAccountSelected.id) {
        shareParams.client_org = raasAccountSelected.id;
    }

    const onEasyShareSuccess = () => {
        setOpenShare(false);
        setEasyShareSuccess(true);
    };

    const showBanner = localStorageGetItem('isBannerPopupShow');
    const appVersion = localStorageGetItem('appVersion');
    useEffect(() => {
        if (!compareAppVersion(appVersion)) {
            setOpen(true);
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
        localStorageSetItem('isBannerPopupShow', 'no');
        localStorageSetItem('appVersion', LATEST_VERSION);
    };

    const dontShowClose = () => {
        setOpen(false);
        localStorageSetItem('isBannerPopupShow', 'no');
        localStorageSetItem('appVersion', LATEST_VERSION);
    };
    return (
        <div className={classes.root}>
            {(isPageLoading || downloading) ? <Loader /> : ''}
            {isExumaEnabled && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>

                        <div className={classes.paperComingSoon}>
                            <Box className={classes.overlay}>
                                <img src={exumaLogo} alt="Exuma Logo" className={classes.exuStyle} />
                                <span>
                                    {' '}
                                    <CloseIcon className={classes.closeIconlay} onClick={handleClose} />
                                </span>
                            </Box>
                            <Box className={classes.divNext}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 10px' }}>
                                    <div />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '33%' }}>
                                        <span style={{ fontWeight: 'bold' }}>App Version</span>
                                        <span style={{ color: '#428BCA' }}>{LATEST_VERSION}</span>

                                    </div>
                                </div>
                                <Box py={3}>
                                    <Typography variant="h4">
                                        We have new features for you!
                                    </Typography>
                                    <Typography variant="body1" className={classes.smFont}>
                                        Configure your own Auto Actions right from our live dashboards and get them through email, Slack, or Webhooks. Don’t forget to look for an all-new Location Analytics live dashboard if you monitor countries or cities.
                                    </Typography>
                                    <Typography variant="body1" className={classes.smFont}>
                                        What’s more, you can now build your own live dashboards and access expanded Auto Actions with a premium add-on to your Supply Wisdom subscription.
                                    </Typography>
                                </Box>

                                <Box>
                                    <Link
                                        className={classes.readMoreLink}
                                        to={{
                                            pathname: 'https://success.supplywisdom.com/knowledge/custom-dashboards-and-auto-actions',
                                        }}
                                        target="_blank"
                                        onClick={handleClose}
                                    >
                                        Read more
                                        <span>
                                            {' '}
                                            {<ArrowForwardIcon className={classes.moreIcon} />}
                                        </span>
                                    </Link>
                                </Box>
                                <Box>
                                    <Typography
                                        className={classes.bottomUnderline}
                                        onClick={dontShowClose}

                                    >
                                        Don’t show this message again
                                    </Typography>
                                </Box>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
            )}
            <AskAnalystContainerView />
            <Box px={3} pt={3}>
                <Grid
                    container
                    className={classes.mainSectionInTabMob}
                    direction="row"
                    spacing={2}
                >
                    <Grid item md={6} sm={12} lg={6} className={classes.mainAlgnData}>
                        <Box className={classes.algnData}>
                            <Tabs
                                name="targetType"
                                value={currentSelected.targetType}
                                onChange={handleTargetChange}
                                textColor="primary"
                                aria-label="full width tabs example"
                                className={`${classes.tabStyle} tour-dashboard-target-type`}
                            >
                                {targetType.map((target, index) => (
                                    <Tab
                                        label={target.name}
                                        classes={{ selected: classes.backColor }}
                                        className={classes.tabStyleIn}
                                        key={target.name}
                                        {...a11yProps(1)}
                                    />
                                ))}
                            </Tabs>
                            <LabelContainer
                                openModal={openModal}
                                organizationId={typeof raasAccountSelected.id !=='undefined' ? raasAccountSelected.id : organizationId}
                                handleLabelChange={handleLabelChange}
                            />
                        </Box>
                    </Grid>
                    {isUniversalSearchEnabled ? (
                        <Grid item md={6} sm={12} lg={6} className={classes.topMainCont}>
                            <Box className={classes.searchTPoupInput}>
                                <CustomSelect
                                    isMulti
                                    options={targetOption}
                                    styles={customSelectSearchStyles}
                                    isClearable
                                    name="textsearch"
                                    placeholder="Search Target by Name(Max 10)"
                                    onChange={handleMultiFilter}
                                    value={searchedTargetList.searchedTarget}
                                />
                                <span className={classes.iconTargetField} onClick={handleSearchedTarget}>
                                    <ArrowRightAltIcon />
                                </span>
                            </Box>
                            <Box>
                                {searchedTargetList.isError && <Box className={classes.mandatoryError}>Max limit target 10</Box>}
                            </Box>
                        </Grid>
                    ) : null}
                    <Grid item md={6} sm={12} lg={6} className={classes.mainAlgnData}>
                        <Box className={classes.mainSelectBoxSecRight}>
                            <Box className={`${classes.mainSelectBoxSec} tour-dashboard-period-quarter`}>
                                <Select
                                    options={quarterOptions}
                                    styles={customSelectStyles}
                                    onChange={handleQuarterChange}
                                    components={{ IndicatorSeparator: () => null }}
                                    value={selectValue.quarter}
                                    placeholder="QTR"
                                    isClearable
                                />
                                <DateRangeRoundedIcon className={classes.filterImgR} />
                            </Box>
                            <Box className={`${classes.mainSelectBoxSec} tour-dashboard-period-year`}>
                                <Select
                                    options={yearOptions}
                                    styles={customSelectStyles}
                                    onChange={handleYearChange}
                                    components={{ IndicatorSeparator: () => null }}
                                    value={selectValue.year}
                                    placeholder="Year"
                                    isClearable
                                />
                                <CalendarTodayRoundedIcon className={classes.filterImgRSec} />
                            </Box>
                            <ArrowRightAltIcon
                                onClick={handleQuarterYearChange}
                                className={goBtnEnabled ? classes.iconTargetField : `${classes.iconTargetField} ${classes.disabledBtn}`}
                            />
                            {isUniversalSearchEnabled ? null : (
                                <Box className={classes.mainSocialSign}>
                                    <Tooltip title="Email Portfolio Report" placement="bottom">
                                        <Box
                                            className={`${classes.socialSign} tour-dashboard-share`}
                                            onClick={handleShareClick}
                                        >
                                            <ShareIcon />
                                        </Box>
                                    </Tooltip>
                                    <ShareComponent
                                        open={openShare}
                                        shareAPI={shareAPI}
                                        shareMethod="post"
                                        closeShare={closeShare}
                                        requestParams={shareParams}
                                        onSuccess={onEasyShareSuccess}
                                    />
                                    <CustomModal
                                        modalData={easyShareSuccessModalElements}
                                        open={easyShareSuccess}
                                        title=""
                                        handleClose={closeEasySharePopup}
                                    />
                                    {
                                        ((year === 2022 && quarter !== 'Q1') || year > 2022) ? (
                                            <Tooltip title="Download Portfolio Report as PDF" placement="bottom">
                                                <Box
                                                    className={`${classes.socialSignR} tour-dashboard-sdownload`}
                                                    onClick={handleDownloadClick}
                                                >
                                                    <GetAppIcon />
                                                </Box>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Download Portfolio Report as PDF" placement="bottom">
                                                <Box
                                                    className={`${classes.socialSignRDisbl} tour-dashboard-download`}
                                                >
                                                    <GetAppIcon />
                                                </Box>
                                            </Tooltip>
                                        )
                                    }
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    {isUniversalSearchEnabled ? (
                        <Grid item md={6} sm={12} lg={6} className={classes.topMainCont}>
                            <Box className={classes.mainSocialSign}>
                                <Tooltip title="Email Portfolio Report" placement="bottom">
                                    <Box
                                        className={`${classes.socialSign} tour-dashboard-share`}
                                        onClick={handleShareClick}
                                    >
                                        <ShareIcon />
                                    </Box>
                                </Tooltip>
                                <ShareComponent
                                    open={openShare}
                                    shareAPI={shareAPI}
                                    shareMethod="post"
                                    closeShare={closeShare}
                                    requestParams={shareParams}
                                    onSuccess={onEasyShareSuccess}
                                />
                                <CustomModal
                                    modalData={easyShareSuccessModalElements}
                                    open={easyShareSuccess}
                                    title=""
                                    handleClose={closeEasySharePopup}
                                />
                                {
                                    ((year === 2022 && quarter !== 'Q1') || year > 2022) ? (
                                        <Tooltip title="Download Portfolio Report as PDF" placement="bottom">
                                            <Box
                                                className={`${classes.socialSignR} tour-dashboard-sdownload`}
                                                onClick={handleDownloadClick}
                                            >
                                                <GetAppIcon />
                                            </Box>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Download Portfolio Report as PDF" placement="bottom">
                                            <Box
                                                className={`${classes.socialSignRDisbl} tour-dashboard-download`}
                                            >
                                                <GetAppIcon />
                                            </Box>
                                        </Tooltip>
                                    )
                                }
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>
            <Box>
                <TabPanel
                    value={currentSelected.targetType}
                    index={0}
                    dir={theme.direction}
                    className={classes.paddSec}
                >
                    {dashboardChartView}
                </TabPanel>
                <TabPanel
                    className={classes.paddSec}
                    value={currentSelected.targetType}
                    index={1}
                    dir={theme.direction}
                >
                    {dashboardChartView}
                </TabPanel>
                <TabPanel
                    className={classes.paddSec}
                    value={currentSelected.targetType}
                    index={2}
                    dir={theme.direction}
                >
                    {dashboardChartView}
                </TabPanel>
            </Box>
        </div>
    );
};

export default Dashboard;

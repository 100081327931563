import L from 'leaflet';
import locationGreenIcon from '../../Images/locationGreen.svg';
import locationOrangeIcon from '../../Images/locationOrange.svg';
import locationRedIcon from '../../Images/locationRed.svg';
import locationYellowIcon from '../../Images/locationYellow.svg';

const LOGIN_SIGNUP_MESSAGE_LIST = [
    {
        title: 'Continuous',
        message: 'Always-on monitoring for continuous risk intelligence, scores,alerts and reports',
    },
    {
        title: 'Widest Risk Coverage',
        message: 'Comprehensive third-party and location risk coverage',
    },
    {
        title: 'Curated',
        message: 'No noise alerts and zero false positives',
    },
    {
        title: 'Patented',
        message: 'Unique and innovative solution',
    },
    {
        title: 'No Software',
        message: 'Cloud or API, coverage starts within 24 hours of sign-up',
    },
    {
        title: 'Integrated View of Risk',
        message: 'Single pane view of all risks',
    },
    {
        title: 'Automated Risk Management',
        message: 'Enables automated risk actions with reduced manual intervention',
    }];

const RISK_CATEGORY_COLORS = {
    CRITICAL_RISK: '#c01610',
    LOW_RISK: '#70ad47',
    HIGH_RISK: '#ed7d31',
    MODERATE_RISK: '#F4BF2D',
    INFO_RISK: '#5B9BD5',
};

const RISK_CATEGORY_RANGE = {
    LOW_RISK_LIMIT: 4.99,
    MODERATE_RISK_LIMIT: 6.99,
    HIGH_RISK_LIMIT: 8.99,
    CRITICAL_RISK_LIMIT: 10,
    INFO_RISK_LIMIT: 0,
};

const RAAS_CHAMPION_PLOT_COLORS = {
    SUPPLY_WISDOM: '#00C0AA',
    ORG_1: '#9B69E0',
    ORG_2: '#4661F4',
};

const RISK_BAND_COLOR = ['#574AE2', '#2F9BF7', '#273469', '#AB81CD', '#95DCAE', '#73956F', '#F9C784',
    '#5D737E', '#3DDC97', '#539987', '#30343F', '#CCF5AC', '#E4D9FF', '#654597',
    '#1E2749', '#E85F5C', '#91A050', '#33658A', '#CEA07E', '#ED6A5A', '#37505C',
    '#3BB3B5', '#9CFFFA', '#773344', '#E3B5A4', '#606C38', '#896081', '#065143',
    '#1F01B9', '#B4E33D', '#594167', '#726DA8', '#F7996E', '#A0D2DB', '#F0B67F',
    '#C1F7DC', '#F6828C', '#E7E7E7', '#FFCAD4', '#E2ADF2'];

const THIRD_PARTY_TARGETS_HEATMAP_ORDER = [
    { id: 0, name: 'Composite Risk' },
    { id: 1, name: 'Financial' },
    { id: 1621, name: 'Operations' },
    { id: 1622, name: 'Compliance' },
    { id: 1574, name: 'Cyber' },
    { id: 1623, name: 'ESG' },
    { id: 1888, name: 'Nth Party' },
    { id: 1889, name: 'Location' },
];

const CITIES_TARGETS_HEATMAP_ORDER = [
    { id: 0, name: 'Composite Risk' },
    { id: 919, name: 'Financial' },
    { id: 1002, name: 'Business' },
    { id: 1049, name: 'Scalability' },
    { id: 1029, name: 'Legal, Security and Compliance' },
    { id: 1807, name: 'ESG' },
    { id: 900, name: 'Macro-Economic' },
    { id: 981, name: 'Infrastructure' },
    { id: 957, name: 'Geo-Political' },
];

const COUNTRIES_TARGETS_HEATMAP_ORDER = [
    { id: 0, name: 'Composite Risk' },
    { id: 713, name: 'Financial' },
    { id: 791, name: 'Business' },
    { id: 839, name: 'Scalability' },
    { id: 819, name: 'Legal, Security and Compliance' },
    { id: 1722, name: 'ESG' },
    { id: 696, name: 'Macro-Economic' },
    { id: 771, name: 'Infrastructure' },
    { id: 750, name: 'Geo-Political' },
];

const APP_STEPS = [
    {
        target: '.select-tier',
        disableBeacon: true,
        content: 'Select a pre-built tier to load risk intelligence for specific targets',
    },
    {
        target: '.select-account',
        disableBeacon: true,
        content: 'Select the client account which you want to load',
    },
    {
        target: '.select-dashboard',
        disableBeacon: true,
        content: 'Select your preferred pre-built dashboard',
    },
    {
        target: '.tour-home',
        disableBeacon: true,
        content: 'Go the Supply Wisdom dashboard',
    },
    {
        target: '.tour-risk-insight',
        disableBeacon: true,
        content: 'View articles from Supply Wisdom risk analysts on macro and micro trends',
    },
    {
        target: '.tour-help',
        disableBeacon: true,
        content: 'Help videos and guides for using Supply Wisdom',
    },
    {
        target: '.tour-user',
        disableBeacon: true,
        content: 'Configure your account and notifications here',
    },
    {
        target: '.tour-dashboard-target-type',
        disableBeacon: true,
        content: 'Select the target type for which you want to explore Supply Wisdom risk intelligence',
    },
    {
        target: '.tour-dashboard-label',
        disableBeacon: true,
        content: 'Filter your dashboard view based on existing or new label',
    },
    {
        target: '.tour-dashboard-period-quarter',
        disableBeacon: true,
        content: 'Display risk intelligence for the selected quarter',
    },
    {
        target: '.tour-dashboard-period-year',
        disableBeacon: true,
        content: 'Display risk intelligence for the selected year',
    },
    {
        target: '.tour-dashboard-share',
        disableBeacon: true,
        content: 'Share portfolio',
    },
    {
        target: '.tour-dashboard-download',
        disableBeacon: true,
        content: 'Download portfolio report',
    },
];

const LINK_OPTIONS = [
    {
        value: 'dashboard',
        label: 'Dashboard',
    },
    {
        value: 'alertDashboard',
        label: 'Alerts',
    },
    {
        value: 'PrimaryAlertDashboard',
        label: 'Alert Analytics',
    },
    {
        value: 'PrimaryThirdPartyDashboard',
        label: 'Third Party Analytics',
    },
    {
        value: 'LocationAnalyticsDashboard',
        label: 'Location Analytics',
    },
    {
        value: 'Administration',
        label: 'Dashboard Settings',
    },
    // {
    //     value: 'ExecutiveOne',
    //     label: 'Executive 1',
    // },
    // {
    //     value: 'ExecutiveTwo',
    //     label: 'Executive 2',
    // },
    // {
    //     value: 'AnalystTargets',
    //     label: 'Analyst – Targets',
    // },
    // {
    //     value: 'AnalystLocations',
    //     label: 'Analyst – Locations',
    // },
    // {
    //     value: 'Administrator',
    //     label: 'Administrator',
    // },
    // {  value: 'Administrator',
    // label: 'Administrator', },
    // {  value: 'Analyst',
    //     label: 'Analyst', },
    // {  value: 'Executive',
    //     label: 'Executive', },
];

const DASHBOARD_IDS = {
    ADMINISTRATION: process.env.REACT_APP_DASHBOARD_SETTINGS,
    ADMINISTRATOR: '63c0e5b73e387a0035c5214e',
    ANALYST_LOCATION: '632b39c88355580037541900',
    ANALYST_TARGETS: '6329f0bc835558003754163d',
    EXECUTIVE_ONE: '6329cc5c5b3b0700373ba25a',
    EXECUTIVE_TWO: '632b458c8355580037541a06',
    PRIMARY_ALERT_DASHBOARD: process.env.REACT_APP_ALERT_ANALYTICS,
    PRIMARY_ALERT_THIRD_PARTY_DASHBOARD: process.env.REACT_APP_THIRD_PARTY_ANALYTICS,
    LOCATION_ANALYTICS: process.env.REACT_APP_LOCATION_ANALYTICS,
};

const PARTY_MAP_DISPLAY = [1888, 1889];
const LOCATION_PARTY_MAP_DISPLAY = [1889];

const SIGNIN_USERNAME_PASSWORD = process.env.REACT_APP_SIGNIN_USERNAME_PASSWORD === 'true';

const selectMarkerIcon = (riskRating) => {
    let icon;
    if ((riskRating === 'low') || (riskRating > RISK_CATEGORY_RANGE.INFO_RISK_LIMIT && riskRating <= RISK_CATEGORY_RANGE.LOW_RISK_LIMIT)) {
        icon = new L.Icon({
            iconUrl: locationGreenIcon,
            iconSize: [20, 20],
        });
    } else if (
        (riskRating === 'moderate') || (riskRating > RISK_CATEGORY_RANGE.LOW_RISK_LIMIT && riskRating <= RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT)
    ) {
        icon = new L.Icon({
            iconUrl: locationYellowIcon,
            iconSize: [20, 20],
        });
    } else if (
        (riskRating === 'high') || (riskRating > RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT && riskRating <= RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT)
    ) {
        icon = new L.Icon({
            iconUrl: locationOrangeIcon,
            iconSize: [20, 20],
        });
    } else if (
        (riskRating === 'critical') || (riskRating > RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT && riskRating <= RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT)
    ) {
        icon = new L.Icon({
            iconUrl: locationRedIcon,
            iconSize: [20, 20],
        });
    } else {
        icon = new L.Icon({
            iconUrl: locationGreenIcon,
            iconSize: [20, 20],
        });
    }
    return icon;
};

const LATEST_VERSION = process.env.REACT_APP_VERSION;

export {
    LOGIN_SIGNUP_MESSAGE_LIST,
    RISK_CATEGORY_COLORS,
    RISK_CATEGORY_RANGE,
    RAAS_CHAMPION_PLOT_COLORS,
    RISK_BAND_COLOR,
    THIRD_PARTY_TARGETS_HEATMAP_ORDER,
    CITIES_TARGETS_HEATMAP_ORDER,
    COUNTRIES_TARGETS_HEATMAP_ORDER,
    APP_STEPS,
    LINK_OPTIONS,
    DASHBOARD_IDS,
    PARTY_MAP_DISPLAY,
    LOCATION_PARTY_MAP_DISPLAY,
    selectMarkerIcon,
    SIGNIN_USERNAME_PASSWORD,
    LATEST_VERSION,
};

import { Box } from '@material-ui/core';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import CustomCheckbox from '../common/CustomCheckbox';
import CustomRadio from '../common/CustomRadio';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect, useState } from 'react';
import RiskiestByTargets from './RiskiestTargetsView';
import Typography from '@material-ui/core/Typography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import sendRequest from '../../httpRequest';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
    targetSeeTarget: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    radioSecionRight: {
        flexDirection: 'row',
        width: '100%',
    },

    fontSetPort: {
        color: '#07212B',
    },

    linkClass: {
        textDecoration: 'none',
        color: 'inherit',
        marginRight: 5,
        width: 155,
        textAlign: 'right',
    },

    filterButtonInner: {
        border: '0.800000011920929px solid #29456241',
        borderRadius: '2px',
        backgroundColor: '#2045650B',
        color: '#204565',
        width: '36px',
        height: '36px',
        padding: '7px',
        cursor: 'pointer',
    },

    arrowRight: {
        border: 'solid #428BCA',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
        transform: 'rotate(-45deg)',
    },

    radioSecionFont: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('1000', '1350')]: {
            fontSize:'15px',
        },
    },
    radioSecionFontLeftSec: {
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        marginRight: '55px',
    },

    filterButtonInnerMenu: {
        marginTop: '0px',
        marginLeft: '-56px',
        '& li': {
            fontSize: '18px',
            color: '#204565',
        },
        [breakpoints.down('xs')]: {
            marginLeft: '0px',
            marginTop: '0px',
        },
    },

    FilterDoneSec: {
        marginTop: '10px',
        display: 'block',
        textAlign: 'right',
        color: '#428BCA !important',
    },
    seeAllTarget: {
        cursor: 'pointer',
    },
}));

const TopTenTargets = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [anchorElf, setAnchorElf] = useState(null);
    const [categories, setCategories] = useState([]);
    const [riskView, setFindingView] = useState('byFindings');
    const targetNameMapper = { 1: 'cities', 2: 'countries', 3: 'targets' };
    const { raasAccountSelected, targetId, reportId } = props;
    const seeAllTargetText = targetNameMapper[targetId] || 'targets';

    useEffect(() => {
        const categorySet = props.categories;
        const target_categories = [];
        categorySet.map((category, index) => {
            if (category.target_type === targetId) {
                category.selectStatus = true;
                target_categories.push(category);
            }
        });
        setCategories(target_categories);
    }, [props.categories]);

    /**
   * Function to handle category change
   * @param {Object} event
   */
    const handleCategoryChange = (event) => {
        const category = event.target.name;
        const value = event.target.checked;
        const changedCategory = [];
        categories.forEach((cat) => {
            if (cat.name === category) {
                cat.selectStatus = value;
            }
            changedCategory.push(cat);
        });
        setCategories(changedCategory);
    };

    const handleClickf = (event) => {
        setAnchorElf(event.currentTarget);
    };

    const handleClosef = () => { };

    /**
   * Function to handle done button on category filter click
   */
    const handleDoneClick = () => {
        setAnchorElf(null);
    };

    /**
   * Function to handle risk radio change
   * @param {Object} event
   */
    const handleRiskRadio = (event) => {
        setFindingView(event.target.value);
    };

    return (
        <Box>
            <Box pb={3} pt={3} className={classes.targetSeeTarget}>
                <Box>
                    <Typography className={classes.fontSetPort} variant="h5">
                        Top 10 Targets
                    </Typography>
                </Box>
                <Link
                    to={`/targetList?targetType=${targetId || ''}&reportType=${reportId || ''}`}
                    className={classes.linkClass}
                >
                    <Box className={classes.seeAllTarget}>
                        <Typography color="primary" variant="subtitle1">
                            {`See all ${seeAllTargetText} `}
                            <i className={classes.arrowRight} />
                        </Typography>
                    </Box>
                </Link>
            </Box>
            <Box pb={2} className={classes.targetSeeTarget}>
                <RadioGroup
                    classes={{ root: classes.radioSecionRight }}
                    aria-label="gender"
                    name="gender1"
                    value={riskView}
                    onChange={handleRiskRadio}
                >
                    <FormControlLabel
                        classes={{ label: classes.radioSecionFontLeftSec }}
                        value="byFindings"
                        control={<CustomRadio />}
                        label="By Domain"
                        name="byFindings"
                    />
                    <FormControlLabel
                        classes={{ label: classes.radioSecionFont }}
                        value="byCompositeScore"
                        control={<CustomRadio />}
                        label="By Composite Rating"
                        name="byCompositeScore"
                    />
                </RadioGroup>
                <Box>
                    <FilterListIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClickf}
                        className={classes.filterButtonInner}
                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElf}
                        keepMounted
                        open={Boolean(anchorElf)}
                        onClose={handleDoneClick}
                        classes={{ paper: classes.filterButtonInnerMenu }}
                    >
                        {categories.map((category) => (
                            <MenuItem onClick={handleClosef} key={category.name}>
                                {' '}
                                <CustomCheckbox
                                    checked={category.selectStatus}
                                    onChange={handleCategoryChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    name={category.name}
                                />
                                {' '}
                                {category.name}
                            </MenuItem>
                        ))}
                        <MenuItem
                            color="primary"
                            className={classes.FilterDoneSec}
                            onClick={handleDoneClick}
                        >
                            Done
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            {riskView === 'byFindings' ? (
                <RiskiestByTargets
                    targetFilter="byFindings"
                    targetId={props.targetId}
                    reportId={props.reportId}
                    quarter={props.quarter}
                    year={props.year}
                    label={props.label}
                    filteredCategories={categories}
                    raasAccountSelected={raasAccountSelected}
                />
            ) : (
                <RiskiestByTargets
                    targetFilter="byCompositeScore"
                    targetId={props.targetId}
                    reportId={props.reportId}
                    quarter={props.quarter}
                    year={props.year}
                    label={props.label}
                    filteredCategories={categories}
                    raasAccountSelected={raasAccountSelected}
                />
            )}
        </Box>
    );
};

export default TopTenTargets;

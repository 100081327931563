import { Box, Typography } from '@material-ui/core';
import { getFAQs } from '../redux/actions/ParameterAction';
import { getRequestedHeader } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AskAnalystContainerView from '../containers/AskAnalystContainerView';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import InputTextField from './common/InputTextField';
import Loader from './common/LoaderView';
import React, { Fragment, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import parse from 'html-react-parser';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },

    setingsPart: {
        color: '#07212B',
        textAlign: 'center',
    },

    faqMain: {
        maxWidth: '1050px',
        margin: '0px auto',
        [breakpoints.down('xs')]: {
            padding: '0px 8px',
        },
    },

    maxWdSectionOrg: {
        display: 'flex',
        position: 'relative',
        [breakpoints.between('sm', 'md')]: {
            padding: '0px 24px',
        },
        '& div': {
            width: '100%',
            borderRadius: '0px',
        },
    },
    inpuPart: {
        '& input': {
            padding: '12px 45px !important',
            [breakpoints.between('sm', 'md')]: {
                padding: ' 15.5px 45px !important',
            },
        },
    },

    buttonStleBlock: {
        fontSize: '16px',
        width: '100px',
        height: '48px',
        background: '#428BCA',
        color: '#fff',
        border: 'none',
        [breakpoints.down('xs')]: {
            height: '40px',
        },
    },
    searchPoupInputIcon: {
        position: 'absolute',
        left: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#346FA1',
        [breakpoints.between('sm', 'md')]: {
            left: '4%',
        },
    },
    accordianIcon: {
        fontSize: '28px',
        color: '#284562',
    },
    mainSecAccordian: {
        backgroundColor: 'rgba(242, 247, 251, .6)',
        minHeight: '56px !important',
        alignItems: 'center',
        paddingLeft: '45px',
        position: 'relative',
        borderRadius: '0px',
        marginTop: '7px',
    },

    accodianHeading: {
        justifyContent: 'space-between',
        margin: '0px !important',
        '& img': {
            width: '45%',
        },
    },
    heading: {
        color: '#428BCA',
    },

    expendIcon: {
        position: 'absolute',
        left: '0px',
        top: '-2px',
        padding: '0px',
        transform: 'rotate(-90deg)',
        '& span': {
            paddingRight: '30px',
            marginLeft: '-10px',
            marginTop: '-10px',
        },
    },
    expendIconSect: {
        transform: 'rotate(0deg) !important',
        '& span': {
            paddingLeft: '15px',
            paddingTop: '26px',
        },
    },

    accoDetailSec: {
        padding: '10px 15px',
        backgroundColor: '#F2F7FB',
        display: 'block',
    },
}));

const FAQ = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // Header start
    const [loading, setLoadingStatus] = useState(false);
    const [initialResults, setInitialResults] = React.useState([]);
    const [results, setResults] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const allFAQs = useSelector((state) => state.ParametertReducer.faqs);

    useEffect(() => {
        if (allFAQs && allFAQs.length) {
            setResults(allFAQs);
            setInitialResults(allFAQs);
        }
    }, [allFAQs]);

    /**
   * After load, calling for the list of FAQs.
   */
    useEffect(() => {
        getFAQs(dispatch);
    }, []);

    /**
   * Generates view from the HTTP response and is called during rendering.
   * @param {Object} HTTP Response Data
   * @return React Element.
   */
    const getTheFAQsView = () => {
        let resultsView = [];

        if (results.length > 0) {
            results.forEach((result) => {
                resultsView.push(
                    <Box py={0.5}>
                        <Accordion className={classes.topMainSecAccordian}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreOutlinedIcon className={classes.accordianIcon} />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.mainSecAccordian}
                                classes={{
                                    content: classes.accodianHeading,
                                    expandIcon: classes.expendIcon,
                                    expanded: classes.expendIconSect,
                                }}
                            >
                                <Typography className={classes.heading} variant="subtitle2">
                                    {result && result.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accoDetailSec}>
                                {result && result.answer && parse(result.answer)}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                );
            });
        } else if (
            (!results || results.length === 0)
            && !loading
            && !errorMessage
        ) {
            resultsView = <span style={{padding:'10px 24px'}}>Sorry unable to find the searched FAQ!</span>;
        } else if ((!results || results.length === 0) && !loading && errorMessage) {
            resultsView = <span>Some error occured.</span>;
        }

        return resultsView;
    };

    /**
   * Works on the change of the Search Value
   * @param {Object} Event object
   */
    const changeSearchValue = (event) => {
        if (event.target.value) {
            setSearchValue(event.target.value);
        } else {
            setResults(initialResults);
            setSearchValue('');
        }
    };

    /**
   * Search textbox on KeyPress event
   * @param {Object} Event object
   */
    const keyPress = (event) => {
        setErrorMessage('');

        if (event.key == 'Enter') {
            searchFAQ();
        }
    };

    /**
   * Function to be called after Search button click or enter button click.
   */
    const searchFAQ = () => {
        if (searchValue) {
            setResults(
                results.filter((result) => {
                    if (
                        result.question.indexOf(searchValue) > -1
                        || result.answer.indexOf(searchValue) > -1
                    ) {
                        return result;
                    }
                })
            );
        } else {
            setResults(initialResults);
        }
    };

    return (
        <Fragment>
            <div className={classes.root}>
                {loading ? (
                    <Loader />
                ) : (
                    <Box className={classes.faqMain}>
                        <Box pt={5} pb={4}>
                            <Typography className={classes.setingsPart} variant="h5">
                                Supply Wisdom Help Centre - FAQs
                            </Typography>
                        </Box>
                        <Box className={classes.maxWdSectionOrg}>
                            <SearchIcon className={classes.searchPoupInputIcon} />
                            <InputTextField
                                className={classes.inpuPart}
                                type="text"
                                placeholder="Search"
                                name="search"
                                value={searchValue}
                                onChange={changeSearchValue}
                                onKeyPress={keyPress}
                            />
                            <button
                                className={classes.buttonStleBlock}
                                onClick={searchFAQ}
                                type="button"
                            >
                                Search
                            </button>
                        </Box>
                        {getTheFAQsView()}
                    </Box>
                )}
            </div>
            <AskAnalystContainerView />
        </Fragment>
    );
};

export default FAQ;

import { Box } from '@material-ui/core';
import { RECENT_CHANGES_REPORTING } from '../common/constants';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FilterListIcon from '@material-ui/icons/FilterList';
import Loader from '../common/LoaderView';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import RiskCategory from './RiskCategoryView';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import sendRequest from '../../httpRequest';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
    filterButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    filterButtonInner: {
        border: '0.800000011920929px solid #29456241',
        borderRadius: '2px',
        backgroundColor: '#2045650B',
        color: '#204565',
        width: '36px',
        height: '36px',
        padding: '7px',
        cursor: 'pointer',
    },

    filterButtonInnerMenu: {
        marginTop: '0px',
        marginLeft: '-56px',
        '& li': {
            fontSize: '18px',
            color: '#204565',
        },
    },
    [breakpoints.down('xs')]: {
        marginLeft: '0px',
    },
}));

const ReportingView = (props) => {
    const classes = useStyles();
    const [anchorElf, setAnchorElf] = React.useState(null);
    const [recentChangesInRisk, setRecentChangesInRisk] = useState([]);
    const [loading, setLoadingStatus] = useState(false);

    const { raasAccountSelected, categories, targetId } = props;

    const handleClickf = (event) => {
        setAnchorElf(event.currentTarget);
    };

    const handleClosef = () => {
        setAnchorElf(null);
    };

    /**
   * Request success handler
   * @param {Object} response
   */
    const requestSuccess = (response) => {
        setLoadingStatus(false);
        const riskData = response.data.map((risk, index) => {
            risk.selectStatus = true;
            return risk;
        });
        setRecentChangesInRisk(riskData);
    };

    /**
   * Request failure handler
   * @param {Object} error
   */
    const requestFailure = (error) => {
        setLoadingStatus(false);
        console.log(error);
    };

    /**
   * Function to handle sub-category selection
   * @param {Object} event
   */
    const handlecategorySelection = (event) => {
        const categoryName = event.target.name;
        const value = event.target.checked;
        setRecentChangesInRisk((prevState) => {
            const newState = prevState;
            const currentCategoryIndex = newState.findIndex((risk, index) => risk.name === categoryName);
            newState[currentCategoryIndex].selectStatus = value;
            return newState;
        });
    };

    useEffect(() => {
        setLoadingStatus(true);
        const requestData = {
            target_type: props.targetId,
            report_type: props.reportId,
            quarter: props.quarter,
            year: props.year,
            label: props.label,
            client_org: raasAccountSelected.id,
        };

        sendRequest(
            'get',
            'api/v2/risk/recent-changes/report/',
            requestData,
            getRequestedHeader(),
            requestSuccess,
            requestFailure
        );
    }, [props.targetId, props.reportId, props.quarter, props.year, props.label, raasAccountSelected]);

    const parentCategories = [];
    if (recentChangesInRisk) {
        recentChangesInRisk.filter((risk, index) => {
            if (risk.selectStatus) {
                let lowcount = 0;
                let highcount = 0;
                let moderatecount = 0;
                let criticalcount = 0;
                // Get total count of all targets under each risk
                risk.low.sub_categories.map((risk, index) => {
                    lowcount += risk.targets.length;
                });

                risk.high.sub_categories.map((risk, index) => {
                    highcount += risk.targets.length;
                });

                risk.critical.sub_categories.map((risk, index) => {
                    criticalcount += risk.targets.length;
                });

                risk.moderate.sub_categories.map((risk, index) => {
                    moderatecount += risk.targets.length;
                });

                // prepare data for first level drill down
                parentCategories.push({
                    name: risk.name,
                    id: risk.id,
                    description: risk.description,
                    lowcount,
                    highcount,
                    moderatecount,
                    criticalcount,
                });
            }
        });
    }

    // Add description for each category
    parentCategories.forEach((cat) => {
        const findIndex = categories.findIndex((category) => {
            if (category.target_type === targetId && category.name === cat.name) {
                return true;
            }
        });

        if (findIndex !== -1) {
            cat.description = categories[findIndex].description;
        }
    });

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <Box className={classes.filterButton}>
                        <FilterListIcon
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClickf}
                            className={classes.filterButtonInner}
                        />
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElf}
                            keepMounted
                            open={Boolean(anchorElf)}
                            onClose={handleClosef}
                            classes={{ paper: classes.filterButtonInnerMenu }}
                        >
                            {recentChangesInRisk.map((category, index) => (
                                <MenuItem onClick={handleClosef} key={index}>
                                    {' '}
                                    <Checkbox
                                        checked={category.selectStatus}
                                        onChange={handlecategorySelection}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        name={category.name}
                                    />
                                    {' '}
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    {parentCategories.map((risk, index) => (
                        <RiskCategory
                            key={index}
                            parentRisk={risk}
                            subCategoryRisks={recentChangesInRisk}
                            parentRiskId={risk.id}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ReportingView;

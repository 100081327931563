import { Box, Divider } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    getDisplayView, getLoginDetail, getParameterByName, getRequestedHeader,
    localStorageGetItem, localStorageSetItem, sessionStorageGetItem, sessionStorageSetItem, showWalkthrough,
} from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import AlertIcon from '@material-ui/icons/ReportProblemOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import HomeIcon from '@material-ui/icons/Home';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import Logo from '../../Images/newlogowhite.svg';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import { APP_STEPS, LINK_OPTIONS } from './constants';
import { isContextualHelpEnabled, isExumaEnabled } from '../../common/Constants';
import { toast } from 'react-toastify';
import CustomModal from './CustomModal';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import React, { useEffect, useState } from 'react';
import ReactJoyride from 'react-joyride';
import Select from 'react-select';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TechSupport from '../TechSupport';
import UserIcon from '@material-ui/icons/AccountCircleOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import methodologyImg from '../../Images/methodology.png';
import nav3 from '../../Images/3.svg';
import sendRequest from '../../httpRequest';

const breakpoints = createBreakpoints({});

const getInitialIcon = ({ initials }) => {
    return (
        <div
            style={{
                backgroundColor: 'blue',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 30,
                width: 50,
                height: 50,
            }}>
            <span style={{ color: 'white', fontSize: 25 }}>{initials}</span>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    navbar: {
        backgroundColor: '#0D1B28',
    },
    link: {
        textDecoration: 'none',
        color: '#fff',
        '&:hover': {
            opacity: 0.5,
        },
    },
    knowledgeCenter: {
        cursor: 'pointer',
    },
    mainSection: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 25px',
        [breakpoints.down('sm')]: {
            padding: '0px 10px',
            width: '100%',
        },
    },
    logoStyle: {
        width: '6%',
        padding: '0px',
        [breakpoints.down('sm')]: {
            width: '30%',
            zIndex: '9999',
        },
    },
    logoStyleMain: {
        width: '103%',
        marginTop: '8px',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '55%',
        },
        [theme.breakpoints.between('1000', '1350')]: {
            width: '103%',
        },
    },
    selectBox: {
        width: '285px',
        height: '46px',
        backgroundColor: '#D5DEE112',
        padding: '4px 8px',
        borderRadius: '2px',
        color: '#fff',
        fontSize: '18px',
        marginLeft: '50px',
        fontFamily: 'Gilroy-Semibold',
    },
    selectContainer: {
        marginLeft: '10px',
        '& span': {
            visibility: 'hidden',
        },
        [breakpoints.down('sm')]: {
            width: '55%',
            marginLeft: '20px',
            zIndex: '9999',
        },
        [theme.breakpoints.between('1000', '1350')]: {
            marginLeft: '20px',
        },

    },
    selectContainerDrop: {
        marginLeft: '20px',
        '& span': {
            visibility: 'hidden',
        },
        [breakpoints.down('sm')]: {
            width: '55%',
            marginLeft: '20px',
            zIndex: '9999',
        },
        [theme.breakpoints.between('1000', '1350')]: {
            marginLeft: '20px',
        },

    },
    icon: {
        marginRight: '10px',
    },
    mainSelectBox: {
        padding: '18px 0px',
    },
    rightMenu: {
        marginTop: 0,
        marginBottom: 0,
        height: 62,
        lineHeight: '62px',
        color: '#fff',
        listStyle: 'none',
        padding: '0px',
        ' & li': {
            opacity: '.6',
            display: 'inline-block',
            fontSize: '18px',
            padding: '0px 28px',
            fontFamily: 'Gilroy-Semibold',
            [breakpoints.down('sm')]: {
                display: 'block',
                textAlign: 'center',
                lineHeight: '50px',
            },
            '&:last-child': {
                [breakpoints.down('sm')]: {
                    marginTop: '12px',
                    borderTop: '1px solid #68717A',
                },
            },
            ' & img': {
                width: 'auto',
                padding: '0px 5px 0px 5px',
                verticalAlign: 'middle',
            },
        },
        [breakpoints.down('sm')]: {
            position: 'fixed',
            top: '7%',
            width: '100%',
            right: '0%',
            margin: '0px',
            bottom: '0%',
            zIndex: '999',
            height: '100vh',
            paddingTop: '40px',
            backgroundColor: '#0D1B28',
            opacity: '1',
        },
        [breakpoints.between('sm', 'md')]: {
            top: '5%',
        },
    },
    rightMenuClose: {
        color: '#fff',
        marginTop: 0,
        marginBottom: 0,
        height: 62,
        lineHeight: '62px',
        listStyle: 'none',
        padding: '0px',
        ' & li': {
            display: 'inline-block',
            fontSize: '17px',
            padding: '0px 20px',
            fontFamily: 'Gilroy-Semibold',
            opacity: '.6',
            [breakpoints.down('sm')]: {
                display: 'block',
                textAlign: 'center',
                lineHeight: '50px',
            },
            [breakpoints.between('1000', '1350')]: {
                fontSize: '15px',
                padding: '0px 10px',
            },
            '&:last-child': {
                [breakpoints.down('sm')]: {
                    paddingTop: '12px',
                    marginTop: '12px',
                    borderTop: '1px solid #68717A',
                },
            },
            ' & img': {
                width: 'auto',
                padding: '0px 2px 0px 2px',
                verticalAlign: 'middle',
            },
        },
        [breakpoints.down('sm')]: {
            position: 'fixed',
            top: '7.5%',
            width: '100%',
            right: '0%',
            margin: '0px',
            bottom: '0%',
            zIndex: '999',
            paddingTop: '40px',
            backgroundColor: '#0D1B28',
            opacity: '1',
        },
        [breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    activeListItem: {
        opacity: '1 !important',
        backgroundColor: '#1d2c38',
    },
    dashboardIcon: {
        fontSize: 18,
        verticalAlign: 'middle',
    },
    mainRightMenu: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        [breakpoints.down('sm')]: {
            width: '10%',
        },
    },
    dropDown: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.5,
        },
    },
    marginTop: {
        marginTop: '60px',
        marginLeft: '25px',
        [breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: '0px',
            zIndex: '9999',
            left: '0px !important',
            maxWidth: '100%',
            borderRadius: '0px',
        },
    },
    menuItemm: {
        fontSize: '14px',
        padding: '5px 10px',
        [breakpoints.between('sm', 'md')]: {
            fontSize: '16px',
            lineHeight: '36px',
        },
    },
    menuItemmI: {
        fontSize: '14px',
        marginRight: '15px',
    },
    arrowDown: {
        transform: 'rotate(45deg)',
        border: 'solid #FFFFFF',
        display: 'inline-block',
        padding: '4px',
        borderWidth: '0 2px 2px 0',
        marginLeft: '7px',
        marginTop: '-4px',
    },

    notFicat: {
        position: 'relative',
        opacity: '1 !important',
        '& span': {
            verticalAlign: 'sub',
            '& svg': {
                cursor: 'pointer',
                color: 'rgba(255, 255, 255, .6)',
            },
        },
        '&:hover': {
            '& $hoverPart': {
                display: 'block',
            },
        },
    },
    hoverPart: {
        backgroundColor: '#fff',
        position: 'absolute',
        border: '1px solid #70707029',
        width: '450px',
        boxShadow: '0px 2px 20px #00000026',
        left: '-510%',
        borderRadius: '4px',
        top: '35px',
        zIndex: '999',
        display: 'none',
        '&::before': {
            content: '""',
            padding: '7px',
            position: 'absolute',
            border: 'solid #fff',
            borderWidth: '0 7px 7px 0',
            display: 'inline-block',
            transform: 'rotate(-135deg)',
            right: '18%',
            top: '-6px',
        },
    },

    fontSetPortPoupNot: {
        color: '#0D1B28',
        fontFamily: 'Gilroy-Semibold',
        fontSize: '16px',
        padding: '7px 15px',
    },

    fontSetPortPoupNotFic: {
        display: 'flex',
        alignItems: 'center',
        color: 'rgba(13, 27, 40, .62)',
        fontSize: '14px',
        padding: '12px 15px',
        '& span': {
            marginRight: '25px',
            '& img': {
                width: '95%',
                padding: '0px',
            },
        },
    },
    mainMobileMenu: {
        display: 'none',
        [breakpoints.down('sm')]: {
            display: 'inline-flex',
            verticalAlign: 'bottom',
            cursor: 'pointer',
        },
    },

    mainMobileMenuClos: {
        [breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mainMobileMenuCoss: {
        display: 'none',
        [breakpoints.down('sm')]: {
            verticalAlign: 'bottom',
            cursor: 'pointer',
        },
    },
    mainMobileMenuCossOpen: {
        [breakpoints.down('sm')]: {
            display: 'block',
        },
    },

    mobileMenu: {
        color: '#fff',
        fontSize: '30px',
    },

    rightPad: {
        marginRight: '10px',
        '& span': {
            visibility: 'hidden',
        },
    },

    userBlock: {
        width: '100px',
        maxWidth: '140px',
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        top: '4px',
        justifyContent:'flex-end'
    },

    userNameStyle: {
        wordWrap: 'break-word',
        lineHeight: '1',
        marginLeft: '4px',
    },
}));

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
    }),
    control: (base, state) => ({
        ...base,
        width: '285px',
        height: '46px',
        // overflowY: 'scroll',
        backgroundColor: '#D5DEE112',
        padding: '4px 4px',
        borderRadius: '2px',
        fontSize: '16px',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.down('sm')]: {
            width: '100%',
            height: '40px',
            fontSize: '15px',
            padding: '0px 7px',
        },
        [breakpoints.between('1000', '1350')]: {
            width: '240px',
            height: '40px',
            padding: '0px 7px',
            fontSize: '15px',
        },
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        width: '285px',
        zIndex: '999',
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#fff',
    }),
    singleValue: (provided) => ({
        color: '#fff',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "20ch",
    }),
    methodologyStyle: {
        width: '100%',
    },
};

const customSelectStylesDashboard = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
    }),
    control: (base, state) => ({
        ...base,
        width: '255px',
        height: '46px',
        backgroundColor: '#D5DEE112',
        padding: '4px 12px',
        borderRadius: '2px',
        fontSize: '16px',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.down('sm')]: {
            width: '100%',
            height: '40px',
            fontSize: '15px',
            padding: '0px 7px',
        },
        [breakpoints.between('1000', '1350')]: {
            width: '240px',
            height: '40px',
            padding: '0px 7px',
            fontSize: '15px',
        },
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        width: '285px',
        zIndex: '999',
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#fff',
    }),
    singleValue: (provided) => ({
        color: '#fff',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '160px',
    }),
    methodologyStyle: {
        witdth: '100%',
    },
};

const NavBar = (props) => {
    const history = useHistory();
    const location = useLocation();
    const getLocationAndIndex = () => {
        switch (history.location.pathname) {
            case '/dashboard': return 0;
            case '/alertDashboard': return 1;
            case '/KnowledgeManagement': return 2;
            case '/FAQ': return 3;
            case '/VideoTutorials': return 3;
            case '/labels': return 4;
            case '/settings': return 4;
            case '/organization': return 4;
            default: return -1;
        }
    };

    const classes = useStyles();
    const [reportTypes, setReportType] = useState([]);
    const [openMenu, setopenMenu] = useState(false);
    const [anchorHelpEl, setAnchorHelpEl] = React.useState(null);
    const [anchorUserEl, setAnchorUserEl] = React.useState(null);
    const [showTechSupport, setTechSupportView] = React.useState(false);
    const [raasChampionDetails, setRaasChampionDetails] = useState([]);
    const [userProfile, setUserProfile] = useState({});
    const [isCustomerSuccess, setCustomerSuccess] = useState(false);
    const [currentReport, setCurrentReport] = useState({});
    const [selectedNav, setSelectedNav] = useState(getLocationAndIndex());
    const [currentAccount, setCurrentAccount] = useState({});
    const [open, setOpen] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [currentDashboard, setCurrentDashboard] = useState('');
    const isMobileView = getDisplayView();
    const userDetails = getLoginDetail();

    if (getLocationAndIndex() !== selectedNav) {
        setSelectedNav(getLocationAndIndex());
    }

    const dispatch = useDispatch();
    const {
        stateProps, reportType, actionProps,
    } = props;

    const { isRaasChampion } = stateProps;
    const { setRaasChampion } = actionProps;
    const { raasSelected } = stateProps;

    const mobileMenPop = () => {
        setopenMenu(true);
    };
    const mobileMenPopClose = () => {
        setopenMenu(false);
    };

    useEffect(() => {
        const params = {};
        if (raasSelected && raasSelected.id) {
            params.client_org = raasSelected.id;
        }
        sendRequest('get', 'api/v2/risk/report-type/', params, getRequestedHeader(), getReportTypesSuccess, requestFailure);

        // If userdata not present then call API
        if (props.stateProps && Object.keys(props.stateProps.loginUserData).length) {
            setUserProfile(props.stateProps.loginUserData.loginDetail);
        } else {
            sendRequest('get', 'api/account/getuser/', {}, getRequestedHeader(), getProfileSuccess, requestLoginFailure);
        }
    }, [raasSelected]);

    useEffect(() => {
        const userGroup = [];
        if (userProfile && userProfile.groups) {
            userProfile.groups.forEach((element) => {
                userGroup.push(element.id);
            });
            setCustomerSuccess(userGroup.includes(9));
        }
    }, [userProfile]);

    useEffect(() => {
        if (isRaasChampion) {
            sendRequest(
                'get',
                'api/v2/accounts/raas-organizations/',
                {},
                getRequestedHeader(),
                getRaasChampionDetails,
                requestFailure
            );
        }
    }, [isRaasChampion]);

    /**
   * Callback function for get user API call
   * @param {Object} res
   */
    const getProfileSuccess = (res) => {
        setUserProfile(res.data);
        dispatch(props.actionProps.loginUserDetail(res.data));
        const isRaasChampion = res.data.profile.is_raas_champion;
        dispatch(setRaasChampion(isRaasChampion));
    };

    /**
   * Callback function for Raas champion API call
   * @param {Object} res
   */
    const getRaasChampionDetails = (res) => {
        const raasChampionResponse = res.data;
        dispatch(props.actionProps.updateRaasOrganization(raasChampionResponse));
        setRaasChampionDetails(raasChampionResponse);
        const raasAcc = localStorageGetItem('raasAccount');
        if (raasAcc) {
            setCurrentAccount(raasAcc);
            const user = raasChampionResponse.find((user) => user.name === raasAcc.value);
            dispatch(actionProps.updateRaasAccountSelected(user || {}));
        }
    };

    /**
   * Callback function for getting report type
   * @param {Object} res
   */
    const getReportTypesSuccess = (res) => {
        let { reportType: defaultReportType } = getParameterByName('reportType');
        defaultReportType = defaultReportType || localStorageGetItem('tierSelected');
        if (res.data.report_type && res.data.report_type.length) {
            const reportTypes = res.data.report_type;
            setReportType(reportTypes);
            let selectedReport = reportTypes.filter((option) => option.id == defaultReportType);
            selectedReport = selectedReport.length ? selectedReport : reportTypes;
            setCurrentReport({
                value: selectedReport[0].id,
                label: selectedReport[0].name,
            });
            dispatch(props.actionProps.updateReportId(selectedReport[0].id));
        } else {
            setCurrentReport({
                value: null,
                label: null,
            });
            dispatch(props.actionProps.updateReportId(null));
        }
    };

    /**
   * Request failure callback
   * @param {Object} error
   */
    const requestFailure = (error) => {
        console.log(error);
    };

    /**
   * Request failure callback login
   * @param {Object} error
   */
    const requestLoginFailure = (error) => {
        toast.error('User authentication failed, Please login again');
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('accessToken');
        history.push('/');
    };

    /**
   * Function to handle report dropdown change
   * @param {Object} option
   */
    const handleReportChange = (option) => {
        setCurrentReport(option);
        localStorageSetItem('tierSelected', option.value);
        dispatch(props.actionProps.updateReportId(option.value));
    };

    /**
     * Function which is called after clicking on the Help Menu Item.
     * @param {Object} event
     */
    const handleHelpClickk = (event) => {
        setAnchorHelpEl(event.currentTarget);
    };

    /**
     * Function which is called after clicking on the User Menu Item.
     * @param {Object} event
     */
    const handleUserClickk = (event) => {
        setAnchorUserEl(event.currentTarget);
    };

    /**
     * Function which is called to handle close.
     * @param {Object} event
     */
    const handleClose = () => {
        setAnchorHelpEl(null);
        setAnchorUserEl(null);
        setSelectedNav(-1);
    };

    /**
     * Function to handle FAQ click
     * @param {Object} event
     */
    const onFAQClick = () => {
        setSelectedNav(3);
        setAnchorHelpEl(null);
        setAnchorUserEl(null);
        history.push('/FAQ');
        if (isMobileView) {
            mobileMenPopClose();
        }
    };

    /**
     * Function to handle Methodology click
     * @param {Object} event
     */
    const onMethodologyClick = () => {
        setOpen(true);
        setAnchorHelpEl(null);
    };

    /**
     * Function to handle handlClose
     * @param {Object} event
     */
    const handlClose = () => {
        setOpen(false);
    };

    /**
     * Function to handle VideoTutorials click
     * @param {Object} event
     */
    const onVideoTutorialsClick = () => {
        setSelectedNav(3);
        setAnchorHelpEl(null);
        setAnchorUserEl(null);
        history.push('/VideoTutorials');
        if (isMobileView) {
            mobileMenPopClose();
        }
    };

    /**
     * Function to handle logout click
     * @param {Object} event
     */
    const handleLogout = (event) => {
        const bannerPopup = localStorageGetItem('isBannerPopupShow');
        const appVersion = localStorageGetItem('appVersion');
        localStorage.clear();
        sessionStorage.clear();
        handleClose();
        history.push('/');
        if (isMobileView) {
            mobileMenPopClose();
        }
        if (appVersion) localStorageSetItem('appVersion', appVersion);
        if (bannerPopup) {
            localStorageSetItem('isBannerPopupShow', bannerPopup);
        }
    };

    /**
     * Function to Tech Support click
     * @param {Object} event
     */
    const techSupportClick = () => {
        setAnchorHelpEl(null);
        setTechSupportView(true);
        setSelectedNav(3);
        if (isMobileView) {
            mobileMenPopClose();
        }
    };

    /**
     * Function to Start tour click click
     * @param {Object} event
     */
    const handleStartTour = () => {
        setStepsEnabled(true);
        sessionStorageSetItem('showWalkthrough', 'yes');
        localStorageSetItem('showWalkthrough', 'yes');
        setAnchorHelpEl(null);
    };

    /**
     * Function to Labels click
     * @param {Object} event
     */
    const labelsClick = () => {
        history.push('/labels');
        setAnchorHelpEl(null);
        setAnchorUserEl(null);
        setSelectedNav(4);
        if (isMobileView) {
            mobileMenPopClose();
        }
    };

    /**
     * Function called after Organization click
     * @param {Object} event
     */
    const organizationClick = () => {
        history.push('/organization');
        setAnchorHelpEl(null);
        setAnchorUserEl(null);
        setSelectedNav(4);
        if (isMobileView) {
            mobileMenPopClose();
        }
    };

    /**
     * Function called after Settings click
     * @param {Object} event
     */
    const settingsClick = () => {
        history.push('/settings');
        setAnchorHelpEl(null);
        setAnchorUserEl(null);
        setSelectedNav(4);
        if (isMobileView) {
            mobileMenPopClose();
        }
    };

    /**
   * Handle organization change for Raas champion
   * @param {Object} option
   */
    const handleAccountChange = (option) => {
        setCurrentAccount(option || {});
        if (option) {
            const userName = option.value;
            const user = raasChampionDetails.find((user) => user.name === userName);
            localStorageSetItem('raasAccount', option);
            dispatch(actionProps.updateRaasAccountSelected(user));
        } else {
            dispatch(actionProps.updateRaasAccountSelected({}));
            localStorageSetItem('raasAccount', {});
        }
    };

    const handleLinkChange = (option) => {
        history.push(`/${option.value}`);
        setCurrentDashboard(option.value);
        // if(option.value === 'Dashboard') {
        //     history.push('/dashboard');
        // } else if(option.value === 'Alerts') {
        //     history.push('/alertDashboard');
        // } else if(option.value === 'Executive 1') {
        //     history.push('/ExecutiveOne');
        // } else if(option.value === 'Executive 2') {
        //     history.push('/ExecutiveTwo');
        // } else if(option.value === 'AnalystTargets') {
        //     history.push('/AnalystTargets');
        // } else if(option.value === 'Analyst – Locations') {
        //     history.push('/AnalystLocations');
        // } else if(option.value === 'Administrator') {
        //     history.push('/Administrator');
        // }
        // if(option.value === 'Executive') {
        //     history.push('/Executive');
        // } else if(option.value === 'Administrator') {
        //     history.push('/Administrator');
        // } else if(option.value === 'Analyst') {
        //     history.push('/Analyst');
        // }
    };

    let raasOrganizations = []; let
        reportTypeOptions = [];
    // Add data for report type react-select
    raasOrganizations = raasChampionDetails.map((user) => ({
        value: user.name,
        label: user.name,
    }));

    // Add data for account type react-select
    reportTypeOptions = reportTypes.map((report) => ({
        value: report.id,
        label: report.name,
    }));

    /**
     * Handle navbar parent click
     * @param {number} value
    */
    const handleParentNaveClick = (value) => {
        setSelectedNav(value);
        if (isMobileView) {
            mobileMenPopClose();
        }
    };

    const methodologyImage = (
        <Box>
            <img src={methodologyImg} className={classes.methodologyStyle} style={{ width: '100%' }} alt="Methodology" />
        </Box>
    );

    const selectedReport = reportTypes.find((tier) => tier.id === props.stateProps.reportId);

    const handleRoute = () => {
        const userData = getLoginDetail();
        const defaultView = userData && userData.profile && userData.profile.default_view;
        const path = `/${defaultView || 'dashboard'}`;
        history.push(path);
        setCurrentDashboard(defaultView || 'dashboard');
    };

    useEffect(() => {
        if (isContextualHelpEnabled) {
            const isWalkthrough = showWalkthrough();
            if (isWalkthrough) {
                setTimeout(() => {
                    setStepsEnabled(true);
                }, 3000);
            }
        }
    }, []);

    const handleJoyrideCB = (data) => {
        const { action, index, type } = data;
        if (action === 'close' || action === 'skip' || action === 'reset') {
            setStepsEnabled(false);
            sessionStorageSetItem('showWalkthrough', 'no');
            localStorageSetItem('showWalkthrough', 'no');
        }
    };

    return (
        <Box className={classes.navbar}>
            <ReactJoyride
                steps={APP_STEPS}
                run={stepsEnabled}
                showSkipButton
                continuous
                callback={handleJoyrideCB}
                styles={{
                    options: {
                        primaryColor: '#428BCA',
                    },
                }}
            />
            <CustomModal
                modalData={methodologyImage}
                open={open}
                handleClose={handlClose}
                title="Methodology"
            />
            <Box
                direction="row"
                className={classes.mainSection}
                maxwidth="xl"
            >
                <Box className={classes.logoStyle}>
                    <img src={Logo} className={classes.logoStyleMain} alt="logo" />
                </Box>
                {
                    reportType ? (
                        <Box className={`${classes.selectContainer} select-tier`}>
                            <Select
                                options={reportTypeOptions}
                                styles={customSelectStyles}
                                placeholder="Select Tier"
                                onChange={handleReportChange}
                                value={selectedReport ? { label: selectedReport.name, value: selectedReport.id } : currentReport}
                            />
                        </Box>
                    ) : null
                }
                {
                    isRaasChampion ? (
                        <Box className={`${classes.selectContainer} select-account`}>
                            <Select
                                options={raasOrganizations}
                                styles={customSelectStyles}
                                placeholder="Select Account"
                                onChange={handleAccountChange}
                                value={raasOrganizations.filter((v) => v.value === currentAccount.value)}
                                isClearable
                            />
                        </Box>
                    ) : null
                }

                <Box className={classes.mainRightMenu}>
                    {isExumaEnabled && (
                        <span className={classes.rightPad}>
                            <Select
                                options={LINK_OPTIONS}
                                styles={customSelectStylesDashboard}
                                placeholder="Select Dashboard"
                                onChange={handleLinkChange}
                                value={LINK_OPTIONS.filter((v) => v.value === currentDashboard)}
                            />
                        </span>
                    )}
                    <span onClick={mobileMenPop} className={openMenu ? classes.mainMobileMenuClos : classes.mainMobileMenu}>
                        <MenuIcon className={classes.mobileMenu} />
                    </span>
                    <span onClick={mobileMenPopClose} className={openMenu ? classes.mainMobileMenuCossOpen : classes.mainMobileMenuCoss}>
                        {' '}
                        <ClearIcon className={classes.mobileMenu} />
                    </span>

                    <ul className={openMenu ? classes.rightMenu : classes.rightMenuClose}>
                        {isExumaEnabled ? (
                            <li
                                className={location.pathname.includes(userDetails && userDetails.profile && userDetails.profile.default_view)
                                    ? `${classes.activeListItem} tour-home` : 'tour-home'}
                            >
                                <span
                                    onClick={handleRoute}
                                    className={classes.link}
                                >
                                    <span>
                                        <HomeIcon className={classes.dashboardIcon} />
                                        {' '}
                                        Home
                                    </span>
                                </span>
                            </li>
                        ) : (
                            <>
                                <li className={selectedNav === 0 ? `${classes.activeListItem} select-dashboard` : 'select-dashboard'}>
                                    <Link
                                        to="/dashboard"
                                        className={classes.link}
                                        onClick={() => { handleParentNaveClick(0); }}
                                    >
                                        <span>
                                            <DashboardIcon className={classes.dashboardIcon} />
                                            {' '}
                                            Dashboard
                                        </span>
                                    </Link>
                                </li>
                                <li className={selectedNav === 1 ? classes.activeListItem : ''}>
                                    <Link
                                        to="/alertDashboard"
                                        className={classes.link}
                                        onClick={() => { handleParentNaveClick(1); }}
                                    >
                                        <span>
                                            <AlertIcon className={classes.dashboardIcon} />
                                            {' '}
                                            Alerts
                                        </span>
                                    </Link>
                                </li>
                            </>
                        )}
                        <li className={selectedNav === 2 ? `${classes.activeListItem} ${classes.knowledgeCenter} tour-risk-insight` : `${classes.knowledgeCenter} tour-risk-insight`}>
                            <Link
                                to="/KnowledgeManagement"
                                className={classes.link}
                                onClick={() => { handleParentNaveClick(2); }}
                            >
                                <img src={nav3} alt="Knowledge Management" />
                                {' '}
                                Risk Insight(s)
                            </Link>
                        </li>
                        <li
                            aria-hidden="true"
                            onClick={handleHelpClickk}
                            className={selectedNav === 3 ? `${classes.activeListItem} ${classes.dropDown} tour-help` : `${classes.dropDown} tour-help`}
                        >
                            <span>
                                <HelpIcon className={classes.dashboardIcon} />
                                {' '}
                                Help
                            </span>
                        </li>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorHelpEl}
                            keepMounted
                            open={Boolean(anchorHelpEl)}
                            onClose={handleClose}
                            classes={{ paper: classes.marginTop }}
                        >
                            <MenuItem className={classes.menuItemm} onClick={onMethodologyClick}>
                                <DashboardOutlinedIcon className={classes.menuItemmI} />
                                {' '}
                                Methodology
                            </MenuItem>
                            <MenuItem className={classes.menuItemm} onClick={onFAQClick}>
                                {' '}
                                <LiveHelpOutlinedIcon className={classes.menuItemmI} />
                                FAQs
                            </MenuItem>
                            <MenuItem className={classes.menuItemm} onClick={onVideoTutorialsClick}>
                                {' '}
                                <PlayCircleOutlineIcon className={classes.menuItemmI} />
                                Video Tutorials
                            </MenuItem>
                            <MenuItem className={classes.menuItemm} onClick={techSupportClick}>
                                {' '}
                                <DonutLargeIcon className={classes.menuItemmI} />
                                Tech Support
                            </MenuItem>
                            {isContextualHelpEnabled ? (
                                <MenuItem
                                    className={classes.menuItemm}
                                    onClick={handleStartTour}
                                >
                                    {' '}
                                    <PlayCircleOutlineIcon className={classes.menuItemmI} />
                                    Start tour
                                </MenuItem>
                            ) : null}
                        </Menu>
                        {/* <li className={classes.notFicat}>
                            <span>
                                {' '}
                                <NotificationsIcon />
                                {' '}
                            </span>
                            <Box className={classes.hoverPart}>
                                <Typography
                                    className={classes.fontSetPortPoupNot}
                                    variant="subtitle2"
                                >
                                    Notifications
                                </Typography>
                                <Divider />
                                <Typography
                                    className={classes.fontSetPortPoupNotFic}
                                    variant="subtitle2"
                                >
                                    <span>
                                        {' '}
                                        <NotificationImportantOutlinedIcon style={{ color: '#3c3b3b' }} />
                                    </span>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr
                                    aliquyam erat, sed diam voluptua. At vero.
                                </Typography>
                            </Box>
                        </li> */}
                        <li
                            aria-hidden="true"
                            onClick={handleUserClickk}
                            className={selectedNav === 4 ? `${classes.dropDown} tour-user` : `${classes.dropDown} tour-user`}
                        >
                            <div className={classes.userBlock}>
                                <UserIcon className={classes.dashboardIcon} />
                                <i className={classes.arrowDown} />
                            </div>
                        </li>
                    </ul>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorUserEl}
                        keepMounted
                        open={Boolean(anchorUserEl)}
                        onClose={handleClose}
                        classes={{ paper: classes.marginTop }}
                    >
                        <MenuItem className={classes.menuItemm} disabled>
                            <UserIcon className={classes.menuItemmI} />
                            {' '}
                            {`${userProfile.first_name || ''} ${userProfile.last_name || ''}`}
                        </MenuItem>
                        <MenuItem className={classes.menuItemm} onClick={labelsClick}>
                            <LocalOfferOutlinedIcon className={classes.menuItemmI} />
                            {' '}
                            My Labels
                        </MenuItem>
                        {(userProfile.profile && userProfile.profile.is_admin) || isCustomerSuccess
                            ? (
                                <MenuItem className={classes.menuItemm} onClick={organizationClick}>
                                    <VerifiedUserOutlinedIcon className={classes.menuItemmI} />
                                    {' '}
                                    Organization
                                </MenuItem>
                            ) : null}
                        <MenuItem className={classes.menuItemm} onClick={settingsClick}>
                            <SettingsOutlinedIcon className={classes.menuItemmI} />
                            {' '}
                            Settings
                        </MenuItem>
                        <MenuItem className={classes.menuItemm} onClick={handleLogout}>
                            {' '}
                            <ExitToAppIcon className={classes.menuItemmI} />
                            Log out
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            <TechSupport propsIsOpen={showTechSupport} setTheView={setTechSupportView} />
        </Box>
    );
};

export default NavBar;

import 'leaflet/dist/leaflet.css';
import { RISK_CATEGORY_COLORS, RISK_CATEGORY_RANGE } from '../common/constants';
import { useHistory } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import WorldmapView from '../common/charts/WorldmapView';

const MapViewCountry = (props) => {
    const [countries, setCountries] = useState([]);
    const history = useHistory();
    const { targetLists } = props;
    const center = [50, 20];
    const ZOOM_LEVEL = 1;

    const countryStyle = {
        fillColor: '#fff',
        fillOpacity: 1,
        color: '#000',
        weight: 0.4,
    };

    /**
   * Function to select color based on rating
   * @param {Object}
   */
    const selectColor = (country) => {
        const rating = country.risk_categories && country.risk_categories.length && country.risk_categories[0].score;
        let riskColor;
        if (rating > 0 && rating <= RISK_CATEGORY_RANGE.LOW_RISK_LIMIT) {
            riskColor = RISK_CATEGORY_COLORS.LOW_RISK;
        } else if (
            rating > RISK_CATEGORY_RANGE.LOW_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT
        ) {
            riskColor = RISK_CATEGORY_COLORS.MODERATE_RISK;
        } else if (
            rating > RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT
        ) {
            riskColor = RISK_CATEGORY_COLORS.HIGH_RISK;
        } else if (
            rating > RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT && rating <= RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT
        ) {
            riskColor = RISK_CATEGORY_COLORS.CRITICAL_RISK;
        } else {
            riskColor = '#fff';
        }
        return riskColor;
    };

    const mapCountries = window.geoData && window.geoData.features;

    useEffect(() => {
        setCountries(null);
        if (mapCountries) {
            for (let i = 0; i < mapCountries.length; i++) {
                const mapCountry = mapCountries[i];
                const matchedCountry = targetLists.find((country) => country.name.toLowerCase() === mapCountry.properties.ADMIN.toLowerCase());
                mapCountry.properties.risk_categories = 0;
                mapCountry.properties.color = '#fff';
                mapCountry.properties.id = 0;
                if (matchedCountry != null) {
                    mapCountry.properties.risk_categories = matchedCountry.risk_categories;
                    mapCountry.properties.id = Number(matchedCountry.id);
                    mapCountry.properties.color = selectColor(matchedCountry);
                }
            }
            setCountries(mapCountries);
        }
    }, [targetLists, mapCountries]);

    /**
   * Function to be executed on country click
   * @param {id}
   */
    const onContentClick = (targetId) => {
        if (targetId > 0) {
            history.push(
                {
                    pathname: '/targetProfile',
                    search: `?id=${targetId}`,
                }
            );
        }
    };

    /**
   * Function to be executed on country hover
   * @param {Array}
   */
    const onContentHover = (layer, countryRiskCat, countryName) => {
        if (countryRiskCat.length) {
            let table = `<div style="font-size:12px; font-weight:600">${countryName}</div>`;
            table += '<table style="font-size:12px;">';
            table += '<tr style="margin-bottom:2px;"><th align="left">Risk Domain</th><th>Rating</th></tr>';
            countryRiskCat.forEach((data) => {
                table += '<tr>';
                table += `<td >${data.name}</td>`;
                table += `<th>${data.score}</th>`;
                table += '</tr>';
            });
            table += '</table>';
            layer.bindTooltip(table).openTooltip();
        } else {
            layer.bindTooltip(countryName).openTooltip();
        }
    };

    /**
   * Function to be executed on creating the map
   * @param {Object} error
   */
    const onEachCountry = (country, layer) => {
        layer.options.fillColor = country.properties.color;
        const countryRiskCat = country.properties.risk_categories;
        const countryName = country.properties.ADMIN;

        layer.on({
            click: () => onContentClick(country.properties.id),
            mouseover: () => onContentHover(layer, countryRiskCat, countryName),
        });
    };

    return (
        <Fragment>
            {
                (countries && countries.length) ? (
                    <WorldmapView
                        ZOOM_LEVEL={ZOOM_LEVEL}
                        center={center}
                        onEachCountry={onEachCountry}
                        data={countries}
                        countryStyle={countryStyle}
                        title="Worldwide Risk Concentration"
                    />
                ) : null
            }
        </Fragment>
    );
};

export default MapViewCountry;

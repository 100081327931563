const logObj = [
    {
        url: '/api/account/users/',
        access_info: 'User list access',
        user_action: 'page-access',
        type: 'get',
    },
    // User Profile
    {
        url: '/api/account/getuser/', // Remove slash
        access_info: 'User data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/account/user/',
        access_info: 'User data update',
        user_action: 'page-update',
        type: 'put',
    },
    // FAQ
    {
        url: '/api/v2/risk/textual-faq/',
        access_info: 'FAQ data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Video Tutorials
    {
        url: '/api/v2/risk/video-faq/',
        access_info: 'Video FAQ access',
        user_action: 'page-access',
        type: 'get',
    },
    // KnowledgeManagement
    {
        url: '/api/v1/kb-articles/',
        access_info: 'Knowledge Management list access',
        user_action: 'page-access',
        type: 'get',
    },

    // KnowledgeManagementDetail
    {
        url: '/api/risk/kb-articles//',
        access_info: 'Knowledge Management data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/risk/get-next-prev-kb-article/',
        access_info: 'Knowledge Management Next and Prev data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/risk/pdfresourcearticle//',
        access_info: 'Download Knowledge Management',
        user_action: 'download',
        type: 'get',
    },
    // Tech Support
    {
        url: '/api/v2/accounts/ask-query/',
        access_info: 'Query added',
        user_action: 'page-add',
        type: 'post',
    },
    // Query
    {
        url: '/api/risk/alerts-csv-report/',
        access_info: 'Download Alert CSV Report',
        user_action: 'download',
        type: 'post',
    },

    // Alert
    {
        url: '/api/v2/risk/get-latest-alerts-by-impact/',
        access_info: 'Latest Alerts access',
        user_action: 'page-access',
        type: 'get',
    },
    // Labels
    {
        url: '/api/v2/accounts/labels/',
        access_info: 'Label  list access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/accounts/labels/',
        access_info: 'Label Data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/accounts/label//',
        access_info: 'Label Data add',
        user_action: 'page-add',
        type: 'post',
    },
    {
        url: '/api/risk/update_targets_for_label/',
        access_info: 'Target update for label',
        user_action: 'page-update',
        type: 'put',
    },
    {
        url: '/api/account/subscribed-targets/', // check first slash
        access_info: 'Subscibed Targets Data Access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/risk/get_target_by_label/',
        access_info: 'Find Target By Label Access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/account/label//',
        access_info: 'Label Delete',
        user_action: 'page-delete',
        type: 'delete',
    },
    // Navbar
    {
        url: '/api/v2/risk/report-type/',
        access_info: 'Report Type Data Access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/accounts/raas-organizations/',
        access_info: 'Raas organizations data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Actionable Intelligence
    {
        url: 'api/v2/risk/recent-changes/actionable/',
        access_info: 'Recent changes list access',
        user_action: 'page-access',
        type: 'get',
    },

    // Dashboard
    {
        url: '/api/v2/risk/target-type/',
        access_info: 'Target type data access',
        user_action: 'page-access',
        type: 'get',
    },
    // User
    {
        url: '/api/account/user//',
        access_info: 'User data update',
        user_action: 'page-update',
        type: 'put',
    },

    // User Profile
    {
        url: '/api/account/user//profile',
        access_info: 'User profile data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/account/user//profile',
        access_info: 'User profile add',
        user_action: 'page-add',
        type: 'post',
    },
    {
        url: '/api/account/user//profile',
        access_info: 'User profile update',
        user_action: 'page-update',
        type: 'put',
    },
    // Portfolio Comparison
    {
        url: '/api/v2/risk/snapshot-score/comparison/',
        access_info: 'Snapshot score comparison data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/risk/category/', // Remove slash
        access_info: 'Risk Category data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/v2/risk/raas-comparison/',
        access_info: 'Risk raas comparison data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Report View
    {
        url: '/api/v2/risk/recent-changes/report/',
        access_info: 'Recet changes report access',
        user_action: 'page-access',
        type: 'get',
    },
    // Riskiest Targets view
    {
        url: 'api/v2/risk/riskiest/findings/',
        access_info: 'Riskiest finding access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/v2/risk/riskiest/composite/',
        access_info: 'Riskiest composite access',
        user_action: 'page-access',
        type: 'get',
    },
    // Score From Finding view
    {
        url: 'api/v2/risk/snapshot-score/findings/',
        access_info: 'Snapshot score finding access',
        user_action: 'page-access',
        type: 'get',
    },
    // Org Profile
    {
        url: '/api/account/organization//',
        access_info: 'Organization data update',
        user_action: 'page-update',
        type: 'put',
    },
    // Manage Email
    {
        url: '/api/account/organization//',
        access_info: 'Organization data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Recent alert 

    {
        url: '/api/v2/risk/get-alerts-portfolio-by-severity/',
        access_info: 'Alerts portfolio by serverity access',
        user_action: 'page-access',
        type: 'get',
    },
    // Ask An Analyst
    {
        url: '/api/v2/accounts/ask-analyst-targets/',
        access_info: 'Ask analyst targets data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Composite Scroe
    {
        url: '/api/v2/risk/snapshot-score/composite/',
        access_info: 'Snapshot score composite data access',
        user_action: 'page-access',
        type: 'get',
    },

    // User update
    {
        url: 'api/account/SubcriptionTargetType/',
        access_info: 'Subcription target type data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/account/create_bulk_users/',
        access_info: 'New user add',
        user_action: 'page-add',
        type: 'post',
    },
    // User View
    {
        url: 'api/account/access-log-list/',
        access_info: 'Log list data access',
        user_action: 'page-access',
        type: 'post',
    },
    // Settings Notification
    {
        url: 'api/account/userpreference/',
        access_info: 'User preference add',
        user_action: 'page-add',
        type: 'post',
    },
    {
        url: 'api/account/userpreference//',
        access_info: 'User preference update',
        user_action: 'page-update',
        type: 'put',
    },
    {
        url: 'api/account/preference/',
        access_info: 'Preference data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/account/userpreference/',
        access_info: 'User preference access',
        user_action: 'page-access',
        type: 'get',
    },
    // Target View

    {
        url: 'api/account/block-unblock-all-targets/',
        access_info: 'Block unblock all targets data update',
        user_action: 'page-update',
        type: 'put',
    },
    {
        url: 'api/account/user-blocked-targets/',
        access_info: 'User block targets data update',
        user_action: 'page-update',
        type: 'put',
    },
    {
        url: 'api/account/users/',
        access_info: 'Organization User Access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/risk/targettypes/',
        access_info: 'Target types data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/account/active-targets-of-organization/',
        access_info: 'Active targets of organization data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/account/user-blocked-targets/',
        access_info: 'User blocked targets data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Target index 
    {
        url: 'api/risk/Categorytree/',
        access_info: 'Category data access',
        user_action: 'page-access',
        type: 'get',

    },
    {
        url: 'api/risk/get-reporttype-targets/',
        access_info: 'Report type targets data access',
        user_action: 'page-access',
        type: 'get',
    },

    // Target List view
    {
        url: 'api/v2/risk/targets/riskiest/composite/', // need to test.
        access_info: 'Targets riskies composite data access',
        user_action: 'page-access',
        type: 'get',
    },

    {
        url: 'api/v2/risk/targets/riskiest/findings/', // need to test.
        access_info: 'Targets riskies findings data access',
        user_action: 'page-access',
        type: 'get',
    },

    // Target Risk score
    {
        url: 'api/v2/risk/targets/risk-comparison/', // need to test.
        access_info: 'Targets risk comparison data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Target Dashboard index
    {
        url: '/api/risk/TargetDetail/',
        access_info: 'Target detail data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/risk/target-detail/get-alerts/',
        access_info: 'Target alerts data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/risk/pdfreport/',
        access_info: 'Download risk pdf report',
        user_action: 'download',
        type: 'get',
    },
    // Risk Category
    {
        url: '/api/risk/targetprofiledata/',
        access_info: 'Target profile data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/risk/get-compliance-risk-new/',
        access_info: 'Risk compliance data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/risk/get-fair-risk-new/',
        access_info: 'Risk fair data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Target Risk Snapshot view
    {
        url: '/api/risk/compositetabledata/', // need to test.
        access_info: 'Composite table data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/risk/trends/get_trends_list/', // need to test.
        access_info: 'Risk trends list access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/risk/targetprofiledata/',
        access_info: 'Risk target profile data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Alert
    {
        url: '/api/account/org-subscription-start-date/',
        access_info: 'Org suscription data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/risk/events/',
        access_info: 'Risk events data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/risk/get-alerts-for-dashboard',
        access_info: 'Risk alerts for dashboard access',
        user_action: 'page-access',
        type: 'get',
    },

    // Alert Listing Chart
    {
        url: '/api/v2/risk/get-alerts-by-severity-count/',
        access_info: 'Risk alerts by serverity count data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/risk/get-alerts-by-category-count/?', // need to work.
        access_info: 'Risk alerts by category count data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Alert Profile
    {
        url: 'api/v2/risk/related-alerts-by-timeperiod/',
        access_info: 'Risk related alerts by timeperiod access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/v2/risk/alert-events-by-timeperiod/',
        access_info: 'Risk alert events by timeperiod data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/v2/risk/alert-view/',
        access_info: 'Risk alert view data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: 'api/risk/pdfalert/',
        access_info: 'Download pdf alert data',
        user_action: 'download',
        type: 'get',
    },
    // Alert Serverity Component
    {
        url: '/api/v2/risk/get-alert-portfolio/',
        access_info: 'Risk alert portfolio data access',
        user_action: 'page-access',
        type: 'get',
    },
    {
        url: '/api/v2/risk/get-alerts-portfolio-by-event-type/',
        access_info: 'Risk alerts protfolio by event data access',
        user_action: 'page-access',
        type: 'get',
    },
    // Alert AlertSnapshot
    {
        url: '/api/v2/risk/get-alert-pie-chart/',
        access_info: 'Risk alert pie chart data access',
        user_action: 'page-access',
        type: 'get',
    },
];

const fetchURLRegex = (url) => {
    // with slashes
    const ids = url.match(/\/(\d+)+[\/]?/g);
    if (ids && ids.length === 1) {
        url = url.replace(/[0-9]/g, ''); // remove number from url its replace only one number
    }
    return url;
};

const fetchLogObj = (type, url) => {
    const customUrl = fetchURLRegex(url);
    const filterData = logObj.find((l) => (l.url === customUrl && l.type === type));

    if (filterData) {
        filterData.page_url = window.location.pathname;
        delete filterData.type;
        delete filterData.url;
    }
    return filterData;
};

export default fetchLogObj;

import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import React from 'react';
// import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        color: '#07212B',
        fontFamily: 'Gilroy-Semibold',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'capitalize',
    },
    icon: {
        marginLeft: '10px',
    },
}));

const BreadCrumb = (props) => {
    const classes = useStyles();
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {
                props.routes.map((item, index) => <Link color="inherit" className={classes.link} onClick={props.handleClick.bind(this, index)} key={item}>{item}</Link>)
            }
            {/* <Link color="inherit" href="/" onClick={handleClick} className={classes.link}>
        Composite Risk
        <InfoIcon className={classes.icon} style={{ fontSize: 16 }}/>
      </Link> */}
        </Breadcrumbs>
    );
};

export default BreadCrumb;
